import {EeviLogo} from "../components/eevi_logo";
import {EeviLoading} from "../components/eevi_loading";
import {EeviError, GetErrorMessage, isGlobalError} from "../components/eevi_error";
import {LoginFunction} from "../components/eevi_data";
import {EeviStandardState} from "../components/eevi_standard_state";
import {EeviContent} from "../components/eevi_std_content";
import {CSSProperties} from "react";
import {EeviUnhandledApiError} from "../components/eevi_form_feedback";
import {
    React,
    Container,
    Row,
    Col,
    Navbar,
    Nav,
    NavItem,
    NavLink,
    Collapse,
    NavbarToggler,
    NavbarBrand,
} from "../components/eevi_react_exports";
import {eeviGlobal} from "../components/eevi_context";

// Unable to find a way to get flask, zappa, aws and react to play well together with regards
// to the stage name that zappa appends to the url.
export const basename = (window as any).basename;
export const apiV1 = `${basename}/api/v1`;

interface BaseContainerState {
    isOpen: boolean;
}

export interface MenuLink {
    title: string;
    link: string;
}

export interface BaseContainerProps {
    title: string;
    children?: React.ReactNode;
    login: LoginFunction;
    forgotPasswordRoute?: string;
    addLoginRegisterButton?: boolean;
    formState: EeviStandardState;
}

export interface ContainerProps extends BaseContainerProps {
    formContentDivStyle?: CSSProperties;
    currentMenu?: string;
    menuLinks: MenuLink[];
    hideTopMargin?: boolean;
    renderedMenus?: React.ReactNode;
}

export class EeviBaseContainer<TProperties extends BaseContainerProps>
    extends React.Component<TProperties, BaseContainerState> {
    public formContentDiv: HTMLDivElement | undefined | null;

    constructor(props: TProperties) {
        super(props);
        this.toggle = this.toggle.bind(this);
        this.state = {isOpen: false};
    }

    toggle() {
        this.setState({
            isOpen: !this.state.isOpen
        });
    }

    // Show a loading indicator unless the child form has loaded its data
    protected renderChildContent() {
        const error = this.props.formState.error;
        if (isGlobalError(this.props.formState)) {
            return <EeviError
                error={error} login={this.props.login} addLoginRegisterButton={this.props.addLoginRegisterButton}/>
        } else if (this.props.formState.loading) {
            return <EeviLoading/>;
        } else {
            return <EeviUnhandledApiError formState={this.props.formState}>
                {this.props.children}
            </EeviUnhandledApiError>
        }
    }

    render(): React.ReactNode {
        const children = this.renderChildContent();
        return (
            <Container
                fluid={true} key="eevi_base_container">
                {children}
            </Container>
        );
    }
}


export class EeviStandardContainer extends EeviBaseContainer<ContainerProps> {
    public headerDiv: HTMLDivElement | undefined | null;

    constructor(props: ContainerProps) {
        super(props);
        this.render = this.render.bind(this);
    }

    // full page load NavLink href={`${basename}${menuLink.link}`}
    protected navItems() {
        return <>
            {
                this.props.menuLinks
                    .filter(m => m.title !== this.props.title) // exclude page we're already on
                    .map(menuLink =>
                        <NavItem key={menuLink.title}>
                            <NavLink to={menuLink.link}>{menuLink.title}</NavLink>
                        </NavItem>
                    )
            }
        </>;
    }

    render(): React.ReactNode {
        const children = this.renderChildContent();
        return (
            <Container
                fluid={true} key="eevi_standard_container">
                {this.renderHeader()}
                <div ref={(r) => this.formContentDiv = r} style={this.props.formContentDivStyle}>
                    {
                        /* white band top margin */
                        this.props.hideTopMargin ? <>{children}</> : <EeviContent>{children}</EeviContent>
                    }
                </div>
            </Container>
        );
    }

    protected getTitle(): string {
        if (this.props.formState.error) {
            const message = GetErrorMessage(this.props.formState.error);
            if (message === "Not logged in") {
                return "Welcome...";
            } else if (isGlobalError(this.props.formState)) {
                return "Ouch...";
            }
        }
        return this.props.formState.title || this.props.title;
    }

    protected renderHeader() {
        return <div className="sticky-top" ref={(r) => this.headerDiv = r}>
            {/* gold top header that sits above eevi banner */}
            <div className="eevi_thin_gold_band"/>

            {/* white eevi banner with logo and menu */}
            <Row className="eevi_white_band">
                <Container fluid className="w-100">
                    <Row>
                        <Col xs={4} sm={4} md={4} className="my-auto pl-4 p-2">
                            <div className="eevi_header">
                                <EeviLogo/>
                            </div>
                        </Col>
                        <Col xs={4} sm={4} md={4} className="my-auto p-0" style={{alignSelf: "center"}}>

                            <div className="eevi_header_title border">
                                <h3 className="text-center p-0 m-0">
                                    {this.getTitle()}
                                </h3>
                            </div>
                        </Col>

                        <Col xs={4} sm={4} md={4} className="my-auto pr-3 p-0 text-right" style={{alignSelf: "center"}}>
                            {this.showMenusIfLoggedIn()}
                        </Col>
                    </Row>
                </Container>
            </Row>

            {/* gold band below eevi banner */}
            <div className="eevi_thin_gold_band"/>
        </div>;
    }

    private showMenusIfLoggedIn() {
        if (this.props.formState.loading || !eeviGlobal.isLoggedIn()) {
            return <></>;
        }
        return !this.props.formState.loading && (this.props.renderedMenus || this.renderMenus());
    }

    protected renderMenus() {
        return <Navbar expand="md" light color="faded"
                       className={this.props.formState.loading ? "invisible" : ""}>
            <NavbarBrand/>
            <NavbarToggler onClick={this.toggle}/>
            <Collapse isOpen={this.state.isOpen} navbar className="pt-0">
                <Nav className="ml-auto" navbar>{this.navItems()}</Nav>
            </Collapse>
        </Navbar>;
    }
}

