import {Carer} from "../../../../../common/web_common/core/eevi_core_data";
import {eeviUuid} from "../../../../../common/web_common/components/eevi_util";

export function getCarerDemoData(careGroupCode: string, demoMode: number): Carer[] | null {
    const data1: Carer[] = [
        {
            careGroupCode: careGroupCode,
            carerName: "Luke Skywalker",
            email: "luke@theforce.com",
            contactPhoneNumber: "+61444444444",
            contactSmsNumber: "+61444444444",
            carerId: eeviUuid(),
            customData: {
                smpers: {
                    oldEmail: "luke_is_a_baby@the_dribbling_jedi.com",
                    password: null,
                    responseClusterSize: 1,
                    nativeIds: [
                        "jupl.uat/10504764"
                    ]
                }
            },

            lastLoginTime: "2023-06-18 20:15:45Z",
            isOnline: "true"
        },
        {
            careGroupCode: careGroupCode,
            carerName: "Bail Organa",
            email: "senator_bail@alderaan.com",
            contactPhoneNumber: "+6111111111",
            contactSmsNumber: "+6111111111",
            carerId: eeviUuid(),
            customData: {
                smpers: {
                    oldEmail: "bail@alderaan.com",
                    password: null,
                    responseClusterSize: 1,
                    nativeIds: [
                        "jupl.uat/10504764"
                    ]
                }
            },

            //lastLoginTime: ,
            //isOnline: "false"
        }

    ];

    let data: Carer[] | null;
    switch (demoMode) {
        case 1:
            data = data1;
            break;

        default:
            data = null;
    }

    return data;
}