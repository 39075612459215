import {apiV1} from "../../../../common/web_common/containers/eevi_std_container";
import {TestRunsTable, TestBatch, TestBatchStats} from "../components/self_test";
import {SmpersForm, SmPersState} from "../components/smpers_form";
import {EeviApi} from "../../../../common/web_common/components/eevi_api";
import {EeviLookupItem} from "../../../../common/web_common/components/eevi_lookup";
import {
    Button, Container,
    React,
    Select,
    withRouter
} from "../../../../common/web_common/components/eevi_react_exports";


class RunTestsState extends SmPersState {
    testBatch: TestBatch = {
        test_types: [],
        test_devices: [],
        test_runs: [],
        still_running: false,
    };
    selectedDevices?: Array<EeviLookupItem>;
    selectedTests?: Array<EeviLookupItem>;
    numberOfIterations: number = 1;
}

class RunTestsForm extends SmpersForm<any, RunTestsState> {
    private timer: any = null;

    constructor(props: any) {
        super(props, "Self Test", true);

        this.state = new RunTestsState({loading: false});

        this.handleSelectedDeviceChange = this.handleSelectedDeviceChange.bind(this);
        this.handleSelectedTestChange = this.handleSelectedTestChange.bind(this);
        this.handleNumberOfIterationsChange = this.handleNumberOfIterationsChange.bind(this);
        this.handleRunTestsClick = this.handleRunTestsClick.bind(this);
    }

    private handleSelectedDeviceChange(selectedDevices: Array<EeviLookupItem>) {
        this.setState(state => {
            return {
                selectedDevices: selectedDevices
            };
        });
    }

    private handleSelectedTestChange(selectedTests: Array<EeviLookupItem>) {
        this.setState(state => {
            return {
                selectedTests: selectedTests
            };
        });
    }


    private handleNumberOfIterationsChange(numberOfIterations: string) {
        this.setState(state => ({
            numberOfIterations: +numberOfIterations
        }));
    }

    private handleRunTestsClick() {
        this.setState(
            {
                testBatch: {...this.state.testBatch, still_running: true}
            }
        );
        this.runSelectedTests();

    }


    private runSelectedTests() {
        if (this.timer !== null) {
            window.clearTimeout(this.timer)
            this.timer = null;
        }
        const data = {
            test_devices: this.state.selectedDevices,
            test_types: this.state.selectedTests,
            number_of_iterations: this.state.numberOfIterations
        };
        const api = EeviApi.fromComponent(this);
        api.post(`${apiV1}/tests`, data, "testBatch", (resp) => {
            this.setPartialState(resp);
            if (this.state.testBatch.still_running) {
                this.timer = window.setTimeout(() => this.updateTestRuns(), 2000)
            }
        });
    }

    componentDidMount() {
        this.updateTestRuns(true);
    }

    componentWillUnmount(): void {
        if (this.timer !== null) {
            window.clearTimeout(this.timer)
        }
    }

    private updateTestRuns(updateProgress: boolean = false) {
        if (this.timer !== null) {
            window.clearTimeout(this.timer)
            this.timer = null;
        }
        const api = EeviApi.fromComponent<RunTestsState>(this);
        const url = `${apiV1}/tests/${this.state.testBatch.batch_id || this.props.match.params.id}`;
        api.get(
            url,
            "testBatch",
            (resp) => {
                api.setState({...resp, loading: false});
                if (this.state.testBatch.still_running) {
                    this.timer = window.setTimeout(() => this.updateTestRuns(), 2000)
                }
            },
            undefined,
            updateProgress);
    }

    renderForm(): React.ReactNode {
        const disabledTests = !this.validInputFields();
        return <>
            <Container>
                <label>PERS Devices</label>
                <Select
                    name="devices"
                    placeholder="Test devices to use..."
                    value={this.state.selectedDevices}
                    options={this.state.testBatch.test_devices || []}
                    onChange={(options) => this.handleSelectedDeviceChange(options as Array<EeviLookupItem>)}
                    isMulti
                />

                <label>Tests</label>
                <Select
                    name="tests"
                    placeholder="Tests to run..."
                    value={this.state.selectedTests}
                    options={this.state.testBatch.test_types}
                    onChange={(options) => this.handleSelectedTestChange(options as Array<EeviLookupItem>)}
                    isMulti
                />
                <label>Test Iterations</label>
                <div className="input-group">
                    <input type="number" name="iterations" min="1" value={this.state.numberOfIterations}
                           placeholder="The number of times to run the tests"
                           onChange={(event) => this.handleNumberOfIterationsChange(event.target.value)}
                           className="form-control"
                    />
                    <span className="input-group-btn">
                            <Button className="btn-primary" disabled={disabledTests}
                                    onClick={() => this.handleRunTestsClick()}>Run Tests</Button>
                        </span>
                </div>
                <TestBatchStats running_test_number={this.state.testBatch.running_test_number}
                                total_number_of_tests={this.state.testBatch.total_number_of_tests}/>
            </Container>
            <TestRunsTable testRuns={this.state.testBatch.test_runs}/>
        </>;
    }


    private validInputFields() {
        return this.state.selectedTests && this.state.selectedTests.length > 0 &&
            this.state.selectedDevices && this.state.selectedDevices.length > 0 &&
            this.state.numberOfIterations && !this.state.testBatch.still_running;
    }
}

export default withRouter(RunTestsForm);