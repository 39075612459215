import {
    EeviDeviceEditAction,
    EeviDeviceEditorProperties
} from "../../../../common/web_common/core/components/eevi_device_editor";
import {
    Button, ButtonGroup,
    Col, Container,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Input,
    Label,
    React,
    Row
} from "../../../../common/web_common/components/eevi_react_exports";
import {AccessoryFunction, Device, EventType} from "../../../../common/web_common/core/eevi_core_data";
import {
    isBlankString,
    setEeviUid, setInputFocus,
    withSpaces
} from "../../../../common/web_common/components/eevi_util";
import {EeviTooltip} from "../../../../common/web_common/components/eevi_tooltip";
import {EeviFormFeedback} from "../../../../common/web_common/components/eevi_form_feedback";
import {EeviSelect} from "../../../../common/web_common/components/eevi_select";
import {boldItalic} from "../../../../common/web_common/components/eevi_style";
import {EeviLoading} from "../../../../common/web_common/components/eevi_loading";
import {dynamicSubclass} from "../../../../common/web_common/components/eevi_transform";
import {eeviGlobal} from "../../../../common/web_common/components/eevi_context";
import {setFieldError} from "../../../../common/web_common/components/eevi_error";


export function DefaultDeviceEditor(props: EeviDeviceEditorProperties) {
    if (!props.devices) {
        return <></>;
    }
    const actionInfo = props.action === EeviDeviceEditAction.Insert ? " (insert)" : "";
    return <>
        <Label className="font-italic">{`${props.platform}${actionInfo}`}</Label>
        {
            props.devices.map(d =>
                <div key={setEeviUid(d)}>
                    <div className="ml-3">
                        {d.deviceName}
                    </div>
                </div>
            )
        }
    </>;
}

const PHONE_TYPES = [
    "Mobile", "Land Line", "Watch"
].map((code) => ({value: code as string, label: code}));

function PhoneDeviceCard(props: EeviDeviceEditorProperties & { device: Device }) {
    const access = props.access;
    const device = props.device;
    const insertMode = (device.deviceId === undefined);
    const editMode = access.deviceBeingEdited === device || insertMode;
    const [stateCount, setStateCount] = React.useState(0);
    eeviGlobal.logRender('PhoneDeviceCard', 'editMode', editMode, 'insertMode', insertMode);

    function refresh() {
        setStateCount(stateCount + 1);
    }

    function setValue<T, K extends keyof Device>(key: K, value: T) {
        device[key] = value;
        refresh();
    }

    function deviceDataIsBlank(): boolean {
        return isBlankString(device.phoneNumber) || isBlankString(device.deviceName);
    }


    function renderForDisplay() {
        return <Row>
            <Col md={10} className="keep-lines pl-0">
                <Row className="pl-4 pr-0">
                    <Col md={2}>{device.deviceType.value}</Col>
                    <Col md={3}>{device.phoneNumber}</Col>
                    {device.deviceName && <Col md={7}>{device.deviceName}</Col>}
                </Row>
            </Col>
            <Col md={2} className="text-right pr-0">
                    <span className="btn-group btn-group-sm p-0 mb-1">
                        <Button
                            disabled={access.disabledDeviceEdits()}
                            id={setEeviUid(device, "edit_device")}
                            onClick={() => access.editDevice(device)}
                            size="sm"
                            color="outline-secondary"
                            className="eevi_edit_button">edit</Button>
                        <EeviTooltip
                            target={setEeviUid(device, "edit_device")}>Change device details.</EeviTooltip>
                    </span>
            </Col>

        </Row>;

    }

    function renderForEdit() {
        if (access.deviceIsLoading()) {
            return <EeviLoading/>;
        }
        return <Row className="shadow row p-4 border">
            <div style={boldItalic}>{insertMode ? "Add new device..." : "Change device..."}</div>
            <Col md={12} className="keep-lines pl-0">
                <EeviSelect
                    className="eevi_edit_text"
                    onChange={deviceTypeLookup => setValue("deviceType", deviceTypeLookup)}
                    lookupValue={device.deviceType.value}
                    lookupItems={PHONE_TYPES}
                    placeholder="Type of device."
                    id={setEeviUid(device, "device_type")}
                />
                <EeviFormFeedback fieldName="deviceType" state={access}/>
                <EeviTooltip target={setEeviUid(device, "device_type")}>Mobile, Land Line or Watch?</EeviTooltip>
                <Input
                    id={setEeviUid(device, "device_name")}
                    innerRef={setInputFocus}
                    type="text"
                    onChange={(e) => setValue("deviceName", e.target.value)}
                    className="eevi_edit_text"
                    value={device.deviceName}
                    placeholder="Service name: e.g. Bill's mobile."/>
                <EeviFormFeedback fieldName="deviceName" state={access}/>
                <EeviTooltip
                    target={setEeviUid(device, "device_name")}>Name or owner of device.</EeviTooltip>
                <Input
                    id={setEeviUid(device, "device_number")}
                    type="text"
                    className="eevi_edit_text"
                    onChange={e => setValue("phoneNumber", e.target.value)}
                    value={device.phoneNumber}
                    pattern="\+[0-9]*"
                    placeholder="Full phone number: e.g. +61470079007."/>
                <EeviFormFeedback fieldName="phoneNumber" state={access}/>
                <EeviTooltip target={setEeviUid(device, "device_number")}>
                    Device phone number (international format)
                </EeviTooltip>
                <DeviceSaveCancelRemoveButtons
                    {...props} disableSave={deviceDataIsBlank()} refresh={refresh}/>
            </Col>
        </Row>;
        // Note DeviceSaveCancelRemoveButtons is using same props as are passed in to PhoneDeviceCard
    }

    return editMode ? renderForEdit() : renderForDisplay();
}

export function PhoneDeviceEditor(props: EeviDeviceEditorProperties) {
    if (props.action === EeviDeviceEditAction.Insert) {
        const device = props.devices.find(d => d.deviceId === undefined);
        if (device === undefined) {
            props.access.newDevice({
                serviceLocationCode: props.serviceLocation.serviceLocationCode,
                careGroupCode: props.careGroup.careGroupCode,
                platform: {value: props.platform},
                deviceType: {value: "Mobile"}
            } as Device);
        }
    }
    if (props.devices.length === 0) {
        return <></>;
    }
    // const prefix = 'pde:';
    return <>
        <Label className="font-italic">Phones and Watches</Label>
        {
            props.devices.map(d =>
                <PhoneDeviceCard
                    key={setEeviUid(d)}
                    device={d}
                    {...props}/>
            )
        }
    </>;


}

export function MedicalAlarmEditor(props: EeviDeviceEditorProperties) {
    // GUI won't allow you to add a 2nd device. If you click add Eevi Gateway and it already exists
    // we just edit the first item. If on the other hand there are no devices, we add one.
    if (props.action === EeviDeviceEditAction.Insert) {
        if (props.devices.length === 0) {
            props.access.newDevice({
                serviceLocationCode: props.serviceLocation.serviceLocationCode,
                careGroupCode: props.careGroup.careGroupCode,
                platform: {value: props.platform},
                deviceType: {value: "Gateway"}
            } as Device);
        } else {
            props.access.editDevice(props.devices[0]);
        }
    }
    if (props.devices.length === 0) {
        return <></>;
    }
    const prefix = 'mae:';
    return <>
        <Label className="font-italic">Eevi Gateway</Label>
        {
            props.devices.map(d =>
                <MedicalDeviceCard
                    key={setEeviUid(d, prefix)}
                    device={d}
                    {...props}/>
            )
        }
    </>;

}

export function IoTDeviceEditor(props: EeviDeviceEditorProperties) {
    if (!props.devices) {
        return <></>;
    }
    const prefix = 'sh_editor:';
    return <>
        <Label className="font-italic">Smart Home</Label>
        {
            props.devices.map(d =>
                <div key={setEeviUid(d, prefix)}>
                    <div className="ml-4">Name: {d.deviceName}, Type: {d.deviceType.value}</div>
                </div>
            )
        }
    </>;
}

interface DeviceCardProps extends EeviDeviceEditorProperties {
    device: Device;
}

class VillageCareData {

    get smpersData(): any {
        const instanceData = this as any;
        if (instanceData.customData === undefined) {
            instanceData.customData = {};
        }
        if (instanceData.customData.smpers === undefined) {
            instanceData.customData.smpers = {};
        }
        return instanceData.customData.smpers;
    }

}

export class VillageDevice extends VillageCareData {

    get vendorSettingsUrl(): string | undefined {
        return this.smpersData["vendorSettingsUrl"];
    }

    get accessoryFunctions(): AccessoryFunction[] {
        let data = this.smpersData["accessoryFunctions"];
        if (data === undefined) {
            data = [];
            this.smpersData["accessoryFunctions"] = data;
        }
        return data as AccessoryFunction[];
    }
}

export class VillageCarer extends VillageCareData {

    get password(): string | undefined {
        return this.smpersData["password"];
    }

    set password(value: string | undefined) {
        this.smpersData["password"] = value;
    }

    get duressBadge(): string | undefined{
        return this.smpersData["staffBadge"];
    }

    set duressBadge(value: string | undefined) {
        this.smpersData["staffBadge"] = value;
    }
}


export class VillageLocation extends VillageCareData {

    get residentNames(): string | undefined {
        return this.smpersData["residentNames"];
    }

    set residentNames(value: string | undefined) {
        this.smpersData["residentNames"] = value;
    }

    get smsMessage(): string | undefined {
        return this.smpersData["smsMessage"];
    }

    set smsMessage(value: string | undefined) {
        this.smpersData["smsMessage"] = value;
    }


}


function MedicalDeviceCard(props: DeviceCardProps) {
    const access = props.access;
    const device = dynamicSubclass(props.device, VillageDevice);
    const location = dynamicSubclass(props.serviceLocation, VillageLocation);
    const insertMode = (device.deviceId === undefined);
    const editMode = access.deviceBeingEdited === props.device || insertMode;
    device.deviceName = device.deviceName || "Eevi Gateway";

    // These events can be selected from the drop-down.  These are the only actions a user can change.
    const eligibleAccessoryOptions: Map<string, string> = new Map([
        [EventType.SMOKE_ALARM, "Smoke Alarm"],
        [EventType.PENDANT_BUTTON_PUSH, "Help Button"],
    ])

    // These events are read only and assigned through the pendant to event mapping file on the server
    // and each gateway (1.11+).
    const readOnlyAccessoryOptions: Map<string, string> = new Map([
        ["CARER_PRESENCE", "Carer Presence"],
        ["CARER_DEPARTURE", "Carer Departure"],
        ["BED_EXIT", "Bed Exit"],
        ["PENDANT_COMPLETE_BUTTON_PUSH", "Complete Button"],
        ["FALL_DETECTED", "Fall Detected Alarm"],
        ["SMOKE_ALARM", "Smoke Alarm"],
        ["PENDANT_BUTTON_PUSH", "Help Button"]
    ])

    const [showDeleteConfirmDialog, setShowDeleteConfirmDialog] = React.useState(false);
    const [stateCount, setStateCount] = React.useState(0);

    //console.log(`device accessory: ${device.accessoryFunctions}`)

    function refresh() {
        setStateCount(stateCount + 1);
    }

    function editDevice() {
        access.editDeviceAndAccessories(props.device);
    }

    function deviceDataIsBlank(): boolean {
        return isBlankString(device.phoneNumber);
    }

    function renderForDisplay() {
        if (access.deviceIsLoading()) {
            return <EeviLoading/>;
        }

        return <>
            <Row key={setEeviUid(device, "mrfp")}>
                <Col xs={7} sm={7} className="keep-lines pl-0">
                    <Row className="ml-4">
                        {device.vendorSettingsUrl &&
                            <Button
                                onClick={
                                    () => access!.showVendorSettings(
                                        withSpaces(
                                            location.serviceLocationCode,
                                            location.residentNames,
                                            device.deviceName),
                                        device.vendorSettingsUrl!)
                                }
                                className="p-0"
                                color="link">device settings</Button>
                        }
                        <span className="ml-4">{device.phoneNumber}</span>
                    </Row>
                </Col>
                <Col className="text-right pr-0">
                    <span className="btn-group btn-group-sm p-0 mb-1">
                        <Button
                            disabled={access.disabledDeviceEdits()}
                            id={setEeviUid(device, "edit_device")}
                            onClick={editDevice}
                            size="sm"
                            color="outline-secondary"
                            className={editMode ? "invisible" : "eevi_edit_button"}>edit</Button>
                        <EeviTooltip target={setEeviUid(device, "edit_device")}>Change device details</EeviTooltip>
                    </span>
                </Col>
            </Row>
        </>;
    }

    function removeDeviceConfirm() {
        setShowDeleteConfirmDialog(true);
    }

    function removeDevice() {
        eeviGlobal.logEvent('confirmRemoveDevice', ...props.devices.map(d => d.deviceId));
        access!.removeDevice();
        props.refresh();  // Necessary?
        setShowDeleteConfirmDialog(false);
    }


    function renderForEdit() {
        if (access.deviceIsLoading()) {
            return <EeviLoading/>;
        }

        if (showDeleteConfirmDialog) {
            // Delete confirmation dialog replaces the panel
            return (
                <div className="card shadow border-warning">
                    <h2 className="modal-header">Confirm Remove Gateway from Resident?</h2>
                    <div className="p-4 text-justify">Are you sure you want to remove Eevi Gateway phone number
                        '{device.phoneNumber}' from this resident?
                    </div>
                    <div className="p-2">
                        <Button className="m-2" onClick={removeDevice} color="primary">ok</Button>
                        <Button className="m-2" color="secondary" onClick={() => setShowDeleteConfirmDialog(false)}>
                            cancel
                        </Button>
                    </div>
                </div>
            );
        }

        // Render for edit as normal
        return <Container className="d-flex flex-column shadow p-2 border">
            <Row className={"ml-1 mr-1"}>
                <div style={boldItalic}>{device.deviceId ? "Change Eevi gateway..." : "Add Eevi gateway..."}</div>
            </Row>
            <Row className={"ml-1 mr-1"}>
                <Input
                    id={setEeviUid(device, "device_number")}
                    innerRef={setInputFocus}
                    type="text"
                    className="eevi_edit_text"
                    onChange={e => {
                        device.phoneNumber = e.target.value;
                        refresh();
                    }}
                    value={device.phoneNumber}
                    pattern="\+[0-9]*"
                    placeholder="full phone number: e.g. +61470079007"/>
                <EeviFormFeedback fieldName="phoneNumber" state={access}/>
                <EeviFormFeedback fieldName="deviceId" state={access}/>
                <EeviFormFeedback fieldName="carer_email" state={access}/>
                <EeviTooltip target={setEeviUid(device, "device_number")}>
                    Device phone number (international format)
                </EeviTooltip>
            </Row>
            {device.accessoryFunctions.length ? <Row className={"ml-1 mr-1 mt-2"}>
                <div style={boldItalic}>Accessory</div>
            </Row> : null
            }
            {
                device.accessoryFunctions.map(
                    (d: AccessoryFunction) => {
                        const cellWidthCount: number = 3;
                        let eventNameLabel: string = `${d.eventType}`;
                        let lastSeenLabel: string = `${eventNameLabel} pendant is enabled, but has not responded yet.`;
                        if (d.lastContactTime) {
                            const dateStringFormat: Intl.DateTimeFormatOptions = {
                                day: 'numeric',
                                month: 'numeric',
                                year: 'numeric',
                            }
                            const timeStringFormat: Intl.DateTimeFormatOptions = {
                                hour: 'numeric',
                                minute: 'numeric',
                                hour12: false
                            }
                            const lastContactTime = new Date(d.lastContactTime);
                            lastSeenLabel = `${eventNameLabel} pendant was last seen at ${lastContactTime.toLocaleTimeString('en-GB', timeStringFormat)} on ${lastContactTime.toLocaleDateString('en-GB', dateStringFormat)}.`;
                        }


                        if (d.readOnly) {
                            readOnlyAccessoryOptions.forEach((value, key) => {
                                if (key === `${d.eventType}`) {
                                    eventNameLabel = value;
                                }
                            });
                            return (
                                <Row className={"ml-1 mr-1 mb-1"}>
                                    <Col style={{fontWeight: "bold", maxWidth: "100px"}}>{`${d.serialNumber}:`}</Col>
                                    <Col md={cellWidthCount}>
                                        <div>{eventNameLabel}</div>
                                    </Col>
                                    <Col>{lastSeenLabel}</Col>
                                </Row>
                            );
                        } else {
                            return (
                                <Row className={"ml-1 mr-1 mb-1"}>
                                    <Col style={{fontWeight: "bold", maxWidth: "100px"}}>{`${d.serialNumber}:`}</Col>
                                    <Col md={cellWidthCount}>
                                        {<AccessoryDropDown
                                            device={d}
                                            attr={"eventType"}
                                            options={eligibleAccessoryOptions}
                                            onSelect={(k: EventType) => d.eventType = k}/>
                                        }
                                    </Col>
                                    <Col>{lastSeenLabel}</Col>
                                </Row>
                            );
                        }
                    }
                )}
            <Row className={"ml-1 mr-1"} style={{justifyContent: "flex-end"}}>
                <DeviceSaveCancelRemoveButtons
                    {...props} disableSave={deviceDataIsBlank()} save={removeDeviceConfirm} refresh={refresh}/>
            </Row>
        </Container>;
    }

    return editMode ? renderForEdit() : renderForDisplay();
}  // End MedicalDeviceCard

type ButtonProps = DeviceCardProps & { disableSave: boolean; save?: VoidFunction; refresh: VoidFunction; };

function DeviceSaveCancelRemoveButtons(props: ButtonProps) {
    /**
     * Process save, cancel and remove button press events. Devices can be eg.
     * Eevi Gateway, SmartThings or Emfit devices. The access property references the
     * EeviCoreAccess interface for managing CRUD ops on the Eevi data model.
     */
    const access = props.access;
    const device = props.device;

    //const cancelRef = React.useRef<HTMLButtonElement>(null);


    function cancelEditDevice() {
        eeviGlobal.logEvent('cancelEditDevice', ...props.devices.map(d => d.deviceId));
        access.cancelEditDevice();
        props.refresh();
    }

    function validateDeviceAndSave(): void {
        if (!device.phoneNumber) {
            return;
        }
        const otherDevices = access.getServiceLocationDevices(device.careGroupCode!, device.serviceLocationCode!);
        if (otherDevices === "loading") {
            return;
        }
        for (let otherDevice of otherDevices) {
            if (device !== otherDevice && otherDevice.phoneNumber === device.phoneNumber) {
                setFieldError(
                    access,
                    "A device with that phone number has already been added to this service location.",
                    "phoneNumber"
                );
                access.onModelUpdate();
                return;
            }
        }
        access.saveDevice();
    }

    /*
    function removeDevice() {
        eeviGlobal.logEvent('confirmRemoveDevice', ...props.devices.map(d => d.deviceId));
        access!.removeDevice();
        props.refresh();  // Necessary?
        setShowDeleteConfirmDialog(false);
    }

     */

    // Delete confirm not shown
    return (
        <div className="text-right">
            <Button
                onClick={validateDeviceAndSave}
                disabled={props.disableSave}
                className="eevi_edit_button"
                size="sm"
                color="outline-secondary"
                id={setEeviUid(device, "save")}
            ><span>save</span></Button>
            <Button
                onClick={cancelEditDevice}
                size="sm" color="outline-secondary"
                className="m-2 eevi_edit_button"
                id={setEeviUid(device, "cancel")}
            >cancel</Button>
            <Button
                onClick={() => {
                    if (props.save) {
                        props.save();
                    } else {
                        open();
                    }
                }}
                disabled={props.disableSave}  // As with [save] button.
                className="eevi_edit_button"
                size="sm"
                color="outline-secondary"
                id={setEeviUid(device, "remove")}
            >remove</Button>

            {/*            {showDeleteConfirmDialog && (
                <Dialog id="remove-device" className="modal-content" isOpen={showDeleteConfirmDialog} initialFocusRef={cancelRef} onDismiss={setShowDeleteConfirmDialog(false)}>
                    <p>
                    Are you sure you want to remove Eevi Gateway phone number '{device.phoneNumber}' from this resident?
                    </p>
                    <div className="text-center">
                        <button ref={cancelRef}
                                onClick={setShowDeleteConfirmDialog(false)}
                                className="eevi_edit_button_dialog btn btn-outline-secondary btn-sm"
                                color="outline-secondary"
                        >No</button>
                        <button onClick={removeDevice}  // Yes, go ahead and remove it.
                                className="eevi_edit_button_dialog btn btn-outline-secondary btn-sm"
                                color="outline-secondary"
                        >Yes</button>
                    </div>
                </Dialog>
            )}*/}

            <EeviTooltip target={setEeviUid(device, "save")}>Update database</EeviTooltip>
            <EeviTooltip target={setEeviUid(device, "cancel")}>Return without updating</EeviTooltip>
            <EeviTooltip target={setEeviUid(device, "remove")}>Remove this device from the database</EeviTooltip>
        </div>
    );
}

function AccessoryDropDown(props: { device: any, attr: string, options: Map<EventType, string>, onSelect: Function }) {
    const {device, attr, options, onSelect} = props
    const [expand, setExpand] = React.useState(false)
    let items: any[] = [];
    options.forEach((value, key) => {
        items.push(<DropdownItem
            onClick={() => {
                onSelect(key)
                setExpand(false);
            }
            }
            key={`pendant-config-${key}`}
        >
            {value}
        </DropdownItem>)
    })
    return <Dropdown isOpen={expand} toggle={() => setExpand(!expand)}>
        <ButtonGroup>
            <Button
                className="eevi_edit_button eevi_light_grey_color"
                style={{width: "150px", textAlign: "left"}}
            >
                {options.get(device[attr]) ?? "Please choose"}
            </Button>
            <DropdownToggle
                split
                className="eevi_edit_button eevi_light_grey_color"
                style={{width: "30px"}}
            >
                <span className="sr-only">Toggle Dropdown</span>
            </DropdownToggle>
            <DropdownMenu style={{width: "180px"}} right>
                {items}
            </DropdownMenu>
        </ButtonGroup>
    </Dropdown>
}