import {React, Collapse} from "./eevi_react_exports";
import {EeviAction} from "./eevi_transform";


interface CollapseProperties {
    ikey: string;
    children: React.ReactNode;
    heading: string;
    defaultToCollapsed: boolean;
    onChange?: EeviAction<boolean>
}


export function EeviCollapseHeading(props: CollapseProperties) {
    const collapse = props.defaultToCollapsed;

    function onChange(collapse: boolean) {
        if (props.onChange) {
            props.onChange(collapse);
        }

    }

    const collapseClass = collapse ? "eevi_collapse_closed mb-1" : "eevi_collapse_open mb-3";
    return <>
        <button
            onClick={() => onChange(!collapse)}
            key={'h:' + props.ikey}
            className={collapseClass}>
            {props.heading}
            {
                collapse ?
                    <i className="far fa-folder fa-pull-left" aria-hidden="true"/> :
                    <i className="far fa-folder-open fa-pull-left" aria-hidden="true"/>

            }
        </button>
        <Collapse key={'c:' + props.ikey} isOpen={!collapse}>
            {(!collapse) && props.children}
        </Collapse>
    </>;
}

