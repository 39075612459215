import {EeviStandardState} from "../../components/eevi_standard_state";
import * as React from "react";
import {EeviPlugin, EeviPluginProps} from "../../components/eevi_plugin";
import {CareGroup, Device, EeviCoreAccess, EeviCoreModel, PlatformCode, ServiceLocation} from "../eevi_core_data";

export enum EeviDeviceEditAction {
    View,
    Insert
}

export interface EeviDeviceEditorProperties {
    platform: PlatformCode;
    serviceLocation: ServiceLocation;
    careGroup: CareGroup;
    devices: Array<Device>;
    refresh: VoidFunction;
    action: EeviDeviceEditAction;
    pluginUrl?: string;
    access: EeviCoreAccess;
    model: EeviCoreModel;
}

export interface EeviDeviceEditorState extends EeviStandardState {
    devices: Array<Device>;
}

export class EeviDeviceEditorPluginProps extends Map<PlatformCode, EeviPluginProps<EeviDeviceEditorProperties>> {
}

export class EeviDeviceEditorPlugin extends EeviPlugin<EeviDeviceEditorProperties> {

}

export class EeviDeviceEditor extends React.Component<EeviDeviceEditorProperties, EeviDeviceEditorState> {
    constructor(props: EeviDeviceEditorProperties) {
        super(props);
        this.state = {
            devices: this.props.devices
        };
    }
}

