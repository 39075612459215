import {React, Button} from "./eevi_react_exports";


export interface EeviRouteButtonProps {
    location: string
    labelText: string
    size?: string
    className?: string;
}

export interface EeviRouteButtonState {

}

export class EeviRouteButton extends React.Component<EeviRouteButtonProps, EeviRouteButtonState> {

    constructor(props: EeviRouteButtonProps) {
        super(props);
    }

    render() {
        // TODO: Make work with Redirect
        return <a href={this.props.location}>
            <Button size={this.props.size} className={this.props.className}>
                {this.props.labelText}
            </Button>
        </a>;
    }
}
