import {apiV1, MenuLink} from "../../../../common/web_common/containers/eevi_std_container";
import {
    EeviDropDownMenuLink,
    EeviForm,
    EeviFormProperties,
    EeviFormState,
    EeviMenuLinks,
    initialState
} from "../../../../common/web_common/forms/eevi_form";
import {
    AddDeviceToPlatform,
    CareGroup, CareGroupCode, Carer, Device, DevicePlatform, EditItem,
    EeviCoreAccess,
    EeviCoreModel, PlatformCode, ResponseGroupType,
    ServiceLocation, ServiceLocationCode, ServiceLocationDevices
} from "../../../../common/web_common/core/eevi_core_data";

import {EeviCoreContextData, EeviCoreContext} from "../../../../common/web_common/core/components/eevi_core_context";
import {
    DropdownItem,
    React,
} from "../../../../common/web_common/components/eevi_react_exports";
import {
    generateExternalDeviceEditorProps,
} from "../../../../common/web_common/core/test_helpers/care_group_test_data";
import {
    EeviDeviceEditAction,
    EeviDeviceEditorPluginProps,
    EeviDeviceEditorProperties
} from "../../../../common/web_common/core/components/eevi_device_editor";
import {EeviPluginProps} from "../../../../common/web_common/components/eevi_plugin";
import {DefaultDeviceEditor} from "./default_device_editor";
import {assert} from "../../../../common/web_common/components/eevi_assert";
import {EeviApi} from "../../../../common/web_common/components/eevi_api";
import {EeviLoading} from "../../../../common/web_common/components/eevi_loading";
import {eeviGlobal} from "../../../../common/web_common/components/eevi_context";
import {EeviStandardState} from "../../../../common/web_common/components/eevi_standard_state";
import {showServiceHealthLink} from "../forms/service_status_form";


export class SmPersState implements EeviFormState {
    loading?: boolean = false;
    nextToken?: string;
    redirect?: string;
    title?: string;
    windowHeight: number = 0;
    windowWidth: number = 0;
    error: any = undefined;

    // vendor settings
    vendorSettingsModeUrl?: string;
    deviceName?: string;
    iframeLoading: boolean = false;
    model?: EeviCoreModel;

    constructor(init?: object) {
        const initData = {
            ...initialState(),
            ...(init || {})
        };
        Object.assign(this, initData);
    }

}


export abstract class SmpersForm<P extends EeviFormProperties, S extends SmPersState>
    extends EeviForm<P, S> implements EeviCoreAccess {

    protected readonly api = EeviApi.fromComponent<S>(this);

    // Store the previous scroll position when we pop up the vendor settings
    protected scrollY: number = 0;

    private pollTimerId?: NodeJS.Timeout;
    private haltPolling: boolean = false;

    // There is a bit of legacy code that is state based rather than context based that we need to support.
    // So tie together the global EeviCoreModel from the context with EeviStandardState.
    get model(): EeviCoreModel {
        return this.state.model!;
    }

    set model(newModel: EeviCoreModel) {
        const state: SmPersState = this.state; // cast off the read-only bit
        state.model = newModel;
    }

    get eeviApi(): EeviApi<EeviStandardState> {
        return this.api as EeviApi<EeviStandardState>;
    }

    get error(): any {
        return this.state.error;
    }

    set error(value: any) {
        this.setState({error: value});
    }

    get carerBeingEdited(): Carer | undefined {
        return this.model.editCarer.item;
    }

    get serviceLocationBeingEdited(): ServiceLocation | undefined {
        return this.model.editServiceLocation.item;
    }

    get deviceBeingEdited(): Device | undefined {
        return this.model.editDevice.item;
    }

    get serviceLocationPlatformForDeviceInsert(): AddDeviceToPlatform {
        return this.model.serviceLocationPlatformForDeviceInsert;
    }

    protected get coreAccess(): EeviCoreAccess {
        return this;
    }

    getCareGroups(refresh = false): "loading" | CareGroup[] {
        if (refresh) {
            this.model!.careGroups = undefined;
        }
        if (this.model!.careGroups === undefined) {
            this.model!.careGroups = "loading";
            this.loadCareGroups();
        }
        return this.model!.careGroups;
    }

    getCarers(
        careGroupCode: CareGroupCode,
        refresh: boolean = false,
        clusterSize: number | undefined = undefined
    ): "loading" | Carer[] {
        const responseGroupType = this.getSelectedResponseGroupType();
        const careGroupCarers = this.model!.careGroupCarers.get(careGroupCode) ?? new Map();
        this.model!.careGroupCarers.set(careGroupCode, careGroupCarers);
        if (refresh) {
            careGroupCarers.delete(responseGroupType);
        }
        let carersLoading = careGroupCarers.get(responseGroupType);
        if (carersLoading === undefined) {
            carersLoading = "loading";
            careGroupCarers.set(responseGroupType, carersLoading);
            this.loadCarers(careGroupCode, responseGroupType, clusterSize);
        }
        return carersLoading;
    }

    insertCarer(careGroupCode: CareGroupCode, responseGroupType?: ResponseGroupType): void {
        const groupType = responseGroupType ?? this.getSelectedResponseGroupType();
        const careGroupCarers = this.model!.careGroupCarers.get(careGroupCode) ?? new Map();
        this.model!.careGroupCarers.set(careGroupCode, careGroupCarers);
        const newCarer = {
            careGroupCode: careGroupCode
        } as Carer;
        let carers = careGroupCarers.get(groupType);
        if (!(carers instanceof Array)) {
            carers = [];
            careGroupCarers.set(responseGroupType, carers);
        }
        carers.unshift(newCarer);
        this.editCarer(newCarer);
    }

    editCarer(carer: Carer): void {
        this.model.editCarer = new EditItem<Carer>(carer);
        this.forceUpdate();
    }

    logoutCarer(carer: Carer, callback: any): void {
        this.api.put<Carer>(
            `${apiV1}/village_admin/logout/${carer.careGroupCode}/${carer.carerId}`,
            undefined,
            undefined,
            (carer) => {
                callback(carer);
                const careGroupCarers = this.model!.careGroupCarers.get(carer.careGroupCode!);
                careGroupCarers!.forEach(
                    (value) => {
                        if (value instanceof Array) {
                            value.forEach(
                                (c) => {
                                    if (c.email === carer.email) {
                                        c.isOnline = carer.isOnline;
                                    }
                                }
                            )
                        }
                    }
                )
            }
        );
    }

    cancelEditCarer(): void {
        const carer = this.model.editCarer.item;
        if (carer && !carer.carerId) {
            // we're in insert mode - drop the first item in the list
            const careGroupCode = carer.careGroupCode;
            const responseGroupType = this.getSelectedResponseGroupType();
            const careGroupCarers = this.model!.careGroupCarers.get(careGroupCode) ?? new Map();
            this.model!.careGroupCarers.set(careGroupCode, careGroupCarers);
            const carers = careGroupCarers.get(responseGroupType) as Carer[];
            carers.shift();
        }
        this.setState({error: undefined});
        this.model.editCarer.cancelEdit();
        this.forceUpdate();
    }

    saveCarer(carer: Carer, carerIndex: number, loading: boolean): void {
        if (loading) {
            carer.loading = true;
        }
        const responseGroupType = this.getSelectedResponseGroupType();
        this.setState({error: undefined});
        this.api.connectWebSocket<Carer[]>(
            undefined, (resp) => this.setCarers(carer.careGroupCode, resp as Carer[]),
        ).then(
            (wsConnectionId) => this.api.save<Carer>(
                carer.carerId,
                `${apiV1}/care_groups/${carer.careGroupCode}/carers`,
                `wsConnectionId=${encodeURIComponent(wsConnectionId)}` +
                `&carerIndex=${carerIndex}` +
                `&groupType=${responseGroupType}&groupNumber=1`,
                carer,
                undefined,
                () => {
                }
            )
        );
    }

    removeCarer(carer: Carer): void {
        const responseGroupType = this.getSelectedResponseGroupType();
        this.setCarers(carer.careGroupCode, "loading");
        this.setState({error: undefined});
        this.api.connectWebSocket<Carer[]>(
            undefined,
            (carers) => this.setCarers(carer.careGroupCode, carers as Carer[]))
            .then((wsConnectionId) => this.api.delete<Carer, Carer[]>(
                `${apiV1}/care_groups/${carer.careGroupCode}/carers/${carer.carerId}` +
                `?wsConnectionId=${encodeURIComponent(wsConnectionId)}` +
                `&groupType=${responseGroupType}&groupNumber=1`,
                undefined,
                undefined,
                false
            ));
    }

    getCareGroupServiceLocations(
        careGroupCode: string,
        refresh = false,
        searchText: string = ""
    ): "loading" | ServiceLocation[] {
        const model = this.model!;
        if (refresh || searchText !== (model.careGroupServiceLocationSearchText.get(careGroupCode) || "")) {
            model.careGroupServiceLocations.delete(careGroupCode);
            model.careGroupServiceLocationSearchText.set(careGroupCode, searchText);
        }
        const serviceLocation = this.model!.careGroupServiceLocations.get(careGroupCode);
        if (serviceLocation === undefined) {
            this.model!.careGroupServiceLocations.set(careGroupCode, "loading");
            this.loadServiceLocations(careGroupCode, searchText);
        }
        return this.model!.careGroupServiceLocations.get(careGroupCode)!;
    }

    editServiceLocation(serviceLocation: ServiceLocation): void {
        this.model!.editServiceLocation = new EditItem<ServiceLocation>(serviceLocation);
        this.forceUpdate();
    }

    onModelUpdate(): void {
        this.forceUpdate();
    }

    saveServiceLocation(clearOccupancy: boolean): void {
        if (this.model.editServiceLocation.item !== undefined) {
            this.setServiceLocationLoading(true);
            this.setState({error: undefined});
            const location = this.model.editServiceLocation.item;
            this.haltPolling = true;
            this.api.connectWebSocket<ServiceLocation>(
                undefined,
                (updated) => this.updateServiceLocation(
                    location, updated as ServiceLocation, clearOccupancy)
            ).then(
                (wsConnectionId) => this.api.put<ServiceLocation>(
                    `${apiV1}/care_groups/${location.careGroupCode}` +
                    `/service_locations/${location.serviceLocationCode}` +
                    `?wsConnectionId=${encodeURIComponent(wsConnectionId)}` +
                    `&clearOccupancy=${clearOccupancy}`,
                    location,
                    undefined,
                    (updated) => this.updateServiceLocation(
                        location, updated as ServiceLocation, clearOccupancy)
                )
            );
        }
    }

    clearServiceLocation(): void {
        if (this.model.editServiceLocation.item === undefined) {
            return;
        }
        this.saveServiceLocation(true);
    }

    cancelEditDevice(): void {
        const device = this.model.editDevice.item;
        if (device && (device.deviceId === undefined || device.deviceId.endsWith('pending'))) {
            // new insert so remove
            this.model.devicePlatforms.removeDevice(device);
        }
        this.clearServiceLocationPlatformForDeviceInsert();
        this.model.editDevice.cancelEdit();
        this.setState({error: undefined});
    }

    editDevice(device: Device): void {
        this.clearServiceLocationPlatformForDeviceInsert();
        if (device !== this.model.editDevice.item) {
            assert(device === undefined || device.platform, "Invalid device.");
            this.model.editDevice = new EditItem<Device>(device);
            this.onModelUpdate();
        }
    }

    setServiceLocationLoading(loading: boolean): void {
        if (this.model.editServiceLocation.item !== undefined) {
            this.model.editServiceLocation.item.loading = loading;
        }
    }

    serviceLocationIsLoading(): boolean {
        return this.error === undefined &&
            this.model.editServiceLocation.item !== undefined &&
            this.model.editServiceLocation.item.loading === true;
    }

    setDeviceLoading(loading: boolean): void {
        if (this.model.editDevice.item !== undefined) {
            this.model.editDevice.item.loading = loading;
        }
        if (loading) { // will cause a refresh !!!
            this.setState({error: undefined});
        }
    }

    deviceIsLoading(): boolean {
        return this.error === undefined &&
            this.model.editDevice.item !== undefined &&
            this.model.editDevice.item.loading === true;
    }

    deviceHasChanged(): boolean {
        return this.model.editDevice.hasChanged();
    }

    removeDevice(): void {
        this.clearServiceLocationPlatformForDeviceInsert();
        const form = this;
        if (this.model.editDevice.item !== undefined) {
            this.setDeviceLoading(true);
            this.setState({error: undefined});
            const device = this.model.editDevice.item;

            function onDelete() {
                form.getDevicePlatforms(device.careGroupCode!, device.serviceLocationCode!, true);
                form.onModelUpdate();
            }

            this.setState({error: undefined});
            this.api.delete<Device, void>(
                `${apiV1}/care_groups/${device.careGroupCode}/service_locations/` +
                `${device.serviceLocationCode}/devices/${device.deviceId}`,
                undefined,
                onDelete
            );
        }
    }

    newDevice(device: Device): void {
        if (this.model.editDevice.item) {
            return;
        }
        assert(device.serviceLocationCode, "Service Location is mandatory.");
        assert(device.careGroupCode, "Care Group is mandatory.");
        assert(device.platform, "Platform is mandatory.");
        this.model.editDevice = new EditItem<Device>(device);
        this.model.devicePlatforms.getOrCreateDevicePlatform(
            device.careGroupCode,
            device.serviceLocationCode,
            device.platform.value,
            device
        );
    }

    refreshEditDevice(device: Device, updated: Partial<Device>, keepEditMode = false): void {
        Object.assign(device, updated);
        this.model.devicePlatforms.sortDevices(
            device.careGroupCode!,
            device.serviceLocationCode!,
            device.platform.value);
        this.setDeviceLoading(false);
        if (!keepEditMode) {
            this.model.editDevice = new EditItem<Device>();
        }
        this.onModelUpdate();
    }

    saveDevice(): void {
        this.clearServiceLocationPlatformForDeviceInsert();
        if (this.model.editDevice.item !== undefined) {
            this.setDeviceLoading(true);
            const device = this.model.editDevice.item;
            let key: string | undefined = device.deviceId;
            if (key === undefined || key.endsWith('pending')) {
                key = undefined;
                // caveat deviceId is mandatory so set to pending
                device.deviceId = `${device.platform.value}/pending`
            }
            const url = (
                `${apiV1}/care_groups/${device.careGroupCode}/service_locations/` +
                `${device.serviceLocationCode}/devices`
            );
            this.api.connectWebSocket<Device>(
                undefined,
                (updated) => this.refreshEditDevice(device, updated)
            ).then((wsConnectionId) => this.api.save<Device>(
                key,
                url,
                `wsConnectionId=${encodeURIComponent(wsConnectionId)}`,
                device,
                undefined,
                () => {
                }
            ))
        }
    }

    editDeviceAndAccessories(device: Device): void {
        this.model.editDevice = new EditItem<Device>(device);
        this.setDeviceLoading(true);
        const url = (
            `${apiV1}/care_groups/${device.careGroupCode}/service_locations/` +
            `${device.serviceLocationCode}/devices/${device.deviceId}?update_accessories=true`
        );
        this.api.get<Device>(
            url,
            undefined,
            (updated) => this.refreshEditDevice(device, updated, true)
        );
    }

    clearServiceLocationPlatformForDeviceInsert(): void {
        this.model.serviceLocationPlatformForDeviceInsert = {};
    }

    /**
     * Doesn't actually insert a device as some platforms will just go to edit mode on a single
     * existing device. It does make
     * @param platform
     * @param serviceLocation
     */
    setServiceLocationPlatformForDeviceInsert(platform: PlatformCode, serviceLocation: ServiceLocation): void {
        this.model.serviceLocationPlatformForDeviceInsert.platformCode = platform;
        this.model.serviceLocationPlatformForDeviceInsert.serviceLocation = serviceLocation;
        // make sure the platform exists for this service location
        this.model.devicePlatforms.getOrCreateDevicePlatform(
            serviceLocation.careGroupCode,
            serviceLocation.serviceLocationCode,
            platform,
            this.model.editDevice.item
        );
        this.onModelUpdate();
    }

    disabledDeviceEdits(): boolean {
        return this.model.editDevice.item !== undefined
            || this.model.serviceLocationPlatformForDeviceInsert.serviceLocation !== undefined;
    }

    showVendorSettings(
        deviceName: string,
        url: string): void {
        this.scrollY = window.scrollY;  // see closeVendorSettings()
        this.setState({
            deviceName: deviceName,
            vendorSettingsModeUrl: url,
            iframeLoading: true
        });
    }

    closeVendorSettings() {
        this.setState({
            deviceName: undefined,
            vendorSettingsModeUrl: undefined,
            iframeLoading: false
        });
        window.setTimeout(() => window.scrollTo(window.scrollX, this.scrollY));
    }

    getServiceLocationDevices(
        careGroupCode: CareGroupCode,
        serviceLocationCode: ServiceLocationCode,
        refresh = false): "loading" | Device[] {
        const devicePlatforms = this.getDevicePlatforms(careGroupCode, serviceLocationCode, refresh);
        if (devicePlatforms === "loading") {
            return "loading";
        } else {
            const devices: Device[] = [];
            for (let dp of devicePlatforms) {
                devices.push(...dp.devices);
            }
            return devices;
        }
    }

    pollForDeviceUpdates() {
        if (this.haltPolling) {
            return;
        }
        eeviGlobal.log(`pollForDeviceUpdates()`);
        for (let serviceLocations of this.model.careGroupServiceLocations.values()) {
            for (const sl of serviceLocations) {
                if (sl !== "loading" && (sl as ServiceLocation).showDevices) {
                    const serviceLocation = sl as ServiceLocation;
                    this.loadDevices(
                        serviceLocation.careGroupCode, serviceLocation.serviceLocationCode
                    );
                }
            }
        }

    }

    getServiceLocationDevicePlatforms(
        serviceLocation: ServiceLocation,
        refresh = false
    ): "loading" | DevicePlatform[] {
        const careGroupCode = serviceLocation.careGroupCode;
        const serviceLocationCode = serviceLocation.serviceLocationCode;
        return this.getDevicePlatforms(careGroupCode, serviceLocationCode, refresh);
    }

    getDevicePlatforms(
        careGroupCode: CareGroupCode,
        serviceLocationCode: ServiceLocationCode,
        refresh = false
    ): "loading" | DevicePlatform[] {
        if (refresh) {
            this.clearDeviceEdits(careGroupCode, serviceLocationCode);
            this.model!.devicePlatforms.clearDevicePlatforms(careGroupCode, serviceLocationCode);
        }
        let devicePlatforms = this.model!.devicePlatforms.getDevicePlatforms(careGroupCode, serviceLocationCode);
        if (devicePlatforms === undefined) {
            this.model!.devicePlatforms.setDevicePlatformsLoading(careGroupCode, serviceLocationCode);
            this.loadDevices(careGroupCode, serviceLocationCode);
            devicePlatforms = this.model!.devicePlatforms.getDevicePlatforms(careGroupCode, serviceLocationCode);
            assert(devicePlatforms);
        }
        this.updateDeviceWithEdits(devicePlatforms, serviceLocationCode);
        return devicePlatforms;
    }

    /**
     * Some platforms have their device editor loaded from an external site.
     * These will have a "url" property defined rather than "plugin".
     */
    getDeviceEditorProps(
        platform: DevicePlatform,
        serviceLocation: ServiceLocation,
        careGroup: CareGroup,
        action: EeviDeviceEditAction,
        refresh: boolean = false
    ): EeviPluginProps<EeviDeviceEditorProperties> {
        this.getAllDeviceEditorProps(refresh);
        const pluginProps = this.model.deviceEditorProps!.get(platform.platformCode) || {
            plugin: DefaultDeviceEditor,
            name: "..."
        };
        return {
            props: {
                access: this,
                model: this.model,
                platform: platform.platformCode,
                serviceLocation: serviceLocation,
                careGroup: careGroup,
                devices: platform.devices,
                refresh: () => {
                    this.getServiceLocationDevicePlatforms(serviceLocation, true);
                    this.onModelUpdate();
                },
                action: action,
            },
            ...pluginProps
        };
    }

    getAllDeviceEditorProps(refresh: boolean = false): EeviDeviceEditorPluginProps {

        if (refresh || this.model!.deviceEditorProps === undefined) {
            const externalPlugins = generateExternalDeviceEditorProps();
            this.model!.deviceEditorProps = new EeviDeviceEditorPluginProps([
                ...this.model!.internalDeviceEditorProps, ...externalPlugins
            ]);
        }
        return this.model!.deviceEditorProps;
    }

    selectCareGroup(careGroupCode?: CareGroupCode): void {
        if (this.model.selectedCareGroupCode !== careGroupCode) {
            this.model.selectedCareGroupCode = careGroupCode;
            this.onModelUpdate();
        }
    }

    getSelectedCareGroup(): CareGroupCode | undefined {
        return this.model.selectedCareGroupCode;
    }

    selectResponseGroupType(groupType?: ResponseGroupType): void {
        if (this.model.selectedResponseGroupType !== groupType) {
            this.model.selectedResponseGroupType = groupType;
            this.onModelUpdate();
        }
    }

    getSelectedResponseGroupType(): ResponseGroupType {
        return this.model.selectedResponseGroupType ?? ResponseGroupType["Alarm Response"];
    }

    render(): React.ReactNode {
        if (this.state.error && this.model.editCarer.item) {
            this.model.editCarer.item.loading = false;
        }

        return <EeviCoreContext.Consumer>
            {
                (context: EeviCoreContextData) => this.renderContext(context)
            }
        </EeviCoreContext.Consumer>
    }

    protected onFormDidMount() {
        this.getCareGroups(true);
        if (this.pollTimerId === undefined) {
            this.pollTimerId = setInterval(() => this.pollForDeviceUpdates(), 15000);
        }
    }

    protected onFormWillUnmount() {
        if (this.pollTimerId !== undefined) {
            clearInterval(this.pollTimerId);
            this.pollTimerId = undefined;
        }
    }

    protected menuLinks(): MenuLink[] {
        let menu_links = [
            {title: 'Residents', link: '/residents'},
            {title: 'Carers', link: '/carers'},
            {title: 'Events', link: '/events'},
            {title: 'Smart Annunciator', link: '/event_status'},
            //{title: 'Service Status', link: '/service_status'},
            {title: 'Dashboard', link: '/dashboard'}
        ];

        if (showServiceHealthLink()) {
            menu_links.splice(4, 0, {title: 'Service Status', link: '/service_status'},);
        }
        return menu_links;
    }

    protected renderMenus(): React.ReactNode | undefined {
        return <div className="d-flex justify-content-center">
            <EeviMenuLinks loading={this.state.loading} menuLinks={this.menuLinks()} title={this.getTitle()}>
                <EeviDropDownMenuLink>
                    <div className="card shadow pt-4 pb-2 border bg-white">
                        <div className="font-weight-bold font-italic smaller border-bottom w-100 center pb-2">
                            {eeviGlobal.loggedInUser.email}
                        </div>
                        <DropdownItem onClick={() => this.props.history.replace('/logout', this.props.location)}>
                            <div className="ml-3 mt-2 smaller">Log out</div>
                        </DropdownItem>
                    </div>
                </EeviDropDownMenuLink>
            </EeviMenuLinks>
        </div>;

    }

    protected setPartialState(partialState: Partial<S>) {
        this.setState(prev => ({...prev, ...partialState}));
    }

    private loadCareGroups(): void {
        this.setState({error: undefined});
        this.api.get<CareGroup[]>(
            `${apiV1}/care_groups`,
            undefined,
            (careGroups) => {
                this.model!.careGroups = careGroups as CareGroup[];
                this.setState({loading: false});
            }
        );
    }

    private loadCarers(
        careGroupCode: CareGroupCode,
        responseGroupType?: ResponseGroupType,
        clusterSize: number | undefined = undefined
    ): void {
        const groupType = responseGroupType ?? this.getSelectedResponseGroupType();
        this.setState({error: undefined});
        let cp = '';
        if (clusterSize !== undefined) {
            cp = `&clusterSize=${clusterSize}`;
        }
        this.api.get<Carer[]>(
            `${apiV1}/care_groups/${careGroupCode}/carers?groupType=${groupType}&groupNumber=1${cp}`,
            undefined,
            (carers) => this.setCarers(careGroupCode, carers as Carer[])
        );
    }

    private setCarers(
        careGroupCode: CareGroupCode,
        carers: Carer[] | "loading",
        responseGroupType?: ResponseGroupType
    ) {
        const groupType = responseGroupType ?? this.getSelectedResponseGroupType();
        const careGroupCarers = this.model!.careGroupCarers.get(careGroupCode) ?? new Map();
        this.model!.careGroupCarers.set(careGroupCode, careGroupCarers);
        careGroupCarers.set(groupType, carers as Carer[]);
        this.model.editCarer = new EditItem<Carer>();
        this.setState({loading: false});
    }

    private loadServiceLocations(careGroupCode: string, searchText: string = ""): void {
        // Rather than make multiple server trips, we now /call service_location_devices
        // not /service_locations and load the devices at the same time.
        this.setState({error: undefined});
        let searchParameter: string;
        if (searchText !== undefined && searchText !== '') {
            searchParameter = `?search=${searchText}`;
        } else {
            searchParameter = "";
        }
        this.api.get<ServiceLocationDevices[]>(
            `${apiV1}/care_groups/${careGroupCode}/service_location_devices${searchParameter}`,
            undefined,
            (items) => {
                const serviceLocations = items.map(i => i!.serviceLocation);
                this.model!.careGroupServiceLocations.set(careGroupCode, serviceLocations);
                for (const locationDevices of items) {
                    const serviceLocationCode = locationDevices!.serviceLocation.serviceLocationCode;

                    // We now include JUPL pendants which we need to remove before rendering otherwise we generate
                    // multiple summary blocks.
                    const devices = locationDevices!.devices.filter(
                        (e) => e.deviceType.value !== "Pendant"
                    );

                    this.model.devicePlatforms.addDevices(
                        careGroupCode,
                        serviceLocationCode,
                        devices,
                        this.model.editDevice.item,
                        this.serviceLocationPlatformForDeviceInsert
                    );
                }
                this.setState({loading: false});
                // if (this.state.loading) {
                //     this.setState({loading: false});
                // }
            }
        );
    }

    private updateServiceLocation(location: ServiceLocation, updated: ServiceLocation, clearOccupancy: boolean) {
        Object.assign(location, updated);
        this.setServiceLocationLoading(false);
        this.model.editServiceLocation = new EditItem<ServiceLocation>();
        if (clearOccupancy) {
            // we've removed personal devices'
            this.getServiceLocationDevicePlatforms(location, true);
        }
        this.onModelUpdate();
        this.haltPolling = false;
    }

    private updateDeviceWithEdits(devicePlatforms: "loading" | DevicePlatform[], serviceLocationCode: string) {
        // now that we are continually polling for device updates we need to maintain the idea of the
        // currently edited device.
        const item = this.model.editDevice.item;

        function matchPlatform(dp: DevicePlatform): boolean {
            return dp.platformCode === item!.platform.value;
        }

        let devicePlatform: DevicePlatform | undefined;
        if (devicePlatforms == "loading" ||
            item === undefined ||
            item.serviceLocationCode !== serviceLocationCode ||
            !(devicePlatform = devicePlatforms.find(matchPlatform))) {
            return;
        }

        function matchDevice(device: Device): boolean {
            return device.deviceId === item!.deviceId;
        }

        let idx: number;
        if ((idx = devicePlatform.devices.findIndex(matchDevice)) === -1) {
            // not found - so put back insert
            if (!this.model.editDevice.item!.deviceId) {
                devicePlatform.devices.unshift(this.model.editDevice.item!);
            }
        } else {
            const devices = devicePlatform.devices;
            // const device = devices[idx];
            devices[idx] = item!;
            // don't update copy as results in disabled save on poll
            // if (device.deviceId) {
            //     this.model.editDevice.copyOfOriginal = deepCopy(device);
            // }
        }
    }

    private clearDeviceEdits(
        careGroupCode: CareGroupCode,
        serviceLocationCode: ServiceLocationCode
    ) {
        const addDevice = this.model.serviceLocationPlatformForDeviceInsert;
        if (addDevice.serviceLocation !== undefined
            && addDevice.serviceLocation.careGroupCode === careGroupCode
            && addDevice.serviceLocation.serviceLocationCode === serviceLocationCode) {
            this.model.serviceLocationPlatformForDeviceInsert = {};
        }
        const editDevice = this.model.editDevice.item;
        if (editDevice !== undefined
            && editDevice.careGroupCode === careGroupCode
            && editDevice.serviceLocationCode === serviceLocationCode) {
            this.model.editDevice = new EditItem<Device>();
        }
    }

    private loadDevices(
        careGroupCode: string,
        serviceLocationCode: string,
    ): void {
        this.api.get<Device[]>(
            `${apiV1}/care_groups/${careGroupCode}/service_locations/${serviceLocationCode}/devices`,
            undefined,
            (rawDevices) => {

                // We now include JUPL pendants which we need to remove before rendering otherwise we generate
                // multiple summary blocks.
                const devices = rawDevices.filter(
                    (e) => e?.deviceType.value !== "Pendant"
                );

                this.model!.devicePlatforms.addDevices(
                    careGroupCode,
                    serviceLocationCode,
                    devices as Device[],
                    this.model.editDevice.item,
                    this.serviceLocationPlatformForDeviceInsert
                );
                this.setState({loading: false});
            }
        );
    }

    private setModel(model: EeviCoreModel) {
        this.model = model;
        setTimeout(() => this.forceUpdate(), 0);
    }

    /**
     * All Village Care forms and subordinate components share a global context that includes the EeviCoreModel and
     * access to it via the parent form.
     * @param context
     */
    private renderContext(context: EeviCoreContextData): React.ReactNode {
        if (!this.model) {
            this.setModel(context.model!);
            return <></>;
        } else {
            return <EeviCoreContext.Provider value={{model: context.model, access: this}}>
                {
                    this.state.vendorSettingsModeUrl ? this.popUpVendorSettingsPage() : super.render()
                }
            </EeviCoreContext.Provider>;
        }

    }

    /**
     * Copied over from the SMPERS v1 Residents Form. This is a pop-up that takes over the browser tab with an
     * embedded device vendor page.
     */
    private popUpVendorSettingsPage() {
        return <div
            style={{top: 0, left: 0, right: 0, bottom: 0}}
            className="position-fixed"
            hidden={this.state.vendorSettingsModeUrl === undefined}>
            <div className="eevi_gold_band strong text-center" hidden={this.state.iframeLoading}>
                {this.state.deviceName || "Medical Alarm"}
                <i className="fa fa-window-close position-fixed"
                   style={{color: "#fcb813", backgroundColor: "#3e444f", top: "3px", right: "6px"}}
                   onClick={() => this.closeVendorSettings()}/>
            </div>
            {this.state.iframeLoading && <EeviLoading/>}
            <iframe
                width="100%"
                height="100%"
                hidden={this.state.iframeLoading}
                onLoad={() => window.setTimeout(() => this.setState({iframeLoading: false}), 400)}
                src={this.state.vendorSettingsModeUrl!}/>
        </div>;
    }
}

