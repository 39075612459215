import {React, Container} from "./eevi_react_exports";


export function UnderMaintenance() {
    return <Container>
        <div className="shadow p-5 mt-5 mb-5">
            <h3>eevi is getting an upgrade...</h3>
            <p className="lead">Administration functions are currently unavailable.</p>
            <hr className="my-2"/>
            <p className="font-weight-bold">Eevi gateways continue to function.</p>
        </div>
    </Container>;
}

