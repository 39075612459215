import {EeviEventHandler} from "./eevi_event_handler";
import {EeviTransform} from "./eevi_transform";
import {React, ReactNode, Table} from "./eevi_react_exports";

interface EeviColumnDefinition<T> {
    columnHeaderTitle: string;
    dataKey: keyof T;
    width?: string;
    formatter?: EeviTransform<any, any>;
    styles?:  Array<EeviTransform<any, string>>
}

interface EeviTableProperties<T> {
    columnDefinitions: Array<EeviColumnDefinition<T>>;
    primaryKey: keyof T;
    data: Array<T>;
    onSelect?: EeviEventHandler<T>;
    noDataPlaceholder?: ReactNode;
    fixedWidth?: Boolean;
}


function EeviTable<T>(props: EeviTableProperties<T>) {
    function OnRowClicked(e: React.MouseEvent<HTMLTableRowElement>, item: T) {
        if (props.onSelect) {
            props.onSelect(item);
        }
    }

    function CellData(columnDefinition: EeviColumnDefinition<T>, rowData: T): any {
        const cellData = rowData[columnDefinition.dataKey];
        if (columnDefinition.formatter) {
            return columnDefinition.formatter(cellData)
        }
        else {
            return cellData;
        }

    }

    function TableRow(rowData: T): React.ReactNode {
        return (
            <tr key={String(rowData[props.primaryKey])} onClick={(e) => OnRowClicked(e, rowData)}>
                {props.columnDefinitions.map(cd =>
                    <td className={(cd.styles || []).map(t => t(rowData[cd.dataKey])).join(' ')}
                        key={String(cd.dataKey)}>{CellData(cd, rowData)}</td>)}
            </tr>
        );
    }
    const tableBody = (props.noDataPlaceholder !== undefined && props.data.length == 0) ? props.noDataPlaceholder : props.data.map(TableRow)

    return (
        <Table striped bordered hover responsive style={props.fixedWidth ? {wordWrap: "break-word", tableLayout:'fixed'}:{}}>
            <thead>
            <tr>
                {props.columnDefinitions.map(cd =>
                    <th key={cd.columnHeaderTitle} className="keep-lines" style={props.fixedWidth ? {width: cd.width}:{}}>{cd.columnHeaderTitle}</th>)}
            </tr>
            </thead>
            <tbody>{tableBody}</tbody>
        </Table>
    );

}

export {EeviColumnDefinition, EeviTable};
