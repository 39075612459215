import {RawHistoricalData} from "../device_connectivity_components";

const EMFIT_DEMO_DEVICE_ID: string = "em/123ABC";
const EMFIT_DEMO_PENDENT_ID: string = "XYZ321";

export function getConnectivityDemoConfig(demoMode: number): {
    startTime: Date,
    endTime: Date,
    deviceId: string,
    deviceModel: string,
    deviceName: string,
    enablePendant: boolean,
    pendantId: string[] | null | undefined,
    serviceAddressCode: string
} | null {

    // Note all date are in UTC!

    // Demo 1 is Pendant enabled and last seen wifi on same day
    const data1 = {
        startTime: new Date(Date.parse("2023-06-18 20:13:57Z")),
        endTime: new Date(Date.parse("2023-06-18 20:34:57Z")),
        deviceId: EMFIT_DEMO_DEVICE_ID,
        deviceModel: "Emfit QS+Care",
        deviceName: "Han Solo",
        enablePendant: true,
        pendantId: [
            EMFIT_DEMO_PENDENT_ID
        ],
        serviceAddressCode: "Jabba the Hutt's Palace"
    };

    // Demo 2 is Pendant not enabled wifi on same day
    const data2 = {
        startTime: new Date(Date.parse("2023-06-18 20:13:57Z")),
        endTime: new Date(Date.parse("2023-06-18 20:34:57Z")),
        deviceId: EMFIT_DEMO_DEVICE_ID,
        deviceModel: "Emfit QS+Care",
        deviceName: "Wicket",
        enablePendant: false,
        pendantId: [],
        serviceAddressCode: "Bright Tree Village"
    };

    // Demo 3 is Pendant enabled and hasn't been seen yet wifi on different days
    const data3 = {
        startTime: new Date(Date.parse("2023-06-18 20:13:57Z")),
        endTime: new Date(Date.parse("2023-06-19 20:34:57Z")),
        deviceId: EMFIT_DEMO_DEVICE_ID,
        deviceModel: "Emfit QS+Care",
        deviceName: "Bail Organa",
        enablePendant: true,
        pendantId: [EMFIT_DEMO_PENDENT_ID],
        serviceAddressCode: "The Tantive IV"
    };

    let data;
    switch (demoMode) {
        case 1:
            data = data1;
            break;
        case 2:
            data = data2;
            break;
        case 3:
            data = data3;
            break;
        default:
            data = null;
    }
    return data;
}

export function getConnectivityDemoData(demoMode: number): RawHistoricalData | null {
    // Note all dates are in UTC!
    const map: { [id: string]: string; } = {};
    map[EMFIT_DEMO_PENDENT_ID] = "2023-06-18 20:15:45Z";

    const data1: RawHistoricalData =
        {
            pendant:
            map,
            wifi: {
                "2023-06-18": {
                    "20:13:57Z": "1",
                    "20:14:57Z": "1",
                    "20:15:57Z": "1",
                    "20:16:57Z": "0",
                    "20:17:57Z": "0",
                    "20:18:57Z": "0",
                    "20:19:57Z": "0",
                    "20:20:57Z": "1",
                    "20:21:57Z": "1",
                    "20:22:57Z": "1",
                    "20:23:57Z": "1",
                    "20:24:57Z": "1",
                    "20:25:57Z": "1",
                    "20:26:57Z": "1",
                    "20:27:57Z": "1",
                    "20:28:57Z": "1",
                    "20:29:57Z": "1",
                    "20:30:57Z": "1",
                    "20:31:57Z": "1",
                    "20:32:57Z": "1",
                    "20:33:57Z": "1",
                    "20:34:57Z": "1",
                }
            }
        };

    const data2: RawHistoricalData =
        {
            pendant: null,
            wifi: {
                "2023-06-18": {
                    "20:13:57Z": "1",
                    "20:14:57Z": "1",
                    "20:15:57Z": "1",
                    "20:16:57Z": "0",
                    "20:17:57Z": "0",
                    "20:18:57Z": "0",
                    "20:19:57Z": "0",
                    "20:20:57Z": "1",
                    "20:21:57Z": "1",
                    "20:22:57Z": "1",
                    "20:23:57Z": "1",
                    "20:24:57Z": "1",
                    "20:25:57Z": "1",
                    "20:26:57Z": "1",
                    "20:27:57Z": "1",
                    "20:28:57Z": "0",
                    "20:29:57Z": "0",
                    "20:30:57Z": "0",
                    "20:31:57Z": "1",
                    "20:32:57Z": "1",
                    "20:33:57Z": "1",
                    "20:34:57Z": "1",
                }
            }
        };

    let data: RawHistoricalData | null;
    switch (demoMode) {
        case 1:
            data = data1;
            break;

        case 2:
            data = data2;
            break;

        case 3:    // Like 2
            data = data2;
            break;

        default:
            data = null;
    }

    return data;
}
