import {v4} from 'uuid';
import {eeviGlobal} from "./eevi_context";

export function getUrlBase(fullUrl: string): string {
    return fullUrl.replace(/(http(s)?:\/\/)|(\/.*){1}/g, '');
}

export function eeviUuid() {
    return v4();
}


export type WithEeviUid<T> = T & { eeviUid: string };

/**
 * Add a uuid to an existing object if it has not already been done
 */
export function setEeviUid<T>(item: T, prefix?: string): string {
    const _prefix = prefix || '';
    const itemWithId = item as WithEeviUid<T>;
    if (!itemWithId.eeviUid) {
        itemWithId.eeviUid = eeviUuid();
    }
    return `${_prefix}${itemWithId.eeviUid}`;
}

export function eeviId(): string {
    return 'xxxxxx'.replace(/[x]/g, function (c) {
        const r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
}

export function isNil(item: any): boolean {
    return (item === null || item === undefined);
}

export function isEmptyObject(item: any): boolean {
    return Object.entries(item).length === 0;
}

export function isBlankString(item: string | undefined | null): boolean {
    return isNil(item) || item!.trim() === "";
}

export function coalesce<T>(item: T | null | undefined, defaultValue: T): T {
    return isNil(item) ? defaultValue : item!;
}


export function setInputFocus(input: HTMLOrSVGElement | null): HTMLOrSVGElement | null {
    if (input) {
        input.focus();
    }
    return input;
}


export function* groupBy<TGroupBy>(key: keyof TGroupBy, items: Iterable<TGroupBy>): Iterable<TGroupBy[]> {
    let lastKey: any = undefined;
    let nextGroup: TGroupBy[] = [];
    for (const item of items) {
        const value = item[key];
        if (lastKey !== value) {
            lastKey = value;
            if (nextGroup.length > 0) {
                yield nextGroup;
                nextGroup = [];
            }
        }
        nextGroup.push(item)
    }
    if (nextGroup.length > 0) {
        yield nextGroup;
    }
}

export function withSpaces(...items: (string | undefined | null)[]): string {
    return items.filter(item => !isBlankString(item)).join(' ');
}

export function withLineBreaks(...items: (string | undefined | null)[]): string {
    return items.filter(item => !isBlankString(item)).join('\n');
}

export function moveUp<T>(index: number, items: Array<T>, noShift?: boolean): number {
    if (index <= 0) {
        return 0;
    }
    if (!noShift) {
        const current = items[index];
        items[index] = items[index - 1];
        items[index - 1] = current;
    }
    return index - 1;
}

export function moveDown<T>(index: number, items: Array<T>, noShift?: boolean): number {
    if (index >= items.length - 1) {
        return items.length - 1;
    }
    if (!noShift) {
        const current = items[index];
        items[index] = items[index + 1];
        items[index + 1] = current;
    }
    return index + 1;
}

export function dateFromIsoString(isoString?: string): Date {
    if (isNil(isoString)) {
        return new Date();
    } else {
        return new Date(Date.parse(isoString!));
    }
}

const ONE_SECOND_IN_MILLISECONDS = 1000;
const ONE_MINUTE_IN_MILLISECONDS = ONE_SECOND_IN_MILLISECONDS * 60;
const ONE_HOUR_IN_MILLISECONDS = ONE_MINUTE_IN_MILLISECONDS * 60;
const ONE_DAY_IN_MILLISECONDS = ONE_HOUR_IN_MILLISECONDS * 24;

export function dateAddDays(
    dt: Date,
    days: number,
    hours?: number,
    minutes?: number,
    seconds?: number,
    milliseconds?: number): Date {
    return new Date(
        dt.getTime() + days * ONE_DAY_IN_MILLISECONDS +
        (hours || 0) * ONE_HOUR_IN_MILLISECONDS +
        (minutes || 0) * ONE_MINUTE_IN_MILLISECONDS +
        (seconds || 0) * ONE_SECOND_IN_MILLISECONDS +
        (milliseconds || 0)
    );
}

export function isSameDateTime(lhs: Date, rhs: Date): boolean {
    return lhs.getTime() === rhs.getTime();
}

export function isSameDay(lhs: Date, rhs: Date, localTime: boolean): boolean {
    return isSameDateTime(startOfDay(lhs, localTime), startOfDay(rhs, localTime));
}

export function isToday(lhs: Date, localTime: boolean): boolean {
    return isSameDay(lhs, new Date(), localTime);
}

export function isEndOfDay(dt: Date): boolean {
    return isSameDateTime(dt, endOfDay(dt));
}

export function endOfDay(dt?: Date, localTime?: boolean): Date {
    const _dt = dt || new Date();
    if (localTime || localTime === undefined) {
        return new Date(
            _dt.getFullYear(),
            _dt.getMonth(),
            _dt.getDate(),
            23,
            59,
            59,
            999
        );
    }
    else {
        return new Date(
            Date.UTC(
                _dt.getUTCFullYear(),
                _dt.getUTCMonth(),
                _dt.getUTCDate(),
            23,
            59,
            59,
            999
            )
        );

    }
}

export function endOfMinute(dt?: Date): Date {
    const _dt = dt || new Date();
    return new Date(
        _dt.getFullYear(),
        _dt.getMonth(),
        _dt.getDate(),
        _dt.getHours(),
        _dt.getMinutes(),
        59,
        999
    );
}


export function startOfDay(dt: Date, localTime: boolean): Date {
    return localTime ?
        new Date(
            dt.getFullYear(),
            dt.getMonth(),
            dt.getDate()
        ) :
        new Date(
            Date.UTC(
                dt.getUTCFullYear(),
                dt.getUTCMonth(),
                dt.getUTCDate()
            )
        );
}

export function startOfWeek(dt: Date, localTime: boolean): Date {
    return startOfDay(dateAddDays(dt, -dt.getDay()), localTime);
}

export function treatUtcDateAsLocal(dt: Date): Date {
    return new Date(
        dt.getUTCFullYear(),
        dt.getUTCMonth(),
        dt.getUTCDate(),
        dt.getUTCHours(),
        dt.getUTCMinutes(),
        dt.getSeconds(),
        dt.getUTCMilliseconds()
    );
}

export function getLoggedInEmailAddress(): string | undefined {
    const email = eeviGlobal.loggedInUser.email;
    if (email === "unknown") {
        return undefined;
    }
    return email;
}

export function getProperty<T = any>(item: any, propertyName: string, defaultValue: T): T {
    if (propertyName in item) {
        return item[propertyName];
    } else {
        return defaultValue;
    }

}

/**
 * Get around issues with onchange event not firing when lastpass or other programmatically sets values.
 */
export function setNativeValue(element: any, value: any) {
    const valueSetter = Object.getOwnPropertyDescriptor(element, 'value')!.set;
    const prototype = Object.getPrototypeOf(element);
    const prototypeValueSetter = Object.getOwnPropertyDescriptor(prototype, 'value')!.set;

    if (valueSetter && valueSetter !== prototypeValueSetter) {
        prototypeValueSetter!.call(element, value);
    } else {
        valueSetter!.call(element, value);
    }
}

export function getNativeValue(element: any): any {
    const valueSetter = Object.getOwnPropertyDescriptor(element, 'value')!.get;
    const prototype = Object.getPrototypeOf(element);
    const prototypeValueSetter = Object.getOwnPropertyDescriptor(prototype, 'value')!.get;

    if (valueSetter && valueSetter !== prototypeValueSetter) {
        return prototypeValueSetter!.call(element);
    } else {
        return valueSetter!.call(element);
    }
}

