import {assert} from "./eevi_assert";

export interface EeviTransform<In, Out> {
    (item: In): Out;
}

export interface EeviFunc<Out> {
    (): Out;
}

export type EeviAction<In> = EeviTransform<In, void>;

export const transformDate = (d: string | number, transform: EeviTransform<Date, string>) => {
    let param = d;
    if (typeof (param) === 'number') {
        param = 1000 * param;  // assume timestamp in seconds
    }
    return transform(new Date(param));
};

export const localDateTimeString = (d: string | number) => transformDate(d, dt => dt.toLocaleString());
export const localTimeString = (d: string | number) => transformDate(d, dt => dt.toLocaleTimeString());
export const localDateString = (d: string | number) => transformDate(d, dt => dt.toLocaleDateString());
export const defaultValue = (defaultItem: any) => (item: any) => item || defaultItem;
export const use = (item: any) => (ignore: any) => item;
export const useValue = (item: any) => item;
export const ifValue = (rhs: any, value: any, elseValue: any = '') => (item: any) => item === rhs ? value : elseValue;
export const asLines = (...lines: string[]): string => lines.filter(l => l).join('\n');
export const ifIn = (rhs: any, value: any, elseValue: any = undefined) =>
    (item: any) => rhs.includes(item) ? value : elseValue || item;


export function titleCase(str: string): string {
    return str
        .toLowerCase()
        .split(' ')
        .map(word => word.replace(word[0], word[0].toUpperCase()))
        .join(' ');
}

export type Constructor<T = {}> = new (...args: any[]) => T;


/**
 * Create a mixin of an existing object instance and a class T.
 */
export function dynamicSubclass<T, TBase>(
    item: TBase, ctor: Constructor<T & object>, ...args: any[]): (T & TBase) {
    assert(item !== undefined, "Cannot subclass undefined.")
    const prototype = Object.getPrototypeOf(item);
    if (!(prototype instanceof ctor)) {
        Object.setPrototypeOf(item, new ctor(...args));
    }
    return item as T & TBase;
}

/**
 * Based on https://basarat.gitbook.io/typescript/type-system/literal-types
 */
export function strEnum<T extends string>(...o: T[]): {[K in T]: K} & Iterable<string>  {
    const base = {
        [Symbol.iterator]() {
            return o[Symbol.iterator]();
        }
    } as any;
    return o.reduce((res, key) => {
        res[key] = key;
        return res;
    }, base);

}


/**
 * Deep copy function for TypeScript.
 * @param T Generic type of target/copied value.
 * @param target Target value to be copied.
 * @see Source project, ts-deepcopy https://github.com/ykdr2017/ts-deepcopy
 * @see Code pen https://codepen.io/erikvullings/pen/ejyBYg
 */
export const deepCopy = <T>(target: T): T => {
    if (target === null) {
        return target;
    }
    if (target instanceof Date) {
        return new Date(target.getTime()) as any;
    }
    if (target instanceof Array) {
        const cp = [] as any[];
        (target as any[]).forEach((v) => {
            cp.push(v);
        });
        return cp.map((n: any) => deepCopy<any>(n)) as any;
    }
    if (typeof target === 'object' && target !== {}) {
        const cp = {...(target as { [key: string]: any })} as { [key: string]: any };
        Object.keys(cp).forEach(k => {
            cp[k] = deepCopy<any>(cp[k]);
        });
        return cp as T;
    }
    return target;
};

export function createFrom<T1, T2 extends T1>(src: T1, ctor: Constructor<T2>, ...args: any[]) {
    const item = new ctor(...args);
    Object.assign(item, src);
    return item;
}

export function factoryFrom<T1, T2 extends T1>(ctorSrc: Constructor<T1>, ctor: Constructor<T2>, ...args: any[]) {
    return function (src: T1): T2 {
        const item = new ctor(...args);
        Object.assign(item, src);
        return item;
    }
}