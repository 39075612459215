import {React} from "./eevi_react_exports";
import {EeviLoading} from "./eevi_loading";
import {assert} from "./eevi_assert";
import {eeviGlobal, EeviGlobal} from "./eevi_context";
import {apiV1} from "../containers/eevi_std_container";
import {EeviApi} from "./eevi_api";
import {EeviStandardState} from "./eevi_standard_state";

export interface EeviPluginProps<TPluginProps extends { pluginUrl?: string } = {}> {
    url?: string;
    props?: TPluginProps;
    plugin?: any;
    name: string;
}


export class EeviExportPlugin {
    constructor(readonly plugin: any) {

    }

    set global(value: EeviGlobal) {
        Object.assign(eeviGlobal, value);
    }

    get global(): EeviGlobal {
        return eeviGlobal;
    }

}

/**
 * Load React components from somewhere other than the default bundle.
 */
export class EeviPlugin<TPluginProps = {}>
    extends React.Component<EeviPluginProps<TPluginProps>, { exportPlugin?: EeviExportPlugin }> {
    static pluginCache = new Map<string, EeviExportPlugin>();
    static refreshTimerId?: NodeJS.Timeout;
    debugPoint: any;

    constructor(props: EeviPluginProps<TPluginProps>) {
        assert(props.url || props.plugin, "Either a url or a plugin component must be specified.");
        super(props);
        const plugin = props.plugin || EeviLoading;
        this.state = {exportPlugin: new EeviExportPlugin(plugin)}
    }

    componentDidMount(): void {
        if (!this.props.url) {
            return; // plugin was provided at construction not via url
        }

        if (EeviPlugin.refreshTimerId === undefined) {
            EeviPlugin.refreshTimerId = setInterval(() => this.refreshPluginTokens(), 300000);
        }

        const exportPlugin: EeviExportPlugin | undefined = EeviPlugin.pluginCache.get(this.props.url);

        if (exportPlugin) {
            this.setState({exportPlugin: exportPlugin});
        } else {
            fetch(this.props.url)
                .then((resp) => resp.text())
                .then((code) => this.loadPlugin(code))
                .catch((error) => {
                    console.error(this.props!.url);
                    console.error(error);
                    this.setState({exportPlugin: undefined});
                });
        }
    }

    componentWillUnmount(): void {
        // if (!this.props.url) {
        //     return; // plugin was provided at construction not via url
        // }
        // EeviPlugin.pluginCache.delete(this.props.url!);
        // if (EeviPlugin.pluginCache.size === 0) {
        //     clearInterval(EeviPlugin.refreshTimerId!);
        //     EeviPlugin.refreshTimerId = undefined;
        // }

    }

    private loadPlugin(code: string) {
        let pluginCode = new Function(code)();
        if (pluginCode === undefined) {
            const libName = this.props.name.toLowerCase().replace(/\s/g,'');
            pluginCode = window[libName as any];
        }
        if (pluginCode) {
            const exportPlugin: EeviExportPlugin = pluginCode.default;
            this.debugPoint = exportPlugin.plugin.debugPoint || pluginCode.debugPoint;
            const api = new EeviApi<EeviStandardState>(
                () => {
                    this.setPlugin(exportPlugin);
                },
                () => eeviGlobal.headerConfig()
            );
            api.get(`${apiV1}/refresh_token`);
        }
    }

    private setPlugin(exportPlugin: EeviExportPlugin) {
        exportPlugin!.global = eeviGlobal;
        EeviPlugin.pluginCache.set(this.props.url!, exportPlugin!);
        this.setState({exportPlugin: exportPlugin});
    }

    private refreshPluginTokens() {
        const api = new EeviApi<EeviStandardState>(
            () => {
                for (const exportPlugin of EeviPlugin.pluginCache.values()) {
                    if (exportPlugin.global) {
                        exportPlugin.global = eeviGlobal;
                    }
                }
            },
            () => eeviGlobal.headerConfig()
        );
        api.get(`${apiV1}/refresh_token`);
    }

    render(): React.ReactNode {
        if (!this.state.exportPlugin) {
            return <div className="alert alert-danger mt-3">{this.props.name}: Unable to load {this.props.url}</div>;
        }
        const exportPlugin = this.state.exportPlugin;
        exportPlugin.global = eeviGlobal;
        const Component = exportPlugin.plugin;
        if (this.debugPoint) {
            this.debugPoint();  // add this method to your plugin so you can step into it.
        }
        if (this.props.props) {
            const props = {...this.props.props, pluginUrl: this.props.url};
            return <Component {...props} />
        }
        return <Component/>;
    }
}
