import {EeviForm, EeviFormProperties, EeviFormState} from "./eevi_form";
import {EeviApi} from "../components/eevi_api";
import {apiV1} from "../containers/eevi_std_container";
import {eeviGlobal} from "../components/eevi_context";
import {React} from "../components/eevi_react_exports";

interface LoggedOutState extends EeviFormState {
    loggedOut: boolean;
}

export class EeviLogoutForm extends EeviForm<EeviFormProperties, LoggedOutState> {
    private readonly api: EeviApi<LoggedOutState>;

    constructor(props: any) {
        super(props, "One moment...", true);
        this.api = EeviApi.fromComponent(this);
        this.state = {
            ...this.initialState(),
            loading: true,
            loggedOut: false
        };
    }


    componentDidMount() {
        super.componentDidMount();
        eeviGlobal.logout();
        this.api.post(`${apiV1}/logout`, {});
    }


    renderForm(): React.ReactNode {
        if (this.state.loggedOut) {
            window.location.href = '/';
        }
        return <>
        </>;
    }

}
