export class EeviLog {
    private start?: number;
    private last?: number;

    renderEnabled = false;
    eventsEnabled = false;

    constructor(readonly enabled: boolean) {
    }

    info(msg: string) {
        if (!this.enabled) {
            return;
        }
        this.start = this.start || (new Date()).getTime();
        this.last = this.last || this.start;
        const now = new Date();
        const latest = now.getTime();
        const nowString = now.toLocaleTimeString([],{hour12: false});
        const sinceLast = `${latest - this.last}ms`.padStart(6);
        console.info(`${nowString} ${sinceLast} ${msg}`);
        this.last = latest;
    }

    event(msg: string, ...args: any) {
        if (this.eventsEnabled) {
            this.info(`--> event: ${msg}${args}`)
        }
    }

    render(msg: string, ...args: any) {
        if (this.renderEnabled) {
            this.info(`render: ${msg}${args}`)
        }
    }

}