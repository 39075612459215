import {Col, Container, React, Row} from "./eevi_react_exports";


export class EeviLogo extends React.Component {
    render() {
        return <Container>
            <Row>
                <Col>
                    <a title="eevi" href="https://www.eevi.life">
                        <img alt="eevi" className="img-fluid" style={{maxHeight: "3.6rem"}}
                             src="https://s3.ap-southeast-2.amazonaws.com/assets.eevi.life/common/images/EEVI+Logo.png"/>
                    </a>
                </Col>
            </Row>
        </Container>;
    }
}
