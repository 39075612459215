import axios, {AxiosRequestConfig, AxiosResponse} from 'axios';
import {EeviFunc, EeviTransform} from "./eevi_transform";
import {coalesce, getProperty, isEmptyObject, isNil} from "./eevi_util";
import {EeviStandardState} from "./eevi_standard_state";
import {eeviGlobal} from "./eevi_context";
import {Disposable, GarbageCollector, hasGarbageCollection} from "./eevi_disposable";
import {assert} from "./eevi_assert";
import {React} from "./eevi_react_exports";

type Update<T> = { (response: Partial<T>): void };


/**
 * Makes calls to the Eevi Rest Api, merging the returned value into the component state.
 */
export class EeviApi<TStandardState extends EeviStandardState> implements Disposable {

    private garbageCollector = new GarbageCollector();

    /**
     * The default callback merges the returned rest data with the parent form (component) state.
     * @param component
     * @param configFunction
     */
    static fromComponent<TStandardState extends EeviStandardState>(
        component: React.Component<any, TStandardState>,
        configFunction?: EeviFunc<AxiosRequestConfig | undefined>
    ): EeviApi<TStandardState> {
        const configFunc = configFunction || (() => eeviGlobal.headerConfig());
        const api = new EeviApi<TStandardState>(
            resp => component.setState((prev) => ({...prev, ...resp})),
            configFunc
        );
        if (hasGarbageCollection(component)) {
            api.garbageCollector = component.garbageCollector;
        }
        return api;
    }

    constructor(
        readonly setState: Update<TStandardState>,
        readonly configFunc?: EeviFunc<AxiosRequestConfig | undefined>) {
    }

    private getConfig(): AxiosRequestConfig | undefined {
        return this.configFunc ? this.configFunc() : eeviGlobal.headerConfig();
    }

    dispose(): void {
        this.garbageCollector.dispose();
    }

    get<T = TStandardState>(url: string,
                            dataProp?: keyof T,
                            callback?: Update<T>,
                            transform?: EeviTransform<any, Partial<T>>,
                            setLoadingState?: boolean,
                            errorCallback?: Update<any>
    ) {
        eeviGlobal.log(`api GET request: ${url}`);
        this.loading(setLoadingState);
        axios.get<T[keyof T]>(url, this.getConfig())
            .then(res => {
                eeviGlobal.log(`api GET response: ${url}`);
                eeviGlobal.setFromHeaders(res);
                const transformFunc = coalesce<EeviTransform<any, any>>(transform, obj => obj);
                let response: any;
                if (isNil(dataProp)) {
                    response = transformFunc(res.data);
                } else {
                    response = {[dataProp!]: transformFunc(res.data)};
                }
                // response.loading = false;
                this.callback(callback, response, setLoadingState);
            })
            .catch(error => {
                    eeviGlobal.log(`api error: ${url} ${error}`);
                    if (errorCallback) {
                        errorCallback(error)
                    } else {
                        this.setState({loading: false, error: error} as Partial<TStandardState>);
                    }
                }
            );
    }

    private loading(setLoadingState: boolean | undefined) {
        if (setLoadingState) {
            this.setState({loading: true, error: null} as Partial<TStandardState>);
        }
    }

    delete<T = TStandardState, TResult = T>(
        url: string,
        dataProp?: keyof T,
        callback?: Update<TResult>,
        setLoadingState?: boolean,
        errorCallback?: Update<any>
        ) {
        this.loading(setLoadingState);
        eeviGlobal.log(`api DELETE request: ${url}`);
        axios.delete(url, this.getConfig())
            .then(res => {
                eeviGlobal.log(`api DELETE response: ${url}`);
                this.onResponse(res, dataProp, callback, setLoadingState);
            })
            .catch(error => {
                eeviGlobal.log(`api error: ${url} ${error}`);
                if (errorCallback) {
                    errorCallback(error)
                } else {
                    this.setState({loading: false, error: error} as Partial<TStandardState>);
                }
            });
    }

    private callback<T>(callback: Update<T> | undefined, response: Partial<T>, setLoadingState?: boolean) {
        if (callback) {
            callback(response);
        } else {
            if (setLoadingState === false) {
                this.setState({...response, error: null} as unknown as Partial<TStandardState>);
            } else {
                this.setState({...response, error: null, loading: false} as unknown as Partial<TStandardState>);
            }
        }
    }

    post<T = TStandardState>(
        url: string,
        data: any,
        dataProp?: keyof T,
        callback?: Update<T>,
        setLoadingState?: boolean,
        errorCallback?: Update<any>
    ) {
        this.loading(setLoadingState);
        axios.post(url, data, this.getConfig())
            .then(res => this.onResponse(res, dataProp, callback, setLoadingState))
            .catch(error => {
                eeviGlobal.log(`api error: ${url} ${error}`);
                if (errorCallback) {
                    errorCallback(error)
                } else {
                    this.setState({loading: false, error: error} as Partial<TStandardState>);
                }
            })
    }

    private isConnectResponse(data: any): boolean {
        const {wsConnectionId, ...rest} = data;
        return wsConnectionId && isEmptyObject(rest);
    }

    private isError(data: any): boolean {
        const {error, ...rest} = data;
        return error && isEmptyObject(rest);
    }


    private getWebSocketAddress(): string {

        const address = getProperty<string | undefined>(
            window,
            'webSocketAddress',
            undefined
        );
        assert(address, "webSocketAddress property is missing. Use eevi_react_loader.react_index_view!!!");
        return address;
    }

    connectWebSocket<T = TStandardState>(
        dataProp?: keyof T,
        callback?: Update<T>,
        setLoadingState?: boolean): Promise<string> {
        return new Promise<string>(resolve => {
            this.loading(setLoadingState);
            const ws = this.garbageCollector.add(new WebSocket(this.getWebSocketAddress()));
            ws.onopen = ev => {
                // kick off so we get our own connectionId back
                ws.send(JSON.stringify({action: 'connect'}))
            };
            let wsConnectionId: string | undefined;
            ws.onmessage = ev => {
                const data = JSON.parse(ev.data);
                if (this.isConnectResponse(data)) {
                    // from our websockets lambda
                    wsConnectionId = data.wsConnectionId;
                    resolve(data.wsConnectionId!);
                } else if (this.isError(data)) {
                    // exception thrown during websocket response
                    this.setState({loading: false, error: data.error} as Partial<TStandardState>);
                } else {
                    // custom response from one of our applications
                    if (wsConnectionId) {
                        eeviGlobal.log(`api socket response: ${wsConnectionId}`);
                    }
                    let response: any;
                    if (isNil(dataProp)) {
                        response = data;
                    } else {
                        response = {[dataProp!]: data};
                    }
                    response.loading = false;
                    this.callback(callback, response, setLoadingState);
                }
            }
        });
    }

    private onResponse<T = TStandardState, TResult = T>(
        res: AxiosResponse<T>,
        dataProp?: keyof T,
        callback?: Update<TResult>,
        setLoadingState?: boolean) {
        eeviGlobal.setFromHeaders(res);
        if (res.status === 202) {
            //    Still in progress - probably a connectWebSocket response
            return;
        }
        let response: any;
        if (isNil(dataProp)) {
            response = res.data;
        } else {
            response = {[dataProp!]: res.data};
        }
        if (response === undefined || response === "") {
            response = undefined;
        }
        else {
            response.loading = false;
        }
        this.callback(callback, response, setLoadingState);

    }

    save<T = TStandardState>(
        key: string | number | undefined,
        url: string,
        parameters: string | undefined,
        data: any,
        dataProp?: keyof T,
        callback?: Update<T>,
        setLoadingState?: boolean,
        errorCallback?: Update<any>
    ) {
        this.loading(setLoadingState);
        if (key) {
            const urlFull = `${url}/${key}` + (parameters ? `?${parameters}` : '');
            this.put(urlFull, data, dataProp, callback, setLoadingState, errorCallback);
        } else {
            const urlFull = url + (parameters ? `?${parameters}` : '');
            this.post(urlFull, data, dataProp, callback, setLoadingState, errorCallback);
        }
    }

    put<T = TStandardState>(
        url: string,
        data: any,
        dataProp?: keyof T,
        callback?: Update<T>,
        setLoadingState?: boolean,
        errorCallback?: Update<any>
) {
        this.loading(setLoadingState);
        axios.put(url, data, this.getConfig())
            .then(res => this.onResponse(res, dataProp, callback, setLoadingState))
            .catch(error => {
                eeviGlobal.log(`api error: ${url} ${error}`);
                if (errorCallback) {
                    errorCallback(error)
                }
                this.setState({loading: false, error: error} as Partial<TStandardState>);
            })

    }

    getMultiple<T = TStandardState>(
        ...requests: { url: string, dataProp?: keyof T, transform?: EeviTransform<any, any> }[]) {
        this.loading(true);
        const promises = requests.map(
            request => new Promise<Partial<T | EeviStandardState>>(resolve => {
                this.get<T>(
                    request.url, request.dataProp, response => resolve(response), request.transform);
            }));
        Promise.all(promises).then(updates => {
            this.setState(Object.assign({loading: false, error: null}, ...updates));
        });

    }
}

