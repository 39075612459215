import {CSSProperties} from "react";

const bold: CSSProperties = {fontWeight: "bold"};

const italic: CSSProperties = {fontStyle: "italic"};

const underline: CSSProperties = {textDecoration: "underline"};

const boldItalic: CSSProperties = {...bold, ...italic};

const boldItalicUnderline: CSSProperties = {...bold, ...italic, ...underline};

const noPadding: CSSProperties = {padding: 0};

function Width(width: number): CSSProperties {
    return {
        width: width
    }
}

export {bold, italic, underline, boldItalic, boldItalicUnderline, noPadding, Width};