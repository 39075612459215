import {React} from "../../../../common/web_common/components/eevi_react_exports";
import {eeviGlobal} from "../../../../common/web_common/components/eevi_context";
import {getShowEmfitDemoData} from "./demo/service_status_sample_data";

enum IncidentSeverity {
    High = 1,
    Medium,
    Low,
    None = 5
}

// Key to the service name as returned by the API
const ServiceDataName = {
    aws: 'aws',
    villageCare: 'village_care',
    jupl: 'jupl',
    emfit: 'emfit',
    smartThings: 'smartthings',
}

// Key to the presentation name for a service
const ServicePresentationName = {
    aws: 'AWS',
    villageCare: 'eevi Care',
    jupl: 'JUPL VBN',
    emfit: 'Emfit',
    smartThings: 'SmartThings',
}

const GetIncidentSeverity = (incidents: ServiceStatusIncident[]): IncidentSeverity => {
    // Default no incidents is green
    // If all instances are on same day then amber.
    // If multi-day then red
    let severity: IncidentSeverity;

    const anyMultiDay = AnyMultiDay(incidents);
    if (anyMultiDay) {
        severity = IncidentSeverity.High;
    } else if (incidents.length === 1) {
        severity = IncidentSeverity.Medium;
    } else if (incidents.length > 1) {
        severity = IncidentSeverity.Low;
    } else {
        severity = IncidentSeverity.None;
    }

    return severity;
}

const GetIncidentItemColor = (incidents: ServiceStatusIncident[]): string => {
    let colour;
    switch (GetIncidentSeverity(incidents)) {
        case IncidentSeverity.High:
            colour = '#b90710';
            break;

        case IncidentSeverity.Medium:
            colour = '#e67e22';
            break;

        case IncidentSeverity.Low:
            colour = '#a8b21b';
            break;

        default:
            colour = '#469b2c';
            break;
    }

    return colour;
}

const AnyMultiDay = (incidents: ServiceStatusIncident[]): boolean => {
    let isMultiDay = false;
    for (const incident of incidents) {
        if (Boolean(incident.started) && Date.parse(incident.ended)) {
            const incidentStartDate: Date = new Date(incident.started);
            const incidentEndDate: Date = new Date(incident.ended);

            if (incidentStartDate.toLocaleDateString() !== incidentEndDate.toLocaleDateString()) {
                isMultiDay = true;
                break;
            }
        }
    }

    return isMultiDay;
}

const IsOperational = (incidents: ServiceStatusIncident[], endDate: Date) => {
    return incidents.filter(incident => {
        if (Boolean(incident.started) && Boolean(incident.ended)) {
            const incidentStartDate = new Date(incident.started);
            const incidentEndDate = new Date(incident.ended);
            return (endDate.getTime() >= incidentStartDate.getTime() && endDate.getTime() <= incidentEndDate.getTime());
        } else if (Boolean(incident.started) && !Boolean(incident.ended)) {
            const incidentStartDate = new Date(incident.started);
            return endDate.getTime() >= incidentStartDate.getTime();

        } else if (!Boolean(incident.started) && Boolean(incident.ended)) {
            const incidentEndDate = new Date(incident.ended);
            return endDate.getTime() <= incidentEndDate.getTime();
        }

        return 0;
    }).length > 0;
}

function AddDays(
    date: Date,
    days: number
) {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
}

type IncidentsOnDate = {
    renderDate: Date,
    incidentsOnDate: ServiceStatusIncident[],
};

// Tested so export.
export function GetIncidentsOnDateRange(
    debugServicePresentationName: string,
    incidents: ServiceStatusIncident[],
    renderStartDate: Date,
    daysDifference: number,
    debugMode: boolean = false
): IncidentsOnDate[] {

    if (debugMode) {
        console.log(`Processing ${incidents.length} incidents of type "${debugServicePresentationName}"...`);
    }

    let renderStatusDay = new Array(daysDifference);
    for (let dayIndex = 0; dayIndex < daysDifference; dayIndex++) {
        // Determine if current date is between the incident dates
        const currentDay = AddDays(renderStartDate, dayIndex);
        currentDay.setHours(0, 0, 0, 0);

        const incidentsOnDay = incidents.filter(incident => {
            let result: boolean = false;
            if (Boolean(incident.started) && Boolean(incident.ended)) {
                // Normalise start and end to date only then see if current date is between these two dates.
                const incidentStartDate = new Date(incident.started);
                incidentStartDate.setHours(0, 0, 0, 0);
                const incidentEndDate = new Date(incident.ended);
                incidentEndDate.setHours(0, 0, 0, 0);

                result = (currentDay.getTime() >= incidentStartDate.getTime() && currentDay.getTime() <= incidentEndDate.getTime());

            } else if (Boolean(incident.started) && !Boolean(incident.ended)) {
                const incidentStartDate = new Date(incident.started);
                incidentStartDate.setHours(0, 0, 0, 0);

                result = currentDay.getTime() >= incidentStartDate.getTime();

            } else {
                // Not expected!
                console.error(`Expecting ${debugServicePresentationName} on ${currentDay.toLocaleDateString()} to have a valid start Date!`)
            }

            if (result && debugMode) {
                console.log(
                    `${result}: ${incident.started ? incident.started.toLocaleString() : "<no start>"} - ${incident.ended ? incident.ended.toLocaleString() : "<no end>"} (${currentDay}) - ${debugServicePresentationName}`
                );
            }

            return result;
        });

        if (debugMode && incidentsOnDay.length > 0) {
            console.log(`${incidentsOnDay.length} incidents on ${currentDay}`);
        }

        renderStatusDay[dayIndex] = {
            'renderDate': currentDay,
            'incidentsOnDate': incidentsOnDay
        };
    }

    if (debugMode) {
        console.log(`Completed processing ${incidents.length} incidents of type "${debugServicePresentationName}"`);
    }

    return renderStatusDay;
}

export function collateIncidents(
    incidents: ServiceStatusIncident[],
    debugMode: boolean = false
): ServiceStatusIncident[] {
    // Our reporting engine can raise lots of short incidents very close together.
    // Rather than create a crowded view, we detect this pattern, and merge together close incidents
    // We mark collated incidents so we can show a suitable incident message.
    // Collation criteria are below.
    // We can collate any tpe of incident.

    // We can't be grouped unless we have 2 or more items
    if (incidents.length < 2) {
        return incidents;
    }

    if (debugMode) {
        console.log(`Collating ${incidents.length} incidents...`);
    }

    // If there is less than a 30 minutes gap between prev end and current start then we group.
    const maxGapSeconds = 30 * 60;

    // Filter to Emfit to start with, then sort by started date
    const emfitIncidents: ServiceStatusIncident[] = incidents.filter((incident) => incident.serviceName === 'emfit');
    const sortedEmfitIncidents = emfitIncidents.sort(
        (a, b) => Number(new Date(a.started)) - Number(new Date(b.started)),
    );

    // Loop
    const processedIncidents: ServiceStatusIncident[] = [];

    // Look ahead
    //let current: ServiceStatusIncident | null = null;
    for (let index: number = 0; index < sortedEmfitIncidents.length; index++) {
        const current = sortedEmfitIncidents[index];

        // If we are on the last item and not in a group then we can't be grouped!
        if ((index + 1) === sortedEmfitIncidents.length) {
            processedIncidents.push(current);
        } else {
            // Not on the last - look ahead to see if we are grouped
            const next: ServiceStatusIncident = sortedEmfitIncidents[index + 1];

            const secondsDifference: number = Math.abs(
                Number(new Date(next.started)) - Number(new Date(current.ended))
            ) / 1000;
            if (next.started && current.ended && secondsDifference < maxGapSeconds) {
                // At least this and next are grouped.  Determine where the final group end is
                if (debugMode) {
                    console.log(
                        `Starting group of ${current.serviceName} as ${secondsDifference} seconds gap between ${current.started} (${current.description}) and ${next.ended} (${next.description}) (from index ${index})...`);
                }

                const groupStart: ServiceStatusIncident = current;
                const groupStartIndex: number = index;

                let groupEnd: ServiceStatusIncident = current;
                let groupEndIndex: number = index;

                // Keep walking forward until we off end, or we have a larger gap
                for (let innerIndex: number = index; innerIndex < sortedEmfitIncidents.length; innerIndex++) {
                    const current = sortedEmfitIncidents[innerIndex];

                    if ((innerIndex + 1) === sortedEmfitIncidents.length) {
                        // Current is last - this must be the end group
                        groupEnd = current;
                        groupEndIndex = innerIndex;

                        if (debugMode) {
                            console.log(
                                `Breaking out of grouping at index ${innerIndex} as at this is the last incident`
                            );
                        }
                        break
                    } else {
                        // Current isn't the last so we have a next but is it the group end? (next is a larger gap than allowed!)
                        const next: ServiceStatusIncident = sortedEmfitIncidents[innerIndex + 1];

                        const secondsDifference = Math.abs(
                            Number(new Date(next.started)) - Number(new Date(current.ended))
                        ) / 1000;
                        if (next.started && current.ended && secondsDifference > maxGapSeconds) {
                            // Next is bigger gap, so current must be the group end
                            groupEnd = current;
                            groupEndIndex = innerIndex;

                            if (debugMode) {
                                console.log(
                                    `Breaking out of grouping at index ${innerIndex} as gap between ${next.started} ${current.description} and ${current.ended} (${current.description}) is too large at ${secondsDifference} seconds`
                                );
                            }
                            break;
                        }

                        // Otherwise current is part of group
                        if (debugMode) {
                            console.log(
                                `Add index ${innerIndex} to group as the gap between ${next.started} ${current.description} and ${current.ended} (${current.description}) is ${secondsDifference} seconds`
                            );
                        }
                    }
                }

                // Start defined and end defined
                if (debugMode) {
                    console.log(
                        `Grouping between index ${groupStartIndex} to ${groupEndIndex}.  Started: ${groupStart.started} (${groupStart.description} and ended: ${groupEnd.ended} (${groupEnd.description})`
                    );
                }

                // Create a new grouped incident and add to the result
                let description: string = "";
                for (let k = groupStartIndex; k <= groupEndIndex; k++) {
                    if (description.length > 0 && sortedEmfitIncidents[k].description) {
                        description += ' - ';
                    }

                    if (sortedEmfitIncidents[k].description) {
                        description += sortedEmfitIncidents[k].description;
                    }
                }

                processedIncidents.push(
                    {
                        serviceName: groupStart.serviceName,
                        started: groupStart.started,
                        ended: groupEnd.ended,
                        isCollated: true,
                        description: description
                    });

                index = groupEndIndex;  // Loop increments to the next item
            } else {
                // Too big a gap - must be ungrouped.
                processedIncidents.push(current);
            }
        }
    }

    if (debugMode) {
        console.log('---------------');
        processedIncidents.forEach(function (incident) {
            console.log(
                `Incident ${incident.started} to ${incident.ended} ${incident.isCollated ? "(grouped)" : ""} (${incident.description})`
            );
        });
        console.log('---------------');
    }

    if (debugMode) {
        console.log(`Completed collating ${incidents.length} incidents`);
    }

    return processedIncidents;
};


export interface ServiceStatusIncident {
    serviceName: string;
    started: string;
    ended: string;
    isCollated?: boolean,
    description: string;        // Only in demo data!
}

type IncidentGraphItemType = {
    itemIndex: number,
    renderDate: Date,
    incidentsOnDate: ServiceStatusIncident[],
    width: number,
    height: number,
    locale: string
};

const IncidentGraphItem: React.FC<IncidentGraphItemType> = ({
                                                                itemIndex,
                                                                renderDate,
                                                                incidentsOnDate,
                                                                width,
                                                                height,
                                                                locale,
                                                            }: IncidentGraphItemType) => {

    const fillColor: string = GetIncidentItemColor(incidentsOnDate);
    const dateTitleOptions: Intl.DateTimeFormatOptions = {year: 'numeric', month: 'long', day: 'numeric'};

    const title: string = incidentsOnDate.length == 0 ?
        `No incidents on ${renderDate.toLocaleDateString(locale, dateTitleOptions)}`
        :
        (incidentsOnDate.length > 1 ?
            `${incidentsOnDate.length} incidents on ${renderDate.toLocaleDateString(locale, dateTitleOptions)}` :
            `${incidentsOnDate.length} incident on ${renderDate.toLocaleDateString(locale, dateTitleOptions)}`)
    ;

    return (
        <rect
            height={height} width="3" x={itemIndex * 5} y="0" fill={`${fillColor}`}
            className={`uptime-day day-${itemIndex}`}
            data-html="true">
            <title>{title}</title>
        </rect>
    );
}

type RenderedIncidentsType = {
    servicePresentationName: string,
    incidentsOfType: ServiceStatusIncident[],
    renderStartDate: Date,
    renderEndDate: Date,
    daysDifference: number
};

const IncidentGraphList: React.FC<RenderedIncidentsType> = ({
                                                                servicePresentationName,
                                                                incidentsOfType,
                                                                renderStartDate,
                                                                renderEndDate,
                                                                daysDifference
                                                            }: RenderedIncidentsType) => {
    /*
    console.log(
        `Rendering ${incidentsOfType.length} incident/s for ${serviceName}`
    );
     */

    const renderStatusDay = GetIncidentsOnDateRange(
        servicePresentationName, incidentsOfType, renderStartDate, daysDifference
    );

    // Calculate if we are currently operational, or we have an incident
    let componentStatusLabel: string = "Operational";
    let componentStatusClass: string = "status-ok";

    if (IsOperational(incidentsOfType, renderEndDate)) {
        componentStatusLabel = "Incident";
        componentStatusClass = "status-incident";
    }

    // Determine the incident time for the render period
    let unavailableMins: number = 0;
    for (const incident of incidentsOfType) {
        let incidentStart: Date = renderStartDate;
        if (Boolean(incident.started) && Date.parse(incident.started) > renderStartDate.getTime()) {
            incidentStart = new Date(incident.started);
        }
        let incidentEnd: Date = renderEndDate;
        if (Boolean(incident.ended) && Date.parse(incident.ended) < renderEndDate.getTime()) {
            incidentEnd = new Date(incident.ended);
        }
        const incidentDurationMins: number = Math.floor((incidentEnd.getTime() - incidentStart.getTime()) / (1000 * 60));
        unavailableMins += incidentDurationMins;

        //console.log(`Description: "${incident.description}" unavailable ${incidentDurationMins} mins (${new Date(incidentStart).toLocaleString()} - ${new Date(incidentEnd).toLocaleString()})`);
    }

    const periodMins = Math.floor((renderEndDate.getTime() - renderStartDate.getTime()) / (1000 * 60));

    let uptimePercentage: number = 100.0;
    if (unavailableMins > 0) {
        uptimePercentage = (1 - (unavailableMins / periodMins)) * 100.0;
    }

    //console.log(`"${serviceName}" uptime ${uptimePercentage}% for render period ${periodMins}mins and unavailable for ${unavailableMins}mins`);
    const options = Intl.DateTimeFormat().resolvedOptions();
    const locale = options.locale;
    const graphWidth: number = 448;
    const graphHeight: number = 34;

    return (
        <div className="service-status-graph-container">
            <div className="service-status-graph-container-inner">
                    <span className="service-name">
                    {servicePresentationName} Status
                    </span>
                <span className={`component-status ${componentStatusClass}`}>
                    {componentStatusLabel}
                    </span>
                <svg className="availability-time-line-graphic" preserveAspectRatio="none" height={`${graphHeight}`}
                     viewBox={`0 0 ${graphWidth} ${graphHeight}`}>
                    {
                        renderStatusDay.map((props, itemIndex) => (
                            <IncidentGraphItem
                                key={`incident-graph-item-${itemIndex}`}
                                itemIndex={itemIndex}
                                renderDate={props.renderDate}
                                incidentsOnDate={props.incidentsOnDate}
                                width={graphWidth}
                                height={graphHeight}
                                locale={locale}
                            />
                        ))
                    }
                </svg>

                <div className="legend">
                    <div className="legend-item">
                        <span className="legend-item-day">{daysDifference}</span> days ago
                    </div>
                    <div className="spacer"></div>
                    <div className="legend-item">
                        <span className="legend-item-uptime-percent">{uptimePercentage.toFixed(2)} % uptime</span>
                    </div>
                    <div className="spacer"></div>
                    <div className="legend-item">
                        <span className="legend-item-daye-label">Today</span>
                    </div>
                </div>
            </div>
        </div>
    );
}

interface IncidentItemProps {
    incident: ServiceStatusIncident,
    locale: string,
}

const IncidentItem: React.FC<IncidentItemProps> = ({
                                                       incident,
                                                       locale
                                                   }: IncidentItemProps) => {

    // todo: A problem is that we list all incidents seperately, rather than grouping per day or type.
    //  This is ok for the low number of incidents, but not great if we have lots of short incidents.
    const serviceName: string = incident.serviceName;
    const stringStarted: string = incident.started;
    const stringEnded: string = incident.ended;

    // Determine the service presentation name from the data name
    let finalServiceName: string = serviceName;
    let property: keyof typeof ServiceDataName;
    for (property in ServiceDataName) {
        if (ServiceDataName[property] === serviceName) {
            finalServiceName = ServicePresentationName[property];
        }
    }

    const dateTitleOptions: Intl.DateTimeFormatOptions = {year: 'numeric', month: 'long', day: 'numeric'};
    const dateShortOptions: Intl.DateTimeFormatOptions = {year: 'numeric', month: 'long', day: 'numeric'};
    const timeOptions: Intl.DateTimeFormatOptions = {hour: "numeric", minute: "numeric", hour12: true};

    const dateIncidentStart: Date = new Date(stringStarted);

    const severity: IncidentSeverity = GetIncidentSeverity([incident]);
    const markerClassName: string = (`incident-severity-marker incident-severity-${IncidentSeverity[severity].toLowerCase()}`)
    const colour: string = GetIncidentItemColor([incident]);

    if (Boolean(stringStarted) && Boolean(stringEnded)) {
        // We have a start and end Date.  If these are different, then render as two dates, otherwise a single date
        const dateIncidentEnd: Date = new Date(stringEnded);

        if (dateIncidentStart.toLocaleDateString() === dateIncidentEnd.toLocaleDateString()) {
            // Same day - only show times (timeOptions)
            return (
                <li className="incident-item incident-single-day">
                    <p className="incident-date-label">
                        <span className={`${markerClassName}`} style={{color: `${colour}`}}>&#8226;</span>
                        {dateIncidentStart.toLocaleDateString(locale, dateTitleOptions)}
                    </p>
                    <p className="incident-service-name">
                        {incident.isCollated ?
                            `${finalServiceName} intermittent service incident.`
                            :
                            `${finalServiceName} service incident.`
                        }
                    </p>
                    <p className="incident-summary">
                        Between {dateIncidentStart.toLocaleTimeString(locale, timeOptions)} and {dateIncidentEnd.toLocaleTimeString(locale, timeOptions)}.
                    </p>
                </li>
            );
        } else {
            // Spans different days
            return (
                <li className="incident-item">
                    <p className="incident-date-label">
                        <span className={`${markerClassName}`} style={{color: `${colour}`}}>&#8226;</span>
                        {dateIncidentStart.toLocaleDateString(locale, dateTitleOptions)} - {dateIncidentEnd.toLocaleDateString(locale, dateTitleOptions)}
                    </p>
                    <p className="incident-service-name">
                        {incident.isCollated ?
                            `${finalServiceName} intermittent service incident.`
                            :
                            `${finalServiceName} service incident.`
                        }
                    </p>
                    <p className="incident-summary">
                        From {dateIncidentStart.toLocaleDateString(locale, dateShortOptions)} at {dateIncidentStart.toLocaleTimeString(locale, timeOptions)} to {dateIncidentEnd.toLocaleDateString(locale, dateShortOptions)} at {dateIncidentEnd.toLocaleTimeString(locale, timeOptions)}.
                    </p>
                </li>
            );
        }
    } else {
        // We just have a start Date (e.g. it's ongoing)
        return (
            <li className="incident-item">
                <p className="incident-date-label">
                    <span className={`${markerClassName}`} style={{color: `${colour}`}}>&#8226;</span>
                    {dateIncidentStart.toLocaleDateString(locale, dateTitleOptions)}
                </p>
                <p className="incident-service-name">
                    {finalServiceName} service incident.
                </p>
                <p className="incident-summary">
                    Started at {dateIncidentStart.toLocaleTimeString(locale, timeOptions)} and is ongoing.</p>
            </li>
        );
    }
}

interface IncidentListProps {
    allIncidents: ServiceStatusIncident[],
    showEmfit: boolean
}

const IncidentList: React.FC<IncidentListProps> = ({
                                                       allIncidents,
                                                       showEmfit
                                                   }: IncidentListProps) => {

    const options = Intl.DateTimeFormat().resolvedOptions();
    const locale: string = options.locale;
    const timezone: string = options.timeZone;

    // Determine if any incidents are currently occurring.
    let allCurrentIncidents: ServiceStatusIncident[] = [];
    let allPastIncidents: ServiceStatusIncident[] = [];

    for (const incident of allIncidents) {
        if (!showEmfit && incident.serviceName === ServiceDataName.emfit) {
            continue;
        }

        if (!Boolean(incident.ended)) {
            allCurrentIncidents.push(incident);
        } else {
            allPastIncidents.push(incident);
        }
    }

    return (
        <div className="service-status-incident-container">
            {allCurrentIncidents.length > 0
                ?
                <div className="current-incident-container">
                    <h1>{allCurrentIncidents.length > 1 ? "Current Incidents" : "Current Incident"}</h1>
                    <ul className="incident-list">
                        {allCurrentIncidents.map((props, itemIndex) => (
                            <IncidentItem
                                key={`current-incident-item-${itemIndex}`}
                                incident={props}
                                locale={locale}
                            />
                        ))}
                    </ul>
                </div>
                : ''
            }

            <div className="past-incident-container">
                <h1>{allPastIncidents.length > 1 ? "Past Incidents" : "Past Incident"}</h1>
                {allPastIncidents.length > 0 ?
                    <ul className="incident-list">
                        {allPastIncidents.map((props, itemIndex) => (
                            <IncidentItem
                                key={`past-incident-item-${itemIndex}`}
                                incident={props}
                                locale={locale}
                            />
                        ))}
                    </ul>
                    :
                    <p className="no-incidents-label">No incidents</p>
                }
            </div>

            {allPastIncidents.length > 0 ?
                <p className="timezone-info">All times in '{timezone}' timezone</p> : ''
            }
            <p className="last-update-label">Last updated {new Date().toLocaleString()}</p>
        </div>
    );
}

const showEmfitServiceStatus = (): boolean => {
    const parameters = new URLSearchParams(window.location.search);
    const demoMode: number | undefined = parseInt(parameters.get("demo") || "") || undefined;
    let enableDemo: boolean = false;
    if (demoMode) {
        enableDemo = getShowEmfitDemoData(demoMode);
    }

    // We use the read_permissions as a sort of user default - e.g. a permission
    // indicates we must do something.
    //
    // If we have demo mode enabled, then the demo data controls if we show Emfit data.
    // This is not a security issue because we use hard coded data for demo mode.
    // The only way to see real data is to have the permission.

    // It appears that read_permissions is an Array on first read then it's a Set.
    // Detect this and call the correct method to determine inclusion.
    const permissionName: string = "system_health_show_emfit_status";
    let hasReadPermission: boolean = false;
    const permissions = eeviGlobal.loggedInUser.read_permissions;
    if (Array.isArray(permissions)) {
        if (permissions.includes(permissionName)) {
            hasReadPermission = true;
        }
    } else {
        hasReadPermission = permissions.has(permissionName);
    }

    return (hasReadPermission || enableDemo);
}

export function ServiceStatus(
    props: {
        data: ServiceStatusIncident[],
        renderStartDate: Date,
        renderEndDate: Date,
        daysDifference: number
    }
): JSX.Element {
    // Show loading state while waiting for the data
    if (!props.data) {
        let operationStatusLabel = "Loading...";
        let operationStatusClass = "status-loading";

        return (
            <div className="service-status-main-container">
                <div className="service-status-main-container-inner">
                    <div className={`page-status-container ${operationStatusClass}`}>
                        <h1 className="label">
                            {operationStatusLabel}
                        </h1>
                    </div>
                </div>
            </div>
        );
    }

    // Note the key names here must match the JSON serviceName field values!
    const rawIncidents: ServiceStatusIncident[] = props.data;

    const awsIncidents: ServiceStatusIncident[] = collateIncidents(
        rawIncidents.filter(incident => incident.serviceName === ServiceDataName.aws)
    );
    const careIncidents: ServiceStatusIncident[] = collateIncidents(
        rawIncidents.filter(incident => incident.serviceName === ServiceDataName.villageCare)
    );
    const vbnIncidents: ServiceStatusIncident[] = collateIncidents(
        rawIncidents.filter(incident => incident.serviceName === ServiceDataName.jupl)
    );
    const emfitIncidents: ServiceStatusIncident[] = collateIncidents(
        rawIncidents.filter(incident => incident.serviceName === ServiceDataName.emfit)
    );
    const stIncidents: ServiceStatusIncident[] = collateIncidents(
        rawIncidents.filter(incident => incident.serviceName === ServiceDataName.smartThings)
    );

    let groupedIncidents: ServiceStatusIncident[] = [];
    groupedIncidents = groupedIncidents.concat(awsIncidents, careIncidents, vbnIncidents, emfitIncidents, stIncidents);

    const showEmfit: boolean = showEmfitServiceStatus();

    console.log(
        `Total of ${groupedIncidents.length} (grouped) incidents between ${props.renderStartDate.toLocaleString()} and ${props.renderEndDate.toLocaleString()}.  aws: ${awsIncidents.length} eevicare: ${careIncidents.length} vbn: ${vbnIncidents.length} emfit: ${emfitIncidents.length} SmartThings: ${stIncidents.length}.  ${showEmfit ? 'Showing Emfit data.' : 'Emfit data is not shown.'}`
    );

    let operationStatusLabel: string = "All Systems Operational";
    let operationStatusClass: string = "status-ok";
    if (IsOperational(groupedIncidents, props.renderEndDate)) {
        operationStatusLabel = "Ongoing incident";
        operationStatusClass = "status-incident";
    }

    return (
        <div className="service-status-main-container">
            <div className="service-status-main-container-inner">
                <div className={`page-status-container ${operationStatusClass}`}>
                    <h1 className="label">
                        {operationStatusLabel}
                    </h1>
                </div>
                <div className="component-status-container">
                    <h1 className="uptime-label">
                        Uptime over the past {props.daysDifference} days
                    </h1>
                    <IncidentGraphList
                        servicePresentationName={ServicePresentationName.aws}
                        incidentsOfType={awsIncidents}
                        renderStartDate={props.renderStartDate}
                        renderEndDate={props.renderEndDate}
                        daysDifference={props.daysDifference}
                    />
                    <IncidentGraphList
                        servicePresentationName={ServicePresentationName.villageCare}
                        incidentsOfType={careIncidents}
                        renderStartDate={props.renderStartDate}
                        renderEndDate={props.renderEndDate}
                        daysDifference={props.daysDifference}
                    />
                    <IncidentGraphList
                        servicePresentationName={ServicePresentationName.jupl}
                        incidentsOfType={vbnIncidents}
                        renderStartDate={props.renderStartDate}
                        renderEndDate={props.renderEndDate}
                        daysDifference={props.daysDifference}
                    />
                    {showEmfit ?
                        <IncidentGraphList
                            servicePresentationName={ServicePresentationName.emfit}
                            incidentsOfType={emfitIncidents}
                            renderStartDate={props.renderStartDate}
                            renderEndDate={props.renderEndDate}
                            daysDifference={props.daysDifference}
                        />
                        : ""
                    }
                    <IncidentGraphList
                        servicePresentationName={ServicePresentationName.smartThings}
                        incidentsOfType={stIncidents}
                        renderStartDate={props.renderStartDate}
                        renderEndDate={props.renderEndDate}
                        daysDifference={props.daysDifference}
                    />
                </div>
                <IncidentList allIncidents={groupedIncidents} showEmfit={showEmfit}/>
            </div>
        </div>
    );
}
