import {EeviAction} from "./eevi_transform";

export interface Disposable {
    dispose(): void;
}

interface Closeable {
    close(): void;
}

function isDisposable(item: any): item is Disposable {
    return (item as Disposable).dispose !== undefined;
}

class DisposableCloseable implements Disposable {
    constructor(readonly closeable: Closeable) {
    }

    dispose() {
        this.closeable.close();
    }

}

export function disposable(item: Disposable | Closeable): Disposable {
    if (isDisposable(item)) {
        return item;
    } else {
        return new DisposableCloseable(item);
    }
}

export function withDispose<T extends Disposable | Closeable>(item: T, do_action: EeviAction<T>): void {
    const disposable_item = disposable(item);
    try {
        do_action(item);
    } finally {
        disposable_item.dispose();
    }
}

export class GarbageCollector implements Disposable {
    private items: Disposable[] = [];

    add<T extends Disposable | Closeable>(item: T): T {
        this.items.push(disposable(item));
        return item;
    }

    dispose() {
        const itemsToDispose = this.items;
        this.items = [];
        for (const item of itemsToDispose) {
            try {
                item.dispose();
            } catch (e) {
                // do nothing
            }
        }
    }
}

interface HasGarbageCollector {
    garbageCollector: GarbageCollector;
}

export function hasGarbageCollection(item: any): item is HasGarbageCollector {
    return (item as HasGarbageCollector).garbageCollector !== undefined;
}