import "react-datepicker/dist/react-datepicker.css"
import {EeviEventHandler} from "./eevi_event_handler";
import {coalesce} from "./eevi_util";
import {React, Dropdown, DropdownItem, DropdownMenu, DropdownToggle} from "./eevi_react_exports";

export interface EeviDropDownMenuItem {
    label: React.ReactNode | string;
    onClick: EeviEventHandler;
    key: string;
    isBold?: boolean;
    isIndented?: boolean;
    addDividerBefore?: boolean;
    addDividerAfter?: boolean;
    menuItemClass?:string;
}

interface DropDownProps {
    children?: React.ReactNode;
    menuButtonClass: string;
    noDefaultButtonStyling?: boolean;
    menuButtonContent?: React.ReactNode | string;
    fontIconClass?: string;
    noBorder?: boolean;
    menuItems?: EeviDropDownMenuItem[];
    className?: string;
    disabled?: boolean;
}

interface DropDownState {
    dropDownOpen: boolean;
}

export class EeviDropDown extends React.Component<DropDownProps, DropDownState> {
    constructor(props: DropDownProps) {
        super(props);
        this.state = {dropDownOpen: false};
    }

    toggle() {
        this.setState({dropDownOpen: !this.state.dropDownOpen});
    }

    close() {
        this.setState({dropDownOpen: false});
    }

    onClick(menuItem: EeviDropDownMenuItem) {
        this.close();
        menuItem.onClick();
    }

    getClass(menuItem: EeviDropDownMenuItem): string | undefined {
        let className: string | undefined = menuItem.menuItemClass;
        if (menuItem.isBold) {
            className = coalesce<string>(className, "") +"font-weight-bold";
        }
        if (menuItem.isIndented) {
            className = coalesce<string>(className, "") + " ml-3";
        }
        return className;
    }


    render() {
        return <Dropdown
            disabled={this.props.disabled}
            className={this.props.className || "ml-md-3 ml-sm-2 mr-md-3 mr-sm-2"}
            isOpen={this.state.dropDownOpen}
            toggle={() => this.toggle()}>
            <DropdownToggle
                disabled={this.props.disabled}
                className={this.props.menuButtonClass + (this.props.noDefaultButtonStyling ? "" : " eevi_dropdown")}>
                {this.props.fontIconClass && <i className={this.props.fontIconClass}/>}
                {this.props.menuButtonContent && this.props.menuButtonContent}
            </DropdownToggle>
            <DropdownMenu
                className={this.props.noBorder ? "eevi_dropdown_no_border" : undefined} right>
                {this.props.children && this.props.children}
                {
                    this.props.menuItems && this.props.menuItems.map(menuItem =>
                        <div key={`divedmni:${menuItem.key}`}>
                            {menuItem.addDividerBefore && <DropdownItem divider />}
                            <DropdownItem
                                onClick={() => this.onClick(menuItem)}
                                className={this.getClass(menuItem)}
                                toggle={false}
                                key={`edmni:${menuItem.key}`}>
                                {menuItem.label}
                            </DropdownItem>
                            {menuItem.addDividerAfter && <DropdownItem divider />}
                        </div>
                    )
                }
            </DropdownMenu>
        </Dropdown>;
    }
}

interface DropDownItemProps {
    children?: JSX.Element | string;
    className?: string;
    fontIconClass?: string;
    key: string;
    onClick: EeviEventHandler<string>;
}


export function EeviDropDownItem(props: DropDownItemProps) {
    return <a className={props.className}
              onClick={() => props.onClick(props.key)}>
        {props.fontIconClass && <i className={props.fontIconClass}/>}
        {props.children && props.children}
    </a>;
}