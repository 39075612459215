import {EeviLookup, EeviLookupItem} from "./eevi_lookup";
import {eeviId} from "./eevi_util";
import {React, Select, CSSProperties} from "./eevi_react_exports";


interface EeviDropdownProperties {
    lookupValue?: string;
    onChange: { (value?: EeviLookupItem): void }
    lookupItems?: Array<EeviLookupItem>
    placeholder?: string
    // multiple?: boolean;
    className?: string;
    innerRef?: React.Ref<HTMLInputElement>;
    id?: string;
    disabled?: boolean;
    style?: CSSProperties
}

export function EeviSelect(props: EeviDropdownProperties) {
    const id = props.id || eeviId();
    const customStyles = {
        control: (base: any) => (
            {
                ...base,
                borderColor: '#efcf71 !important',
                outline: 'none !important',
                boxShadow: '0 !important',
                '&:hover': {borderColor: '#efcf71 !important'}
            }
        ),
        option: (base: any, state: any) => (
            {
                ...base,
                color: '#3e444f',
                backgroundColor: state.isFocused ? '#feefcd' : 'white',
                fontWeight: state.isSelected ? 'bold' : 'normal',
            }
        ),
        ...(props.style || {})
    };

    return <Select
        id={id}
        name={id}
        key={id}
        styles={customStyles}
        value={EeviLookup.forValue(props.lookupValue, props.lookupItems)}
        className={(props.className || '') + ' eevi_select'}
        placeholder={props.placeholder}
        // menuIsOpen={true} // use this to look at styles in debugger
        isDisabled={props.disabled}
        options={props.lookupItems}
        onChange={e => props.onChange(e as EeviLookupItem)}/>;
}
