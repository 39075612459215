import {Input, InputProps, Label} from "reactstrap";
import {EeviEventHandler} from "./eevi_event_handler";
import {React} from "./eevi_react_exports";


interface CustomProperties {
    checkLabel: string
    sameAsValue?: string
    onValueChange: EeviEventHandler<string>
}

type OptionalInputProperties = CustomProperties & InputProps;


export class EeviOptionalInput extends React.Component<OptionalInputProperties, { useSameValue: boolean }> {
    constructor(props: OptionalInputProperties) {
        super(props);
        this.state = {
            useSameValue: props.value === props.sameAsValue || props.value === undefined
        };
    }

    onChangeCheckbox(e: React.ChangeEvent<HTMLInputElement>) {
        this.setState({useSameValue: e.target.checked});
        const props = this.props;
        const value = e.target.checked ? props.sameAsValue : '';
        props.onValueChange(value || '');
    }


    componentDidUpdate(prevProps: Readonly<OptionalInputProperties>,
                       prevState: Readonly<{ useSameValue: boolean }>, snapshot?: any): void {
        if (this.state.useSameValue && prevProps.sameAsValue !== this.props.sameAsValue) {
            // the other input changed and we are using the same value
            this.props.onValueChange(this.props.sameAsValue || '');
        }
    }

    render() {
        const {checkLabel, sameAsValue, onValueChange, ...inputProps} = this.props;
        return <>
            <Label className="eevi_text ml-4">
                <Input
                    className="eevi_check_box"
                    type="checkbox"
                    checked={this.state.useSameValue}
                    onChange={(e) => this.onChangeCheckbox(e)}/>
                {' '}
                {this.props.checkLabel}
            </Label>
            <Input
                {...inputProps}
                className="eevi_edit_text"
                hidden={this.state.useSameValue}
                onChange={(e) => this.props.onValueChange(e.target.value)}
            />
        </>;
    }

}

