import {EeviForm, EeviFormState} from "../../../../common/web_common/forms/eevi_form";
import {React, withRouter} from "../../../../common/web_common/components/eevi_react_exports";
import {apiV1, EeviBaseContainer, MenuLink} from "../../../../common/web_common/containers/eevi_std_container";
import {EeviApi} from "../../../../common/web_common/components/eevi_api";
import {eeviId} from "../../../../common/web_common/components/eevi_util";
import {LoginCredentials} from "../../../../common/web_common/components/eevi_data";
import {ServiceStatusIncident, ServiceStatus} from "../components/service_status_components";
import {
    getServiceEventDemoDates,
    getServiceEventDemoData,
    RenderDates
} from "../components/demo/service_status_sample_data";
import {eeviGlobal} from "../../../../common/web_common/components/eevi_context";

// Update page data every 30 seconds
const REFRESH_RATE_MS = 30000;

class ServiceStatusFormState implements EeviFormState {
    // EeviFormState
    windowHeight: number = 0;
    windowWidth: number = 0;
    nextToken?: string;

    // Fetch status
    loading?: boolean = true;
    error: any = undefined;

    currentDateTime: Date = new Date();
    lastUpdate: Date = new Date();

    // Query control
    startDate: Date = new Date();
    endDate: Date = new Date();
    requestId?: string;

    // Results
    incidents: ServiceStatusIncident[];
    status: string;

    constructor(incidents: ServiceStatusIncident[]) {
        this.incidents = incidents;
        this.status = "";
    }
}


class ServiceStatusForm extends EeviForm<any, ServiceStatusFormState> {
    private readonly api: EeviApi<ServiceStatusFormState>;
    private pageVisible = false;  // is this page visible?
    private _timers = new Map();  // polling data periodically
    private requestId = '';  // pagination
    private renderDates: RenderDates;

    // Visibility Handler stops api invocation if the page is not visible
    private _visibilityHandler: VoidFunction | undefined;

    // If set to a valid number, number defines the sample data set to show
    private demoMode: number | undefined = undefined;

    constructor(props: any) {
        super(props, "Health Status", false, true);
        this.api = EeviApi.fromComponent<ServiceStatusFormState>(this);
        this.state = new ServiceStatusFormState([]);
        this.render = this.render.bind(this);

        this.renderDates = this.getRenderDates();
    }

    render(): React.ReactNode {
        return <EeviBaseContainer
            title={"ServiceStatus"}
            formState={this.state}
            login={(c: LoginCredentials) => super.login(c)}
            addLoginRegisterButton={false}>
            {this.renderForm()}
        </EeviBaseContainer>;
    }

    protected menuLinks(): MenuLink[] {
        return [];
    }

    //region Form Mounting
    protected onFormDidMount() {
        let parameters = new URLSearchParams(window.location.search)
        this.demoMode = parseInt(parameters.get("demo") || "") || undefined

        this.initialize();
    }

    //endregion

    protected onFormWillUnmount() {
        this.removeVisibilityHandler()
        this.pageVisible = false;
        this._timers.forEach(clearInterval);
        this.clear_timers();
    }

    protected renderForm(): React.ReactNode {
        let content: JSX.Element;

        // Render
        if (this.state.error) {
            // todo: hook up error state here
            content = (
                <div className="text-container">{
                    'The Event Status Page is down for maintenance.\n' +
                    'For more information please contact support@eevi.life or 1300 802 738.'
                }</div>);
        } else {
            content = (
                <ServiceStatus
                    data={this.state.incidents}
                    renderStartDate={this.renderDates.start}
                    renderEndDate={this.renderDates.end}
                    daysDifference={this.renderDates.days}
                />
            );
        }

        return (
            <div className="ServiceStatusPage">
                {content}
            </div>);
    }

    private getRenderDates(): RenderDates {
        let renderDates: RenderDates | null = null;
        if (this.demoMode) {
            renderDates = getServiceEventDemoDates(this.demoMode);
        }

        // We render 90 days of history from today
        if (renderDates == null) {
            const daysDifference: number = 90;

            const renderEndDate: Date = new Date();
            renderEndDate.setHours(23, 59, 59, 0);

            const renderStartDate: Date = new Date(renderEndDate);

            // Add one as we want to render today also.
            renderStartDate.setDate(renderStartDate.getDate() - daysDifference + 1);
            renderStartDate.setHours(0, 0, 0, 0);

            renderDates = {
                start: renderStartDate,
                end: renderEndDate,
                days: daysDifference
            }
        }

        return renderDates;
    }

    //endregion

    //region Timers
    private register_timer(name: string, callback: CallableFunction, ms: number = 1000) {
        // Single call point for all timers
        this._timers.set(
            name,
            setInterval(
                () => {
                    if (this.pageVisible) {
                        callback()
                    }
                }, ms
            )
        )
    }

    private clear_timers() {
        this._timers.forEach(
            (value, key) => {
                clearInterval(value)
                this._timers.delete(key)
                console.log(`${key} was removed from timer list`)
            }
        )
    }

    //endregion

    //region Visibility Handler
    private addVisibilityHandler() {
        if (typeof document.hidden !== "undefined") {
            if (!this._visibilityHandler) {
                this._visibilityHandler = () => {
                    this.pageVisible = !document.hidden
                }
                document.addEventListener("visibilitychange", this._visibilityHandler)
            }
        } else {
            console.warn("Current browser does not support Page Visibility")
        }
    }

    private removeVisibilityHandler() {
        if (this._visibilityHandler) {
            document.removeEventListener("visibilitychange", this._visibilityHandler)
            this._visibilityHandler = undefined
        }
    }

    private getAPIQueryParameters(): string {
        this.requestId = eeviId()
        const endDate = this.renderDates.end
        const startDate = this.renderDates.start;

        return `endDate=${encodeURIComponent(endDate.toISOString())}` +
            `&startDate=${encodeURIComponent(startDate.toISOString())}` +
            '&severity=critical' +
            `&requestId=${this.requestId}`
            ;
    }

    private loadData(showLoadingScreen: boolean) {
        //console.log("Loading data...")

        const parameters: string = this.getAPIQueryParameters();
        const url: string = `${apiV1}/service_status?${parameters}`;

        this.api.get<Partial<ServiceStatusIncident[]>>(
            url,
            undefined,
            (data) => {
                //console.log("Loaded data")
                if (this.demoMode) {
                    // demo value of 100 shows the error page
                    if (this.demoMode == 100) {
                        throw new Error("App forced error as demo is '100'!");
                    }

                    // Otherwise display demo data
                    const demoData: ServiceStatusIncident[] = getServiceEventDemoData(this.demoMode)
                    this.setState({
                        loading: false,
                        error: null,
                        incidents: demoData,
                    })
                } else if (data != undefined) {
                    // We have real data to display
                    this.setState({
                        ...this.state,
                        loading: false,
                        error: null,
                        incidents: (data as ServiceStatusIncident[])
                    })
                } else {
                    // No data
                    throw new Error("No data returned from call");
                }
            },
            undefined,
            showLoadingScreen,
            (error) => {
                this.errorHandler(error)
            }
        )
    }

    private errorHandler(error: Partial<any>) {
        // ATM, the way an api error propagates is:
        // -> The default action is EeviApi method will set the current form's state {error:any},
        // -> There is any option of providing an errorCallback function in EeviApi to handle the error
        // -> If not suppressed, the EeviError tag will render this as a generic global error

        if (error.response && (error.response.status === 503 || error.response.status === 429)) {
            // Warning: 503 error means different things in different pages
            // in this page, it is usually mean "Service Unavailable"
            // -> nothing to handle here, simply keep polling
            console.log(`API Error: ${error.message}`)
            error.response.data.errors[0].handled = true
        }
        this.setState({...this.state, loading: false, error: error});
    }

    private initialize() {
        this.pageVisible = true;
        this.addVisibilityHandler()

        // Kick off the load
        this.loadData(true)

        // Setting up a polling timer to refresh the UI every 30 seconds
        this.register_timer(
            "service_status",
            () => {
                this.loadData(false)
            },
            REFRESH_RATE_MS
        )
    }
}

export default withRouter(ServiceStatusForm);

export const showServiceHealthLink = (): boolean => {
    // We use the read_permissions as a sort of user default - e.g. a permission
    // indicates we must do something.
    //
    // If we have demo mode enabled, then we always show carer availability.
    // This is not a security issue because we use hard coded data for demo mode.
    // The only way to see real data is to have the permission.

    // It appears that read_permissions is an Array on first read then it's a Set.
    // Detect this and call the correct method to determine inclusion.
    const permissionName: string = "system_health_status";
    let hasReadPermission: boolean = false;
    const permissions = eeviGlobal.loggedInUser.read_permissions;
    if (Array.isArray(permissions)) {
        if (permissions.includes(permissionName)) {
            hasReadPermission = true;
        }
    } else {
        hasReadPermission = permissions.has(permissionName);
    }

    const parameters = new URLSearchParams(window.location.search);
    const demoMode = parseInt(parameters.get("demo") || "") || undefined;

    return (hasReadPermission || demoMode != undefined);
}

