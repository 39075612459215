import {React} from "../../../../common/web_common/components/eevi_react_exports";

/**
 * Redirect to dashboard webpage. We have only PROD and DEV dashboard at this moment.
 */
class Dashboard extends React.Component {
    componentDidMount() {
        let firstDomain = window.location.host.split(".")[0];
        if (firstDomain == "village") {
            window.location.href = "https://dashboard.eevi.life";
        } else {
            window.location.href = "https://dev.dashboard.eevi.life";
        }
    }

    render(): React.ReactNode {
        return null;
    }
}

export default Dashboard;
