import {LoginCredentials, LoginFunction} from "./eevi_data";
import {setInputFocus} from "./eevi_util";
import {EeviRouteButton} from "./eevi_route_button";
import {React, Container, Col, Form, Input, Button} from "./eevi_react_exports";


export interface LoginProperties {
    login: LoginFunction;
    addRegisterButton?: boolean;
    forgotPasswordRoute?: string;
}

export enum LoggedInState {
    NOT_LOGGED_IN,
    LOGGING_IN,
    FAILED_LOGIN,
    LOGGED_IN
}


export interface LoginState extends LoginCredentials {
    loggedInState: LoggedInState;
    message?: string;
}

export class EeviLogin extends React.Component<LoginProperties, LoginState> {
    constructor(props: LoginProperties) {
        super(props);

        this.state = {email: undefined, loggedInState: LoggedInState.NOT_LOGGED_IN};
    }

    protected login(e: React.FormEvent<HTMLFormElement> | undefined, registerNewUser = false): void {
        if (e) {
            e.preventDefault();
        }
        this.setState({loggedInState: LoggedInState.LOGGED_IN});
        this.props.login({
            email: this.state.email,
            password: this.state.password,
            registerNewUser: registerNewUser
        }).then(loggedInUser => {
            const state = loggedInUser.authenticated ? LoggedInState.LOGGED_IN : LoggedInState.FAILED_LOGIN;
            this.setState({loggedInState: state, message: loggedInUser.message});
        });
    }

    /**
     * Login form - implemented as a submit form to make it more compatibile with last pass etc.
     */
    render() {
        const heading = this.heading();

        return <Container
            hidden={this.state.loggedInState === LoggedInState.LOGGED_IN} className="d-flex justify-content-center">
            <Col xs={12} sm={12} md={6} className="shadow p-5 mt-5 mb-5 eevi-content-container">
                {heading}
                <hr className="my-2"/>
                <Form onSubmit={(e) => this.login(e)}>
                    <Input name="username"
                           id="username"
                           type="email"
                           className="mb-1"
                           placeholder="email"
                           autoComplete="email"
                           autoFocus
                           value={this.state.email || ""}
                           innerRef={setInputFocus}
                           disabled={this.state.loggedInState === LoggedInState.LOGGED_IN}
                           onChange={(e) => this.setState({email: e.target.value})}/>
                    <Input name="password"
                           id="password"
                           type="password"
                           className="mb-1"
                           placeholder="password"
                           autoComplete="current-password"
                           value={this.state.password || ""}
                           disabled={this.state.loggedInState === LoggedInState.LOGGED_IN}
                           onChange={(e) => this.setState({password: e.target.value})}/>
                    <Button size="sm" className="btn-primary mt-2" type="submit"
                            disabled={!(this.state.email && this.state.password)}>Login</Button>
                    {
                        this.props.addRegisterButton &&
                        <Button size="sm" className="btn-primary mt-2 ml-1"
                                onClick={() => this.login(undefined, true)}
                                disabled={
                                    this.state.loggedInState === LoggedInState.LOGGED_IN ||
                                    !(this.state.email && this.state.password)
                                }>Register</Button>
                    }
                    {
                        this.props.forgotPasswordRoute &&
                            <EeviRouteButton labelText={"Forgot Password"} location={this.props.forgotPasswordRoute} size={"sm"} className={"btn btn-primary mt-2 ml-1"}/>
                    }
                    {
                        this.state.message &&
                        <span className="invalid-feedback m-4 d-inline">{this.state.message}</span>
                    }
                </Form>
            </Col>
        </Container>;
    }

    protected heading() {
        if (this.state.loggedInState === LoggedInState.NOT_LOGGED_IN) {
            return <h5>please sign in</h5>;
        } else if (this.state.loggedInState === LoggedInState.LOGGING_IN) {
            return <h5 className="font-italic">logging in...</h5>;
        } else if (this.state.loggedInState === LoggedInState.LOGGED_IN) {
            return <h5 className="text-success">thanks</h5>;
        } else {
            // LoggedInState.FAILED_LOGIN
            return <h5 className="text-danger">try again</h5>;
        }
    }
}


