import {apiV1} from "../../../../common/web_common/containers/eevi_std_container";
import {TestRunsTable, TestBatch, TestRun} from "../components/self_test";
import {SmpersForm, SmPersState} from "../components/smpers_form";
import {EeviApi} from "../../../../common/web_common/components/eevi_api";
import {React, withRouter} from "../../../../common/web_common/components/eevi_react_exports";


class RunTestsState extends SmPersState {
    selfTestData: TestBatch = {
        test_types: [],
        test_devices: [],
        test_runs: [],
        still_running: false
    };
}

class AllTestsForm extends SmpersForm<any, RunTestsState> {

    private timer: any = null;

    constructor(props: any) {
        super(props, "Self Test Log", true);

        this.state = new RunTestsState({loading: true});

    }

    protected onFormDidMount() {
        this.updateTestRuns(true);
    }


    protected onFormWillUnmount() {
        if (this.timer !== null) {
            window.clearTimeout(this.timer)
        }
    }

    private updateTestRuns(updateProgress: boolean = false) {
        if (this.timer !== null) {
            window.clearTimeout(this.timer)
            this.timer = null;
        }
        const api = EeviApi.fromComponent(this);
        api.get(
            `${apiV1}/tests/all`,
            "selfTestData",
            (resp) => {
                if (updateProgress) {
                    (resp as { loading: boolean }).loading = false;
                }
                this.setPartialState(resp);
                if (resp.selfTestData.still_running) {
                    this.timer = window.setTimeout(() => this.updateTestRuns(), 2000)
                }
            });
    }

    onSelectRow(item: TestRun) {
        this.setState({redirect: `/tests/${item.batch_id}`})
    }


    renderForm(): React.ReactNode {
        return <>
            {this.state.selfTestData.test_runs.length === 0 && "No test runs"}
            <TestRunsTable testRuns={this.state.selfTestData.test_runs}
                           onSelect={(item) => this.onSelectRow(item)}/>
        </>;
    }

}
export default withRouter(AllTestsForm);