import {LoggedInUser} from "./eevi_data";
import {isNil} from "./eevi_util";
import {EeviLog} from "./eevi_log";


function noLoggedInUser(): LoggedInUser {
    return {
        email: 'unknown',
        authenticated: false,
        read_permissions: new Set(),
        write_permissions: new Set(),
        loginToken: undefined
    };
}

function urlBase64Decode(str: string): string | undefined {
    let output = str.replace(/-/g, '+').replace(/_/g, '/');
    switch (output.length % 4) {
        case 0:
            break;
        case 2:
            output += '==';
            break;
        case 3:
            output += '=';
            break;
        default:
            return undefined;
    }
    return decodeURIComponent((<any>window).escape(window.atob(output)));
}


export class EeviGlobal {
    private readonly logger = new EeviLog(true);

    constructor(
        readonly loggedInUser: LoggedInUser = noLoggedInUser()) {
    }

    isEeviAdmin(): boolean {
        return this.loggedInUser.write_permissions && this.loggedInUser.write_permissions.has("eevi_admin");
    }

    isVillageAdmin(): boolean {
        return this.isEeviAdmin() || (
            this.loggedInUser.write_permissions && this.loggedInUser.write_permissions.has("care_group_admin")
        );

    }

    isLoggedIn() : boolean {
        return this.loggedInUser.email !== undefined && this.loggedInUser.email !== "unknown";
    }

    logout() {
        this.loggedInUser.email = 'unknown';
        this.loggedInUser.authenticated = false;
        this.loggedInUser.loginToken = undefined;
        this.loggedInUser.read_permissions.clear();
        this.loggedInUser.write_permissions.clear();
    }

    log(message: string) {
        this.logger.info(message);
    }

    logRender(message: string,  ...args: any) {
        this.logger.render(message, ...args);
    }

    logEvent(message: string, ...args: any) {
        this.logger.event(message, ...args);
    }


    headerConfig() {
        if (isNil(this.loggedInUser.loginToken)) {
            return undefined;
        }
        return {
            headers: {Authorization: "bearer " + this.loggedInUser.loginToken}
        };
    }

    /**
     * Eevi Response Headers contain information about the logged in user.
     */
    setFromHeaders(response: { headers: { eevijwt?: string } }): void {
        const token = response.headers.eevijwt;
        if (token) {
            this.loggedInUser.loginToken = token;
            const parts = token.split('.');
            if (parts.length === 3) {
                const decoded = urlBase64Decode(parts[1])
                const data: {
                    clientId?: string,
                    read_permissions?: string[],
                    write_permissions?: string[]
                } = JSON.parse(decoded || "{}");
                if (data.clientId) {
                    this.loggedInUser.email = data.clientId;
                    this.loggedInUser.read_permissions = new Set(data.read_permissions || []);
                    this.loggedInUser.write_permissions = new Set(data.read_permissions || []);
                }
            }
        }
    }
}

export const eeviGlobal = new EeviGlobal();
