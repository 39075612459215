import {React} from "./eevi_react_exports";
import {ReactNode} from "react";
import {UncontrolledTooltip} from "reactstrap";


interface Props {
      target: string | HTMLElement;
      children?: ReactNode;
}

export class EeviTooltip extends React.Component<Props> {
    componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
        // Tooltips can fail to find their target - I think because of the delay
    }
    static getDerivedStateFromError(error: Error) {
        return {};
    }

    render() {
        return <UncontrolledTooltip target={this.props.target} delay={400}>{this.props.children}</UncontrolledTooltip>;
    }
}