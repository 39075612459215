import {
    AceEditor,
    React,
    Button,
    Col,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row,
    UncontrolledTooltip, withRouter
} from "../../../../common/web_common/components/eevi_react_exports";
import {SmpersForm, SmPersState} from "../components/smpers_form";
import {EeviFormFeedback} from "../../../../common/web_common/components/eevi_form_feedback";
import {apiV1, MenuLink} from "../../../../common/web_common/containers/eevi_std_container";
import {EeviConfirmButton} from "../../../../common/web_common/components/eevi_confirm_button";
import {isNil} from "../../../../common/web_common/components/eevi_util";
import {DiffContent, EeviDiffContent} from "../../../../common/web_common/components/eevi_diff";

require('brace/theme/eclipse');
require('brace/mode/yaml');
require('brace/ext/language_tools');
require('brace/ext/searchbox');


interface YamlDoc {
    modified_config: string | undefined | null;
    src_config: string;
    diff?: DiffContent[];
}

class OrganisationState extends SmPersState {
    showWarning: boolean = false;
    document: YamlDoc = {src_config: '', modified_config: null};
    loading = true;
}

class OrganisationsForm extends SmpersForm<any, OrganisationState> {

    constructor(props: any) {
        super(props, "Organisations", true);
        this.state = new OrganisationState();
    }


    protected onFormDidMount() {
        this.api.get(`${apiV1}/organisations`, "document");
    }


    private save() {
        const data = this.state.document;
        this.setState(new OrganisationState());
        data.diff = undefined;
        this.api.put(
            `${apiV1}/organisations`,
            data,
            "document",
            undefined,
            true);
    }

    private toggleEditCompare() {
        if (this.state.document.diff === undefined) {
            //    do diff compare
            this.api.post(
                `${apiV1}/organisations_compare`,
                this.state.document,
                "document",
                undefined,
                true
            );
        } else {
            this.setState({
                document: {
                    diff: undefined,
                    modified_config: this.state.document.modified_config,
                    src_config: this.state.document.src_config
                }
            });
        }
    }

    private cancel() {
        this.api.get(
            `${apiV1}/organisations`, "document", undefined, undefined, true);
    }

    private onChangeDoc(doc: string) {
        this.setState(prev => ({
            document: {
                modified_config: doc,
                src_config: prev.document.src_config,
                diff: undefined
            }
        }))
    }

    private getDoc(): string {
        return isNil(this.state.document.modified_config) ?
            this.state.document.src_config :
            this.state.document.modified_config!;
    }

    protected menuLinks(): MenuLink[] {
        return [
            {title: 'Residents', link: '/residents'},
            {title: 'Carers', link: '/carers'},
            {title: 'Accounts', link: '/accounts'},
            {title: 'Org', link: '/organisations'},
        ];
    }

    renderForm(): React.ReactNode {
        const modified_config = this.state.document.modified_config;
        const diff = this.state.document.diff;
        return <>
            {this.warningModal()}
            {
                diff == undefined ? <AceEditor
                        value={this.getDoc()}
                        onChange={(doc: string) => this.onChangeDoc(doc)}
                        mode="yaml"
                        theme="eclipse"
                        fontSize={14}
                        enableBasicAutocompletion={true}
                        enableLiveAutocompletion={true}
                        showGutter={true}
                        showPrintMargin={false}
                        setOptions={{showLineNumbers: true}}
                        editorProps={{$blockScrolling: Infinity}}
                        width="100%"
                        className="border"
                        height={`${this.state.windowHeight - 200}px`}
                        name="organisation_edit"/> :
                    <EeviDiffContent
                        items={diff}
                        width="100%"
                        height={`${this.state.windowHeight - 200}px`}
                        className="table-responsive card"
                    />
            }
            <Row>
                <Col className="ml-5">
                    <EeviFormFeedback fieldName="modified_config" state={this.state}/>
                </Col>
                <Col className="text-right">
                    <Button
                        disabled={this.state.loading || isNil(modified_config) || isNil(diff)}
                        onClick={() => this.save()}
                        className="m-1 eevi_edit_button"
                        size="sm"
                        color="outline-secondary"
                        id="save_org">apply</Button>
                    <Button
                        disabled={this.state.loading || isNil(modified_config)}
                        onClick={() => this.toggleEditCompare()}
                        className="m-1 eevi_edit_button"
                        size="sm"
                        color="outline-secondary"
                        id="compare_edit">{this.state.document.diff ? "edit" : "review"}</Button>

                    <EeviConfirmButton
                        title="Discard any changes?"
                        message="This discard any edits and reload configuration data."
                        disabled={this.state.loading || isNil(modified_config)}
                        onClick={() => this.cancel()}
                        size="sm" color="outline-secondary"
                        className="m-1 eevi_edit_button"
                        id="cancel_org">cancel</EeviConfirmButton>
                    <Button
                        id="help"
                        disabled={this.state.loading}
                        onClick={() => this.setState({showWarning: true})}
                        size="sm" color="outline-secondary"
                        className="m-1 eevi_edit_button">
                        <i className="far fa-question-circle"/>
                    </Button>

                    <UncontrolledTooltip target="save_org" delay={400}>
                        update database
                    </UncontrolledTooltip>
                    <UncontrolledTooltip target="cancel_org" delay={400}>
                        discard changes
                    </UncontrolledTooltip>
                    <UncontrolledTooltip target="help" delay={400}>
                        I don't get it
                    </UncontrolledTooltip>
                </Col>
            </Row>
        </>;
    }

    private warningModal() {
        return <Modal
            centered={true}
            isOpen={this.state.showWarning}>
            <ModalHeader>Eevi System Administrators</ModalHeader>

            <ModalBody>
                <p className="mb-1">Use this form to configure corporate customers.</p>
                <em>Define an organisation by specifying:</em>
                <ul>
                    <li>
                        Sub-groups and access levels
                        <ul>
                            <li>use the <em>Accounts</em> form to give administrators access
                                to a particular access level.
                            </li>
                        </ul>
                    </li>
                    <li>
                        Residences
                        <ul>
                            <li>
                                use the <em>Residents</em> form to add residents and devices
                                to a residence.
                            </li>
                            <li>
                                use the <em>Carers</em> form to add emergency response staff
                                to a village care group.
                            </li>
                        </ul>
                    </li>
                </ul>
            </ModalBody>
            <ModalFooter>
                <Button
                    onClick={() => this.setState({showWarning: false})}
                    color="primary">ok</Button>
            </ModalFooter>
        </Modal>;
    }
}

export default withRouter(OrganisationsForm);