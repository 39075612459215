import {ServiceStatusIncident} from "../service_status_components";

export interface RenderDates {
    start: Date;
    end: Date;
    days: number;
}

export function getServiceEventDemoDates(demoMode: number): RenderDates | null {
    let demoDates: RenderDates | null;

    switch (demoMode) {
        // Date range on demo is fixed since the data set is fixed
        case 1:
        case 2:
        case 3:
            const daysDifference: number = 90;

            const renderEndDate: Date = new Date("2023-05-18");
            renderEndDate.setHours(23, 59, 59, 0);

            const renderStartDate: Date = new Date(renderEndDate);
            renderStartDate.setDate(renderStartDate.getDate() - daysDifference);
            renderStartDate.setHours(0, 0, 0, 0);

            demoDates = {
                start: renderStartDate,
                end: renderEndDate,
                days: daysDifference
            }
            break

        default:
            demoDates = null
    }

    return demoDates;
}

export function getShowEmfitDemoData(demoMode: number): boolean {

    let showEmfitDemoData: boolean;

    switch (demoMode) {
        case 1: // Every service and show Emfit
            showEmfitDemoData = true;
            break

        case 2: // Every service and don't show Emfit
            showEmfitDemoData = false;
            break

        case 3: // Collated showing Emfit
            showEmfitDemoData = true;
            break

        default:
            showEmfitDemoData = false;
    }

    return showEmfitDemoData;
}

/*
const ServiceDataName = {
    aws: "aws",
    villageCare: 'village_care',
    jupl: 'jupl',
    emfit: 'emfit',
    smartThings: 'smartthings',
}
*/
export function getServiceEventDemoData(demoMode: number): ServiceStatusIncident[] {

    // serviceName defined in service_status_components.ServiceDataName
    const sampleDataAllServices: ServiceStatusIncident[] =
        [
            {
                serviceName: "aws",
                started: "2023-05-15T01:00:00+00:00",
                ended: "2023-05-15T02:40:51+00:00",
                description: "AWS - started and ended 15 May"
            },
            {
                serviceName: "village_care",
                started: "2023-05-03T07:45:20+00:00",
                ended: "2023-05-03T08:55:51+00:00",
                description: "Village Care - started and ended 3 May"
            },
            {
                serviceName: "jupl",
                started: "2023-05-18T04:51:51+00:00",
                ended: "",
                description: "JUPL started 18 May and not ended"
            },
            {
                serviceName: "emfit",
                started: "2023-05-15T00:51:51+00:00",
                ended: "",
                description: "Emfit - started 15 May and not ended"
            },

            {
                serviceName: "smartthings",
                started: "2023-05-10T00:00:00+00:00",
                ended: "2023-05-15T00:51:51+00:00",
                description: "Smart Things started 10 May and ended 15May"
            }
        ];

    const collatedEmfitData: ServiceStatusIncident[] =
        [
            {
                serviceName: "emfit",
                started: "2023-05-14T09:17:04.563629+00:00",
                ended: "2023-05-14T10:44:00.400758+00:00",
                description: "0"
            },
            {
                serviceName: "emfit",
                started: "2023-05-14T10:53:03.770983+00:00",
                ended: "2023-05-14T11:16:59.682321+00:00",
                description: "1"
            },
            {
                serviceName: "emfit",
                started: "2023-05-14T11:23:03.187567+00:00",
                ended: "2023-05-14T11:25:59.988083+00:00",
                description: "2"
            },
            {
                serviceName: "emfit",
                started: "2023-05-14T11:29:03.874525+00:00",
                ended: "2023-05-14T11:40:59.603812+00:00",
                description: "3"
            },
            {
                serviceName: "emfit",
                started: "2023-05-14T11:44:03.722122+00:00",
                ended: "2023-05-14T11:47:03.669020+00:00",
                description: "4"
            },
            {
                serviceName: "emfit",
                started: "2023-05-14T11:50:04.510816+00:00",
                ended: "2023-05-14T12:07:59.594756+00:00",
                description: "5"
            },
            {
                serviceName: "emfit",
                started: "2023-05-14T12:20:03.728791+00:00",
                ended: "2023-05-14T12:35:00.592468+00:00",
                description: "6"
            },
            {
                serviceName: "emfit",
                started: "2023-05-14T13:05:04.719899+00:00",
                ended: "2023-05-14T13:07:59.774575+00:00",
                description: "7"
            },
            {
                serviceName: "emfit",
                started: "2023-05-14T13:23:04.155715+00:00",
                ended: "2023-05-14T13:53:05.565499+00:00",
                description: "8"
            },
            {
                serviceName: "emfit",
                started: "2023-05-14T13:59:04.094717+00:00",
                ended: "2023-05-14T14:01:59.695543+00:00",
                description: "9"
            },
            {
                serviceName: "emfit",
                started: "2023-05-14T14:14:03.922598+00:00",
                ended: "2023-05-14T14:20:00.053730+00:00",
                description: "10"
            },
            {
                serviceName: "emfit",
                started: "2023-05-14T14:23:04.074008+00:00",
                ended: "2023-05-14T14:29:00.571875+00:00",
                description: "11"
            },
            {
                serviceName: "emfit",
                started: "2023-05-14T14:35:04.163843+00:00",
                ended: "2023-05-14T14:52:59.893682+00:00",
                description: "12"
            },
            {
                serviceName: "emfit",
                started: "2023-05-14T15:11:03.388129+00:00",
                ended: "2023-05-14T15:14:04.118681+00:00",
                description: "13"
            },
            {
                serviceName: "emfit",
                started: "2023-05-14T15:20:04.063963+00:00",
                ended: "2023-05-14T15:26:01.067551+00:00",
                description: "14"
            },
            {
                serviceName: "emfit",
                started: "2023-05-14T15:32:04.065696+00:00",
                ended: "2023-05-14T15:43:59.882547+00:00",
                description: "15"
            },
            {
                serviceName: "emfit",
                started: "2023-05-14T16:11:04.452771+00:00",
                ended: "2023-05-14T16:22:59.556312+00:00",
                description: "16"
            },
            {
                serviceName: "emfit",
                started: "2023-05-14T16:26:03.919685+00:00",
                ended: "2023-05-14T16:28:59.694954+00:00",
                description: "17"
            },
            {
                serviceName: "emfit",
                started: "2023-05-14T16:38:03.720410+00:00",
                ended: "2023-05-14T16:41:04.275634+00:00",
                description: "18"
            },
            {
                serviceName: "emfit",
                started: "2023-05-14T16:53:03.444817+00:00",
                ended: "2023-05-14T16:56:01.085350+00:00",
                description: "19"
            },
            {
                serviceName: "emfit",
                started: "2023-05-14T17:32:03.930112+00:00",
                ended: "2023-05-14T17:38:00.774494+00:00",
                description: "20"
            },
            {
                serviceName: "emfit",
                started: "2023-05-14T18:02:03.846627+00:00",
                ended: "2023-05-14T18:04:59.960585+00:00",
                description: "21"
            },
            {
                serviceName: "emfit",
                started: "2023-05-14T18:14:04.814065+00:00",
                ended: "2023-05-14T18:17:03.843707+00:00",
                description: "22"
            },
            {
                serviceName: "emfit",
                started: "2023-05-14T18:53:03.699625+00:00",
                ended: "2023-05-14T18:59:00.023841+00:00",
                description: "23"
            },
            {
                serviceName: "emfit",
                started: "2023-05-14T19:08:04.148052+00:00",
                ended: "2023-05-14T19:10:59.582545+00:00",
                description: "24"
            }
        ];

    // Test data set allows us to test the incident collating rules.
    let demoData: ServiceStatusIncident[]
    switch (demoMode) {
        case 1: // Every service and show Emfit
            demoData = sampleDataAllServices;
            break

        case 2: // Every service and don't show Emfit
            demoData = sampleDataAllServices;
            break

        case 3: // Collated showing Emfit
            demoData = collatedEmfitData;
            break

        default:
            demoData = []
    }

    return demoData;
}
