import {React, FormText} from "../../../../common/web_common/components/eevi_react_exports";
import {EeviLoading} from "../../../../common/web_common/components/eevi_loading";
import {setEeviUid} from "../../../../common/web_common/components/eevi_util";

interface ResultsProps<T> {
    items: Array<T> | "loading" | undefined;
    none?: string;
    render: { (value: T, index: number): any; };
    loading?: boolean;
}

/**
 * Displays the loader or a no results message or the actual content if there is any.
 */
export function Results<T>(props: ResultsProps<T>) {
    const items = props.items || [];
    if (items === "loading" || props.loading) {
        return <EeviLoading/>;
    } else if (items.length == 0) {
        return <FormText color="muted" className="keep-lines ml-4">{props.none || "None"}</FormText>;
    } else {
        return <>
            {items.map((item, index) =>
                <div key={setEeviUid(item, "results:")}>{props.render(item, index)}</div>)}
        </>;
    }
}
