import {EeviErrorState} from "./eevi_standard_state";
import {fieldError, unHandledApiError} from "./eevi_error";
import {React} from "./eevi_react_exports";


export interface EeviFormFeedbackProps {
    state: EeviErrorState;
    fieldName: string;
    hidden?: boolean;
}


export function EeviFormFeedback(props: EeviFormFeedbackProps) {
    const apiError = fieldError(props.state, props.fieldName);
    return <div className="invalid-feedback" hidden={props.hidden}>{apiError ? apiError.error_message : ""}</div>;
}


export interface EeviUnhandledApiError {
    formState: EeviErrorState;
}


export function EeviUnhandledApiError(props: EeviUnhandledApiError & { children: React.ReactNode | React.ReactNode[] }) {
    const [unhandled, setUnhandled] = React.useState<string>();

    function checkError() {
        const error = unHandledApiError(props.formState);
        if (unhandled !== error) {
            setTimeout(() => setUnhandled(error));
        }
        return <></>;
    }

    return <>
        {unhandled && <div className="invalid-feedback m-5">{unhandled}</div>}
        {props.children}
        {checkError()}
    </>;
}