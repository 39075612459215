import {React} from "./eevi_react_exports";
import {setEeviUid} from "./eevi_util";

type DiffType = "insert" | "delete" | "modify" | "unchanged";
export type DiffContent = { diffType: DiffType; content: React.ReactNode };

interface DiffContentProps {
    items: DiffContent[];
    height?:string | number;
    width?: string | number;
    className?: string;
}

/**
 * Renders content in different colors depending if the content's diff property is "insert", "delete", etc
 */
export function EeviDiffContent(props: DiffContentProps) {
    const style: React.CSSProperties = {
        height: props.height,
        width: props.width
    };
    return <div style={style} className={props.className}>
        {
            props.items.map(diffContent =>
                <div
                    className={"eevi_diff_" + diffContent.diffType}
                    key={setEeviUid(diffContent, 'diff')}>{diffContent.content}</div>
            )
        }
    </div>;
}

