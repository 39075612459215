import {EeviColumnDefinition, EeviTable} from "../../../../common/web_common/components/eevi_table";
import {ifIn, ifValue, localDateTimeString, use} from "../../../../common/web_common/components/eevi_transform";
import {EeviEventHandler} from "../../../../common/web_common/components/eevi_event_handler";
import {EeviLookupItem} from "../../../../common/web_common/components/eevi_lookup";
import {React} from "../../../../common/web_common/components/eevi_react_exports";

interface TestRun {
    id: string;
    timestamp: string;
    test_type: string;
    status: string;
    results: string;
    batch_id: string;
}

interface TestBatch {
    test_devices: Array<EeviLookupItem>;
    test_types: Array<EeviLookupItem>;
    test_runs: Array<TestRun>;
    still_running: boolean;
    batch_id?: string;
    running_test_number?: number;
    total_number_of_tests?: number;
}

const columnDefinitions: Array<EeviColumnDefinition<TestRun>> = [
    {
        columnHeaderTitle: "Date",
        dataKey: "timestamp" as keyof TestRun,
        formatter: localDateTimeString,
        width: "7.5%",
    },
    {
        columnHeaderTitle: "Test Type",
        dataKey: "test_type" as keyof TestRun,
        width: "7.5%",
    },
    {
        columnHeaderTitle: "Info",
        dataKey: "results" as keyof TestRun,
        styles: [use('keep-lines')],
        width: "80%"
    },
    {
        columnHeaderTitle: "Status",
        dataKey: "status" as keyof TestRun,
        formatter: ifIn(['success', 'fail'],'', <div className="eevi_small_loader"/>),
        styles: [
            use('center'), ifValue('success', 'icon-tick'), ifValue('fail', 'icon-error')],
        width: "5%"
    },
];

const primaryKey: keyof TestRun = "id" as keyof TestRun;

function TestRunsTable(props: { testRuns: Array<TestRun>, onSelect?: EeviEventHandler<TestRun> }) {

    if (props.testRuns.length > 0) {
        return <EeviTable columnDefinitions={columnDefinitions} primaryKey={primaryKey} data={props.testRuns}
                          onSelect={props.onSelect} fixedWidth={true}/>;
    }
    else {
        return null;
    }
}

function TestBatchStats(props: { total_number_of_tests?: number, running_test_number?: number }) {
    let text = '';
    if (props.total_number_of_tests && props.running_test_number) {
        text = `${props.running_test_number} of ${props.total_number_of_tests} tests`;
    }
    return <div className="eevi_text right">{text}</div>;
}

export {TestRunsTable, TestBatch, TestRun, TestBatchStats};