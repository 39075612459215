import "react-datepicker/dist/react-datepicker.css"
import DatePicker from "react-datepicker";
import {EeviEventHandler} from "./eevi_event_handler";
import {EeviDropDown} from "./eevi_drop_down";
import {dateAddDays, isToday} from "./eevi_util";
import {React} from "./eevi_react_exports";

interface DatePickerProps {
    menuButtonClass: string;
    value?: Date;
    onChange: EeviEventHandler<Date>;
    minDate?: Date;
    maxDate?: Date;
}


export function EeviDatePicker(props: DatePickerProps) {
    const inputDate = props.value || new Date();
    let dropDown: EeviDropDown | undefined | null;

    function onChangeDate(date: Date | undefined | null) {
        date = date || new Date();
        const localDate = new Date(
            date.getFullYear(),
            date.getMonth(),
            date.getDate()
        );
        props.onChange(localDate);
        dropDown && dropDown.close();
    }

    return <EeviDropDown
        ref={(instance) => dropDown = instance}
        menuButtonClass={props.menuButtonClass}
        fontIconClass="fas fa-calendar-alt"
        menuButtonContent={' ' + inputDate.toLocaleDateString()}
        noBorder={true}>
        <DatePicker
            inline
            minDate={props.minDate}
            maxDate={props.maxDate}
            peekNextMonth
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
            onChange={date => onChangeDate(date)}/>
    </EeviDropDown>;
}

interface DateRangePickerProps {
    menuButtonClass: string;
    startDate?: Date;
    endDate?: Date;
    onChange: { (start: Date, end: Date): void };
    minDate?: Date;
    maxDate?: Date;
    scaleDown: boolean;
}

interface DateRangePickerState {
    changedStartDate?: boolean;
    endDate?: Date;
    startDate?: Date;
}

export class EeviDateRangePicker extends React.Component<DateRangePickerProps, DateRangePickerState> {
    private dropDown: EeviDropDown | undefined | null;

    constructor(props: DateRangePickerProps) {
        super(props);
        const endDate = props.endDate || new Date();
        this.state = {
            changedStartDate: true,
            endDate: endDate,
            startDate: props.startDate || dateAddDays(endDate, -7)
        };
    }

    onChangeDate(date: Date | undefined | null) {
        if (this.state.changedStartDate) {
            const startDate = date || new Date();
            const localDate = new Date(
                startDate.getFullYear(),
                startDate.getMonth(),
                startDate.getDate()
            );
            let endDate: Date;
            if (this.state.endDate! < localDate) {
                endDate = new Date(
                    localDate.getFullYear(),
                    localDate.getMonth(),
                    localDate.getDate(),
                    23,
                    59,
                    59,
                    999
                );
            } else {
                endDate = this.state.endDate!;
            }
            if (isToday(endDate, true)) {
                endDate = new Date();
            }
            this.setState({startDate: localDate, endDate: endDate});
            this.props.onChange(localDate, endDate);
        } else {
            const endDate = date || new Date();
            let localDate = new Date(
                endDate.getFullYear(),
                endDate.getMonth(),
                endDate.getDate(),
                23,
                59,
                59,
                999
            );
            let startDate: Date;
            if (this.state.startDate! > localDate) {
                startDate = dateAddDays(localDate, 0, -23, -59, -59, 999);
            } else {
                startDate = this.state.startDate!;
            }
            if (isToday(localDate, true)) {
                localDate = new Date();
            }
            this.setState({startDate: startDate, endDate: localDate});
            this.props.onChange(startDate, localDate);

        }
        this.dropDown && this.dropDown.close();
    }

    changeStartDate() {
        this.setState({changedStartDate: true});
        this.dropDown && this.dropDown.toggle();
    }

    changeEndDate() {
        this.setState({changedStartDate: false});
        this.dropDown && this.dropDown.toggle();
    }

    render() {
        const doubleButton = <>
            <span
                onClick={() => this.changeStartDate()}
                className={this.props.menuButtonClass + " eevi_dropdown"}>
                {this.props.scaleDown ? '...' : this.state.startDate!.toLocaleDateString()}
            </span>
            <span
                onClick={() => this.changeEndDate()}
                className={this.props.menuButtonClass + " eevi_dropdown"}>
                {' - ' + (this.props.scaleDown ? '...' : this.state.endDate!.toLocaleDateString())}
            </span>
        </>;

        return <EeviDropDown
            ref={(instance) => this.dropDown = instance}
            menuButtonClass={this.props.menuButtonClass}
            fontIconClass="fas fa-calendar-alt"
            menuButtonContent={doubleButton}
            noBorder={true}>
            <DatePicker
                minDate={this.props.minDate}
                maxDate={this.props.maxDate}
                inline
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                selected={this.state.changedStartDate ? this.state.startDate : this.state.endDate}
                onChange={date => this.onChangeDate(date)}/>
        </EeviDropDown>;
    }
}