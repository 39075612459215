import {React, Button, ButtonProps, Modal, ModalFooter, ModalHeader, ModalBody} from "./eevi_react_exports";


interface ConfirmState {
    confirming?: boolean;
}

type ConfirmButtonProperties = {
    title: string;
    message: string;
    confirmButtonText?: string;
    cancelButtonText?: string;
} & ButtonProps;

/**
 * Standard button with some sort of confirmation message to proceed.
 * onClick is only fired if the end user responded in the affirmative to the confirmation.
 */
export class EeviConfirmButton extends React.Component<ConfirmButtonProperties, ConfirmState> {

    constructor(props: ConfirmButtonProperties) {
        super(props);
        this.state = {}
    }

    render() {
        return <>
            <Button
                {...this.props}
                onClick={() => this.setState({confirming: true})}
                disabled={this.state.confirming || this.props.disabled}>{this.props.children}</Button>
            <Modal
                className="mt-5"
                centred="true"
                isOpen={this.state.confirming}>
                <ModalHeader>{this.props.title}</ModalHeader>
                <ModalBody>{this.props.message}</ModalBody>
                <ModalFooter>
                    <Button
                        onClick={(e: React.MouseEvent<any>) : void => this.onConfirmed(e)}
                        color="primary">{this.props.confirmButtonText || "ok"}</Button>
                    <Button
                        color="secondary"
                        onClick={() => this.onCancelled()}>{this.props.cancelButtonText || "cancel"}</Button>
                </ModalFooter>
            </Modal>
        </>;
    }

    private onConfirmed(e: React.MouseEvent<any>) : void {
        e.preventDefault();
        this.setState({confirming: false});
        if (this.props.onClick) {
            this.props.onClick!(e);
        }
    }

    private onCancelled() {
        this.setState({confirming: false});
    }
}

