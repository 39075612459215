import {SmpersForm, SmPersState} from "../components/smpers_form";
import {apiV1, MenuLink} from "../../../../common/web_common/containers/eevi_std_container";
import {
    Button,
    Container,
    Input,
    InputGroup,
    InputGroupAddon,
    React, withRouter
} from "../../../../common/web_common/components/eevi_react_exports";
import {eeviGlobal} from "../../../../common/web_common/components/eevi_context";

class EeviAdminFormState extends SmPersState {
    executeUrl: string = '';
    responseData: string = '';
    loading?: boolean = true;
}

class EeviAdminForm extends SmpersForm<any, EeviAdminFormState> {
    private timerId?: NodeJS.Timeout;

    constructor(props: any) {
        super(props, "Eevi Admin");
        this.state = new EeviAdminFormState();
    }


    protected onFormDidMount() {
        //    call smpers to make sure we are logged in and keep refreshing token we will use to call plugin
        this.api.get(`${apiV1}/refresh_token`);
        if (this.timerId === undefined) {
            this.timerId = setInterval(() => this.api.get(`${apiV1}/refresh_token`), 15000);
        }
    }



    protected onFormWillUnmount() {
        if (this.timerId !== undefined) {
            clearInterval(this.timerId);
            this.timerId = undefined;
        }
    }


    protected menuLinks(): MenuLink[] {
        return [
            {title: 'Residents', link: '/residents'},
            {title: 'Carers', link: '/carers'},
            {title: 'Accounts', link: '/accounts'},
            {title: 'Org', link: '/organisations'},
        ];
    }

    private printResponse(data: any) {
        let resultData: string;
        if (typeof data === 'object') {
            if ('response' in data && data.response && 'data' in data.response && data.response.data) {
                // we're using same output code for eevi error handling
                this.printResponse(data.response.data);
                return
            }
            resultData = JSON.stringify(data, undefined, 4);
        }
        else {
            resultData = data.toString();
        }
        this.setState({
            "responseData": resultData,
            "loading": false
        });
    }

    private executePluginUrl() {
        this.setState({"responseData": "", "error": undefined});
        let url = this.state.executeUrl.trim();
        if (!url.startsWith("http")) {
            url = "https://" + url;
        }
        this.api.get(
            url,
            undefined,
            (data) => this.printResponse(data),
            undefined,
            true,
            (data) => this.printResponse(data)
        );

    }


    private isValidUrl(): boolean {
        try {
            let url_data = this.state.executeUrl.trim();
            if (!url_data.startsWith("http")) {
                url_data = "https://" + url_data;
            }
            const url = new URL(url_data);
            return url.hostname.endsWith(".eevi.life");
        }
        catch(err) {
            return false;
        }

    }

    renderForm(): React.ReactNode {
        if (!eeviGlobal.loggedInUser.write_permissions.has("eevi")) {
            return <div className="m-5">This page is for eevi staff only.</div>
        }
        return <Container fluid>
            <div className="ml-5 mt-4 w-75">
                <span>Enter a plugin url to execute as </span>
                <span className="font-weight-bold">{eeviGlobal.loggedInUser.email}</span>
            </div>
            <InputGroup className="ml-5 mb-3 w-75">
                <Input
                    disabled={this.state.loading}
                    value={this.state.executeUrl}
                    onChange={(e) => this.setState({executeUrl: e.target.value})}
                    onKeyDown={(e) => {
                        if (e.key == 'Enter' && this.isValidUrl()) this.executePluginUrl();
                    }}
                />
                <InputGroupAddon addonType="append">
                    <Button
                        disabled={this.state.loading || !this.isValidUrl()}
                        className="btn-primary"
                        onClick={() => this.executePluginUrl()}>GET</Button>
                </InputGroupAddon>
            </InputGroup>
            <div className="ml-5 keep-lines">{this.state.error || ''}</div>
            <div className="ml-5 keep-lines">{this.state.responseData || ''}</div>

        </Container>;
    }

}

export default withRouter(EeviAdminForm);
