import {UnderMaintenance} from "./eevi_under_maintenance";
import {EeviLogin} from "./eevi_login";
import {LoginFunction} from "./eevi_data";
import {EeviErrorState} from "./eevi_standard_state";
import {EeviEventHandler} from "./eevi_event_handler";
import {isNil} from "./eevi_util";
import {React, Container} from "./eevi_react_exports";


interface EeviApiError {
    err_code: number;
    error_message: string;
    field_name: string;
    handled?: boolean;
}

/**
 * This means it is an error in the standard format thrown by our API.
 * @param state
 */
export function isApiError(state: EeviErrorState): boolean {
    return state.error && state.error.response && state.error.response.data && state.error.response.data.errors;
}

/**
 * Any unknown errors and most API errors get handled at the top level (not UI sub-component)
 * @param state
 */
export function isGlobalError(state: EeviErrorState): boolean {
    return state.error && (
        [
            'Not logged in',
            'Under maintenance',
            'Insufficient access permissions'
        ].includes(GetErrorMessage(state.error)) ||
        !isApiError(state));
}


/**
 * For ui components that handle errors at a field level - returns if there is an error matching the specified field.
 * @param state
 * @param fieldName
 */
export function fieldError(state: EeviErrorState, fieldName: string): EeviApiError | undefined {
    if (isApiError(state)) {
        const apiErrors = state.error.response.data.errors as EeviApiError[];
        const err: EeviApiError | undefined = apiErrors.find(error => error.field_name === fieldName);
        if (err) {
            err.handled = true;
            return err;
        }
    }
    return undefined;
}

/**
 * Let the ui set api errors directly to make use of EeviFormFeedback
 * @param state
 * @param message
 * @param fieldName
 */
export function setFieldError(state: EeviErrorState, message: string, fieldName: string) {
    state.error = {
        response: {
            data: {
                errors: [
                    {
                        err_code: 1,
                        error_message: message,
                        field_name: fieldName
                    }
                ]
            }
        }
    };
}

/**
 * API errors become global errors unless a child component has already handled/reported it.
 * @param state
 */
export function unHandledApiError(state: EeviErrorState): string | undefined {
    if (isApiError(state)) {
        const apiErrors = state.error.response.data.errors as EeviApiError[];
        if (isNil(apiErrors.find(error => error.handled === true))) {
            return GetErrorMessage(state.error);
        }
    }
    return undefined;
}

function StringError(error: any): string | null {
    if (typeof error === "string") {
        return error;
    } else {
        return null;
    }
}

function ErrorProperty(error: any, propName: string): string | null {
    if (propName in error) {
        return error[propName];
    } else {
        return null;
    }
}

function ResponseDataError(error: any): string | null {
    if ('response' in error && error.response && 'data' in error.response && error.response.data) {
        return GetErrorMessage(error.response.data)
    } else {
        return null;
    }
}

function StandardApiError(error: any): string | null {
    if ('errors' in error) {
        const errors = error['errors'];
        if (Array.isArray(errors) && errors.length > 0) {
            return GetErrorMessage(errors[0]);
        }
    }
    return null;
}


export function GetErrorMessage(error: any): string {
    return StringError(error) ||
        ResponseDataError(error) ||
        ErrorProperty(error, "error") ||
        ErrorProperty(error, "message") ||
        ErrorProperty(error, "error_message") ||
        StandardApiError(error) ||
        error.toString();
}

export function EeviError(props: { error: any; login: LoginFunction; addLoginRegisterButton?: boolean; forgotPasswordRoute?: string }) {
    const error = props.error;
    const message = GetErrorMessage(error);
    if (message === 'Under maintenance') {
        return <UnderMaintenance/>;
    } else if (message === "Not logged in") {
        return <EeviLogin login={props.login}
                          addRegisterButton={props.addLoginRegisterButton}
                          forgotPasswordRoute={props.forgotPasswordRoute}/>;
    } else {
        return <Container>
            <div className="shadow p-5 mt-5 mb-5">
                <h5>Sorry, an error has occurred.</h5>
                <hr className="my-2"/>
                <div className="keep-lines">{message}</div>
            </div>
        </Container>;
    }
}

interface FatalErrorProps {
    state: EeviErrorState;
    onGlobalError: EeviEventHandler<any>;
    children: React.ReactNode | React.ReactNode[]
}

export function WithCheckForFatalError(props: FatalErrorProps) {
    if (isGlobalError(props.state)) {
        props.onGlobalError(props.state.error);
        return <></>;
    } else {
        return <>{props.children}</>;
    }
}