import {React, withRouter} from "../../../../common/web_common/components/eevi_react_exports";
import {EeviForm, EeviFormState} from "../../../../common/web_common/forms/eevi_form";
import {EeviApi} from "../../../../common/web_common/components/eevi_api";
import {apiV1, MenuLink} from "../../../../common/web_common/containers/eevi_std_container";
import {eeviId} from "../../../../common/web_common/components/eevi_util";
import {getConnectivityDemoConfig, getConnectivityDemoData} from "../components/demo/connectivity_sample_data";
import {HistoricalData, RawHistoricalData} from "../components/device_connectivity_components";
import {showServiceHealthLink} from "./service_status_form";

interface DeviceBasicInfo {
    serviceAddressCode: string
    deviceType: string
    deviceName: string
    deviceModel: string
    enablePendant: boolean,
    pendantId: string,
}

class DeviceConnectivityState implements EeviFormState {
    error: any
    loading: boolean = true
    nextToken?: string
    redirect?: string
    title?: string
    windowHeight: number = 0
    windowWidth: number = 0

    requestId?: string
    deviceId: string = ""
    deviceInfo?: DeviceBasicInfo
    payload?: RawHistoricalData
    historicalData = this.parseRawData

    protected* parseRawData(): IterableIterator<HistoricalData> {
        if (!this.payload) {
            return []
        }

        // We are going to show the latest data entry first
        const wifiPayload = this.payload.wifi;
        for (const [date, time_entry] of Object.entries(wifiPayload).reverse()) {
            for (const [time, online_status] of Object.entries(time_entry).reverse()) {
                yield [new Date(`${date} ${time}`), online_status]
            }
        }
    }
}

class DeviceConnectivityForm extends EeviForm<any, DeviceConnectivityState> {
    private readonly api: EeviApi<DeviceConnectivityState>
    private requestId = ""
    private startTime: null | Date = null
    private endTime: null | Date = null
    private deviceId: string = ""

    // Read from 'demo' query param.  If set to a valid positive number, then this specifies the sample data set to show
    private demoMode: number | null = null;

    constructor(props: any) {
        super(props, "Device Connectivity", false, true)
        this.api = EeviApi.fromComponent<DeviceConnectivityState>(this)
        this.state = new DeviceConnectivityState()
        this.demoMode = null;
    }

    protected menuLinks(): MenuLink[] {
        let menu_links = [
            {title: 'Residents', link: '/residents'},
            {title: 'Carers', link: '/carers'},
            {title: 'Events', link: '/events'},
            {title: 'Smart Annunciator', link: '/event_status'},
            //{title: 'Service Status', link: '/service_status'},
            {title: 'Dashboard', link: '/dashboard'}
        ];

        if (showServiceHealthLink()) {
            menu_links.splice(4, 0, {title: 'Service Status', link: '/service_status'},);
        }
        return menu_links;
    }

    protected onFormDidMount() {
        this.requestId = eeviId()

        const parameters:URLSearchParams = new URLSearchParams(window.location.search);
        this.demoMode = parseInt(parameters.get("demo") || "") || null;

        // By default, start time is 3 hours before end time (now)
        // Note the start and end times are in UTC
        let now = new Date();
        let utcNow = Date.UTC(
            now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(),
            now.getUTCHours(), now.getUTCMinutes(), 0, 0
        );
        const offsetHours: number = 3

        let startTimeString = decodeURIComponent(parameters.get("startTime") || "")
        if (startTimeString) {
            this.startTime = new Date(startTimeString)
        } else {
            this.startTime = new Date(utcNow)
            this.startTime.setHours(this.startTime.getHours() - offsetHours);
        }

        let endTimeString = decodeURIComponent(parameters.get("endTime") || "")
        if (endTimeString) {
            this.endTime = new Date(endTimeString)
        } else {
            let now = new Date();
            let utc = Date.UTC(
                now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(),
                now.getUTCHours(), now.getUTCMinutes(), 0, 0
            );
            this.endTime = new Date(utc)
        }

        this.deviceId = parameters.get("deviceId") || ""

        // If we have the magical demoData set then force in the sample data without making an API call.
        // This is because the API call below will validate the device ID and will 404 fail if not valid.
        if (this.demoMode) {
            let deviceInfo = getConnectivityDemoConfig(this.demoMode);
            if (deviceInfo) {
                this.startTime = new Date(deviceInfo.startTime);
                this.endTime = new Date(deviceInfo.endTime);
            }

            let data = getConnectivityDemoData(this.demoMode);
            if (data && deviceInfo) {
                const mergedData: {} = Object.assign({}, {deviceInfo: deviceInfo}, {payload: data});
                this.setState({...this.state, ...mergedData, loading: false})
            }
            return;
        }

        if (this.deviceId) {
            // The get API call will validate the device ID and return a 4040 if not found.
            // We want to bypass this check for our demoMode devices ID's as they are not valid ID's
            this.api.get(
                `${apiV1}/device/connectivity?` +
                `deviceId=${this.deviceId}` +
                (this.startTime.getTime() ? `&startTime=${encodeURIComponent(this.startTime.toISOString())}` : "") +
                (this.endTime.getTime() ? `&endTime=${encodeURIComponent(this.endTime.toISOString())}` : "") +
                `&requestId=${this.requestId}`,
                undefined,
                (data: Partial<DeviceConnectivityState>) => {
                    if (this.requestId === data.requestId) {
                        this.setState({...this.state, ...data, loading: false})
                    }
                },
                undefined,
                true,
            )
        } else {
            this.setState({loading: false, error: "Unable to find a 'deviceId' query param"})
        }
    }

    protected errorHandler(error: Partial<any>) {
        this.setState({...this.state, loading: false, error: error});
    }

    protected renderForm(): any {
        const datetimeStringFormat: Intl.DateTimeFormatOptions = {
            day: 'numeric',
            month: 'numeric',
            year: 'numeric',

            hour: 'numeric',
            minute: 'numeric',
            hour12: false
        }
        const dateStringFormat: Intl.DateTimeFormatOptions = {
            day: 'numeric',
            month: 'numeric',
            year: 'numeric',
        }
        const timeStringFormat: Intl.DateTimeFormatOptions = {
            hour: 'numeric',
            minute: 'numeric',
            hour12: false
        }
        const locale:string = "en-GB";

        const content: JSX.Element[] = []

        for (const e of this.state.historicalData()) {
            let online = Boolean(parseInt(e[1]))
            content.push(
                <tr className={online ? "device-online-background" : "device-offline-background"}
                    key={e[0].toISOString()}>
                    <td>{e[0].toLocaleString('en-GB', datetimeStringFormat)}</td>
                    <td>{online ? "ONLINE" : "OFFLINE"}</td>
                </tr>
            )
        }

        let pendantEnabled: boolean = false;
        let pendantLastSeenDate: Date | null = null;

        if (this.state.deviceInfo?.enablePendant &&
            this.state.deviceInfo?.pendantId &&
            this.state.deviceInfo?.pendantId.length > 0
        ) {
            pendantEnabled = true;
            const pendantId: string = this.state.deviceInfo?.pendantId[0];

            if (this.state.payload && pendantId && this.state.payload?.pendant) {
                const date: string = this.state.payload?.pendant[pendantId];
                if (date) {
                    pendantLastSeenDate = new Date(
                        Date.parse(date)
                    );
                }
            }
        }

        return this.state.error ? <div/> :
            <div className="connectivity_status_container">
                <p className="title_label">
                    Connectivity status for {this.state.deviceInfo?.deviceModel}: {this.state.deviceInfo?.deviceName} at {this.state.deviceInfo?.serviceAddressCode}
                </p>
                {
                    pendantEnabled ?
                        (pendantLastSeenDate ?
                                <p className="pendant_last_seen_label">Bed Exit pendant was last seen at {pendantLastSeenDate.toLocaleTimeString('en-GB', timeStringFormat)} on {pendantLastSeenDate.toLocaleDateString('en-GB', dateStringFormat)}.</p>
                                :
                                <p className="pendant_last_seen_label">Bed Exit pendant is enabled, but has not responded yet.</p>
                        )
                        :
                        ""
                }
                {
                    this.startTime?.toLocaleDateString() == this.endTime?.toLocaleDateString() ?
                        <p className="wifi_date_range_label">Wifi connectivity report
                            between {this.startTime?.toLocaleTimeString(locale, timeStringFormat)} and {this.endTime?.toLocaleTimeString(locale, timeStringFormat)} on {this.startTime?.toLocaleDateString(locale, dateStringFormat)}.</p>
                        :
                        <p className="wifi_date_range_label">Wifi connectivity report
                            between {this.startTime?.toLocaleString(locale, datetimeStringFormat)} and {this.endTime?.toLocaleString(locale, datetimeStringFormat)}.</p>
                }
                {
                    content.length ?
                        <table className="connectivity_status_table table"
                               id={`device-connectivity-${this.state.deviceId}`}>
                            <thead>
                            <tr>
                                <th className="time_label">Time</th>
                                <th className="status_label">Wifi Status</th>
                            </tr>
                            </thead>
                            <tbody>
                            {content}
                            </tbody>
                        </table>
                        : <p className="no_wifi_records_label">No Wifi connectivity records found for this period!</p>
                }
            </div>
    }
}

export default withRouter(DeviceConnectivityForm);