import {React, withRouter} from "../../../../common/web_common/components/eevi_react_exports";
import {SmpersForm, SmPersState} from "../components/smpers_form";
import {EeviContent} from "../../../../common/web_common/components/eevi_std_content";
import {
    ServiceLocationCareGroupContent
} from "../components/service_location_components";
import {Results} from "../components/results";
import {setEeviUid} from "../../../../common/web_common/components/eevi_util";
import {eeviGlobal} from "../../../../common/web_common/components/eevi_context";


class ServiceLocationsForm extends SmpersForm<any, SmPersState> {


    constructor(props: any) {
        super(props, "Residents", true);
        this.state = new SmPersState();
    }

    renderForm(): React.ReactNode {
        eeviGlobal.logRender('ServiceLocationsForm');
        const careGroups = this.coreAccess.getCareGroups();
        if (careGroups !== "loading" && careGroups.length === 1) {
            this.selectCareGroup(careGroups[0].careGroupCode);
        }
        return <EeviContent key="residents_content">
            <Results items={careGroups} none="No service locations..." render={
                careGroup =>
                    <ServiceLocationCareGroupContent
                        key={setEeviUid(careGroup, "xxslscfg")}
                        careGroup={careGroup}/>
            }/>
        </EeviContent>;
    }
}


export default withRouter(ServiceLocationsForm);