import {React, withRouter} from "../../../../common/web_common/components/eevi_react_exports";
import {SmpersForm, SmPersState} from "../components/smpers_form";
import {CarersGroupList} from "../components/carer_components";

class CarersForm extends SmpersForm<any, SmPersState> {

    constructor(props: any) {
        super(props, "Carers");
        this.state = new SmPersState();
    }

    renderForm(): React.ReactNode {
        return <CarersGroupList/>;
    }

}


export default withRouter(CarersForm);