import {EeviForm, EeviFormState} from "../../../../common/web_common/forms/eevi_form";
import {Button, Card, CardBody, CardTitle, Container, React, Row, withRouter} from "../../../../common/web_common/components/eevi_react_exports";
import {apiV1, EeviBaseContainer, MenuLink} from "../../../../common/web_common/containers/eevi_std_container";
import {EeviApi} from "../../../../common/web_common/components/eevi_api";
import {eeviId} from "../../../../common/web_common/components/eevi_util";
import {
    AlarmHeader,
    AlarmTable,
    CarerAvailabilityData, playTone,
    RealtimeEvent,
    RealtimeEventCompute
} from "../components/alarms_components";
import {dynamicSubclass} from "../../../../common/web_common/components/eevi_transform";
import {LoginCredentials} from "../../../../common/web_common/components/eevi_data";
import {EeviCoreContext} from "../../../../common/web_common/core/components/eevi_core_context";
import {CareGroup} from "../../../../common/web_common/core/eevi_core_data";
import {eeviGlobal} from "../../../../common/web_common/components/eevi_context";
import {getDemoCarerAvailable, getDemoEvents} from "../components/demo/sample_data";
import {QUERY_WINDOW} from "../components/alarms_components";

const REFRESH_RATE = 30000; //ms

class AlarmsFormState implements EeviFormState{
    loading?: boolean;
    nextToken?: string;
    redirect?: string;
    title?: string;
    windowHeight: number = 0;
    windowWidth: number = 0;
    error: any;
    requestId?: string;

    events: RealtimeEvent[] = [];

    // If set to true then do not show the Resident name on screen.  Default is false (to show)..
    hideResidentName: boolean = false;

    // The field to sort on (default for current), and if ascending (default A-Z, etc) or descending
    sortField: string = '';
    sortAscendingOrder: boolean = true;

    currentDateTime: Date = new Date();
    lastUpdate: Date = new Date();

    availableCarers = new Map<string,CarerAvailabilityData>();

    constructor(events: RealtimeEvent[]) {
        this.events = events
    }
}

class AlarmsForm extends EeviForm<any, AlarmsFormState>{
    private readonly api: EeviApi<AlarmsFormState>;
    private pageVisible = false;  // is this page visible?
    private requestId = '';  // pagination
    private _timers = new Map();  // polling data periodically

    // Visibility Handler stops api invocation if the page is not visible
    private _visibilityHandler: VoidFunction | undefined;

    private demoMode: number | undefined = undefined;

    private playAlarm: boolean = false;

    // access core context (i.e. carer group, name,...)
    static contextType = EeviCoreContext;  // Use EeviCoreContext to get user's CareGroups

    // new notification audio
    private notification_audio = document.getElementById("new-event-notification") as HTMLMediaElement
    private autoplay = true;
    private acknowledgementCard = <Container className="d-flex flex-column align-items-center">
        <Card style={{width: "40rem"}}>
            <CardTitle className={"ml-3 mr-3 mt-3"} style={{fontSize: "1.6rem"}}>Acknowledgement</CardTitle>
            <CardBody className={"ml-3 mr-3"}>
                <Row style={{whiteSpace: "pre-wrap", fontSize: "1.2rem"}}>
                    {
                        `Welcome to the Real-time Event Status Page.\n` +
                        `This page will automatically update every ${REFRESH_RATE/1000}s.\n` +
                        `Please click OK to proceed.`
                    }
                </Row>
                <Row className={"mt-3"} style={{justifyContent:"flex-end"}}>
                    <Button color="primary" style={{width: "7rem"}} autoFocus onClick={()=>{this.initialize()}}>OK</Button>
                </Row>
            </CardBody>
        </Card>
    </Container>

    constructor(props: any) {
        super(props, "Event Status", false, true);
        this.api = EeviApi.fromComponent<AlarmsFormState>(this);
        this.state = new AlarmsFormState([]);
        this.render = this.render.bind(this);
    }

    protected menuLinks(): MenuLink[] {
        return [];
    }

    private showCarerAvailability(demoMode:number | undefined): boolean {
        // We use the read_permissions as a sort of user default - e.g. a permission
        // indicates we must do something.
        //
        // If we have demo mode enabled, then we always show carer availability.
        // This is not a security issue because we use hard coded data for demo mode.
        // The only way to see real data is to have the permission.
        return (
            eeviGlobal.loggedInUser.read_permissions.has("carer_availability") ||
            this.demoMode!=undefined
        );
    }

    private loadCareGroups(){
        this.context.model.careGroups = "loading";
        this.api.get<CareGroup[]>(
            `${apiV1}/care_groups`,
            undefined,
            (data) => {
                this.context.model.careGroups = data as CareGroup[];

                let parameters = new URLSearchParams(window.location.search)
                this.setState({
                    loading:false,
                    hideResidentName: this.getHideResidentName(parameters),
                    sortField: this.getSortFieldName(parameters),
                    sortAscendingOrder: this.getSortOrderAscending(parameters),
                })

                // Timer for carers currently logged in, polling period is 1 minute
                // If we have carer_availability permission, or we have demoMode set then
                // get carer availability info.  We check demoMode as we want to ensure we show
                // the hard coded carer availability when we have demo enabled (as this is our test mode).
                if (this.showCarerAvailability(this.demoMode)){
                    this.retrieveCarerLoggedIn()
                    this.register_timer(
                        "carers",
                        () => {
                            this.retrieveCarerLoggedIn();
                        },
                        REFRESH_RATE*2
                    )
                }
            },
            undefined,
            true,
            (error) => {this.errorHandler(error)}
        )
    }

    private addVisibilityHandler(){
        if (typeof document.hidden !== "undefined") {
            if (!this._visibilityHandler) {
                this._visibilityHandler = () => {
                    this.pageVisible = !document.hidden
                }
                document.addEventListener("visibilitychange", this._visibilityHandler)
            }
        } else {
            console.warn("Current browser does not support Page Visibility")
        }
    }

    private removeVisibilityHandler(){
        if(this._visibilityHandler){
            document.removeEventListener("visibilitychange", this._visibilityHandler)
            this._visibilityHandler = undefined
        }
    }

    private initialize(){
        this.pageVisible = true;
        this.autoplay = true;

        // Add visible handler
        this.addVisibilityHandler()

        // Get recent events
        this.retrieveRecentEvents(true)

        // Setting up a polling timer that timed out every REFRESH_RATE (30s) to get a list of open events
        this.register_timer(
            "events",
            ()=>{
                this.retrieveRecentEvents()
            },
            REFRESH_RATE
        )

        this.register_timer(
            "play_alarm",
            ()=>{
                if (this.playAlarm && this.pageVisible) {
                    playTone(
                        this.notification_audio,
                        () => {
                            console.warn("Autoplay failed. User interaction is required!")
                            this.autoplay = false
                        },
                        () => {
                            this.notification_audio.muted = false
                            this.playAlarm = false
                        }
                    )
                }
            },
            REFRESH_RATE
        )
    }

    protected retrieveCarerLoggedIn(){
        for (const care_group of this.context.model.careGroups) {
            if (!care_group.careGroupCode) {break}
            this.api.get<CarerAvailabilityData>(
                `${apiV1}/events_status/carers_available?care_group_code=` + care_group.careGroupCode,
                undefined,
                (data) => {
                    let parameters = new URLSearchParams(window.location.search)
                    this.setState({
                        hideResidentName: this.getHideResidentName(parameters),
                        sortField: this.getSortFieldName(parameters),
                        sortAscendingOrder: this.getSortOrderAscending(parameters),
                    })

                    if (this.demoMode){
                        // Quick and dirty demo
                        const retrievedVillage = getDemoCarerAvailable(this.demoMode).map((v) =>{return [v.careGroupName, v]})
                        // @ts-ignore
                        this.setState({availableCarers: new Map(retrievedVillage),
                            loading: false,
                            error: null,
                        })
                    } else {
                        if (data) {
                            this.setState({
                                ...this.state,
                                availableCarers: this.state.availableCarers.set(care_group.careGroupName, data as CarerAvailabilityData),
                                loading: false,
                                error: null,
                            })
                        }
                    }
                },
                undefined,
                false,
                (error) => {
                    this.errorHandler(error)
                }
            )
        }

    }

    private register_timer(name: string, callback: CallableFunction, ms: number = 1000){
        // Single call point for all timers
        this._timers.set(
            name,
            setInterval(
                () => {
                    if (this.pageVisible) {
                        callback()
                    }
                }, ms
            )
        )
    }

    // @ts-ignore
    /*private remove_timer(name: string){
        if (this._timers.has(name)) {
            clearInterval(this._timers.get(name))
            this._timers.delete(name)
        }else{
            console.log("Unable to find timer:" + name)
        }
    }*/

    private clear_timers(){
        this._timers.forEach(
            (value, key) => {
                clearInterval(value)
                this._timers.delete(key)
                console.log(`${key} was removed from timer list`)
            }
        )
    }

    private setAlarm(){
        if (this.playAlarm) {
            // Already in alarm mode, nothing to do
            return
        }
        this.playAlarm = true;
    }

    private getHideResidentName(parameters: URLSearchParams) : boolean {
        // We use the read_permissions as a sort of user default - e.g. a permission
        // indicates we must do something.
        //
        // If we don't have a query param, and we have a permission, then we default to show,
        // otherwise the query_param controls.
        const permission_present = eeviGlobal.loggedInUser.read_permissions.has("hide_resident_name");
        const queryParamValue = parameters.get("hideResidentName")
        if (queryParamValue == undefined && permission_present){
            return true;
        }
        return this.convertStringToBoolean(parameters.get("hideResidentName") || "")
    }

    private convertStringToBoolean(value: string) : boolean {
        const truthy: string[] = [
            'y',
            'yes',
            't',
            'true',
            'True',
            '1'
        ]
        return truthy.includes(value)
    }

    private getSortFieldName(parameters: URLSearchParams) : string {
        // We use the read_permissions as a sort of user default - e.g. a permission
        // indicates we must do something.
        //
        // If we don't have a query param, and we have a permission, then we default to triggered,
        // otherwise the query_param controls.
        const permission_present = eeviGlobal.loggedInUser.read_permissions.has("sort_field_trigger_time");
        const queryParamValue = parameters.get("sortField")
        if (queryParamValue == undefined && permission_present){
            return "triggered_time";
        }
        return this.convertStringToSortFieldName(queryParamValue || "");
    }

    private convertStringToSortFieldName(value: string) : string {
        const validFieldNames: string[] = [
            'default',
            'triggered_time',
            'location',
            'resident_full_name',
            'arrived_carer'
        ]

        if (validFieldNames.includes(value)) {
            return value
        }
        else {
            return 'default'
        }
    }

    private getSortOrderAscending(parameters: URLSearchParams) : boolean {
        // We use the read_permissions as a sort of user default - e.g. a permission
        // indicates we must do something.
        //
        // If we don't have a query param, and we have a permission, then we default to triggered,
        // otherwise the query_param controls.
        const permission_present = eeviGlobal.loggedInUser.read_permissions.has("sort_order_desc");
        const queryParamValue = parameters.get("sortOrder")
        if (queryParamValue == undefined && permission_present){
            // Descending as we have permission and no query value
            return false;
        }

        return this.convertStringToSortOrderAscending(queryParamValue || "");
    }

    private convertStringToSortOrderAscending(value: string) : boolean {
        const descendingValues: string[] = [
            'desc'
        ]
        if (descendingValues.includes(value)) {
            return false;
        }
        return true;
    }

    protected onFormDidMount() {
        // Setting up a global counter that tick every 1s to simulate a clock
        this.register_timer("clock",()=>{this.setState({currentDateTime: new Date()})}, 1000);

        let parameters = new URLSearchParams(window.location.search)
        this.demoMode = parseInt(parameters.get("demo") || "") || undefined

        // Load careGroups into EeviCoreContext if necessary
        this.loadCareGroups()

        // Check if autoplay is enabled
        playTone(
            this.notification_audio,
            () => {this.autoplay = false},
            ()=>{this.initialize()}
        )
    }

    private topUpWithRecentEvents(recentPage: Partial<AlarmsFormState>): Partial<AlarmsFormState> {
        const oldEvents = this.state.events.filter(e => e.active);
        const newEvents = new Array<RealtimeEvent>();

        for (let event of recentPage.events!) {
            event = dynamicSubclass(event, RealtimeEventCompute)
            let found = false
            // Find and update open events
            for (const existingEvent of oldEvents){
                if (event.eventId === existingEvent.eventId){
                    found = true
                    Object.assign(existingEvent, event);
                    break;
                }
            }

            if (!found){
                if (event.active && !event.isTechSupport){
                    newEvents.push(event);
                }
            }
        }

        const activeEvents = [...newEvents, ...oldEvents].filter((e)=>e.withinQueryWindow);

        // Apply what ever sort order we have defined
        let sorted_active_events: RealtimeEvent[]
        if (this.state.sortField == 'triggered_time')
        {
            // Event triggeredTime sorted in ascending order (the earliest event first)
            sorted_active_events = activeEvents.sort(
                (a,b)=>{
                    return Date.parse(a.triggeredTime) - Date.parse(b.triggeredTime);
                }
            )
        }
        else if (this.state.sortField == 'location')
        {
            // Event triggeredTime sorted in ascending order (the earliest event first)
            sorted_active_events = activeEvents.sort(
                (a,b)=>{
                    return a.location < b.location ? -1 : 1
                }
            )
        }
        else if (this.state.sortField == 'resident_full_name')
        {
            // Event triggeredTime sorted in ascending order (the earliest event first)
            sorted_active_events = activeEvents.sort(
                (a,b)=>{
                    return a.residentFullName < b.residentFullName ? -1 : 1
                }
            )
        }
        else if (this.state.sortField == 'arrived_carer')
        {
            // Event triggeredTime sorted in ascending order (the earliest event first)
            sorted_active_events = activeEvents.sort(
                (a,b)=>{
                    return (a.arrivedCarer || '')  < (b.arrivedCarer || '') ? -1 : 1
                }
            )
        }

        else
        {
            // Default sort order, which is by open then taken
            sorted_active_events = activeEvents.sort(
                (a,b)=>{return a.status - b.status}
            );
        }

        // If descending then flip order
        if (!this.state.sortAscendingOrder)
        {
            sorted_active_events = sorted_active_events.reverse();
        }

        //Play a sound if there are open events
        if(activeEvents.filter((e)=>e.isOpen).length){
            this.setAlarm()
        }

        return {
            ...recentPage,
            events: sorted_active_events
        };
    }

    private retrieveRecentEvents(showLoadingScreen: boolean = false) {
        const parameters = this.getParameters();
        let retrievedEvents: Partial<AlarmsFormState> = {} //Intermediate variable to store response result

        function recursive_polling(resp: Partial<AlarmsFormState>, api_client:EeviApi<AlarmsFormState>){
            // Recursively polling data with the requested parameters until response.nextToken is empty
            if (resp.nextToken){
                const url = `${apiV1}/events_status?nextToken=${encodeURIComponent(resp.nextToken)}&` + parameters;
                api_client.get(
                    url, undefined, (resp: Partial<AlarmsFormState>) => recursive_polling(resp, api_client)
                )
            }
            retrievedEvents = {
                ...resp,
                events: [
                    ...retrievedEvents.events || [],
                    ...resp.events || []
                ]
            }
        }



        this.api.get(
            `${apiV1}/events_status?` + parameters,
            undefined,
            (data: Partial<AlarmsFormState>) => {
                if (data.requestId === this.requestId) {
                    let parameters = new URLSearchParams(window.location.search)

                    //Successfully get data
                    recursive_polling(data, this.api)

                    if (this.demoMode) {
                        // @ts-ignore
                        this.setState({
                            ...this.topUpWithRecentEvents(getDemoEvents(this.demoMode)),
                            loading: false,
                            error: null,
                            lastUpdate: this.state.currentDateTime,

                            hideResidentName: this.getHideResidentName(parameters),
                            sortField: this.getSortFieldName(parameters),
                            sortAscendingOrder: this.getSortOrderAscending(parameters),
                        });
                    } else {
                        this.setState({
                            ...this.state,
                            ...this.topUpWithRecentEvents(retrievedEvents),
                            loading: false,
                            error: null,
                            lastUpdate: this.state.currentDateTime,

                            hideResidentName: this.getHideResidentName(parameters),
                            sortField: this.getSortFieldName(parameters),
                            sortAscendingOrder: this.getSortOrderAscending(parameters),
                        });
                    }
                }
            },
            undefined,
            showLoadingScreen,
            (error) => {
                this.errorHandler(error)
            }
        );

    }
    

    private getParameters(): string {
        this.requestId = eeviId()
        const endDate = this.state.currentDateTime;
        const startDate = new Date(endDate.getTime() - QUERY_WINDOW);
        return `endDate=${encodeURIComponent(endDate.toISOString())}` +
            `&startDate=${encodeURIComponent(startDate.toISOString())}` +
            `&requestId=${this.requestId}`;
    }

    protected onFormWillUnmount() {
        this.removeVisibilityHandler()
        this.pageVisible = false;
        this._timers.forEach(clearInterval);
        this.clear_timers();
    }

    protected renderForm(): React.ReactNode {
        let content: JSX.Element
        if (!this.autoplay){
            content = this.acknowledgementCard
        } else if (this.state.error){
            content = <div className="text-container">{
                'The Event Status Page is down for maintenance.\n' +
                'For more information please contact support@eevi.life or 1300 802 738.'
            }</div>
        } else {
            content = <AlarmTable activeEvents={this.state.events} availableCarers={this.state.availableCarers} setAlarm={()=>{this.setAlarm()} } hideResidentDetails={this.state.hideResidentName}/>
        }

        return <div className="AlarmPage">
            <AlarmHeader now={this.state.currentDateTime} lastUpdate={this.state.lastUpdate}/>
            {content}
        </div>;
    }

    /**
     * Custom API error handler for event_status page.
     * @private
     */
    private errorHandler(error: Partial<any>){
        // ATM, the way an api error propagates is:
        // -> The default action is EeviApi method will set the current form's state {error:any},
        // -> There is any option of providing an errorCallback function in EeviApi to handle the error
        // -> If not suppressed, the EeviError tag will render this as a generic global error

        if (error.response && (error.response.status === 503 || error.response.status === 429)) {
            // Warning: 503 error means different things in different pages
            // in this page, it is usually mean "Service Unavailable"
            // -> nothing to handle here, simply keep polling
            console.log(`API Error: ${error.message}`)
            error.response.data.errors[0].handled = true
        }
        this.setState({...this.state, loading: false, error: error});
    }

    render(): React.ReactNode{
        return <EeviBaseContainer
            title={"Alarms"}
            formState={this.state}
            login={(c:LoginCredentials) => super.login(c)}
            addLoginRegisterButton={false}>
            {this.renderForm()}
        </EeviBaseContainer>;
    }

}

export default withRouter(AlarmsForm);