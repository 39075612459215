import {React, Container} from "./eevi_react_exports";


export function EeviLoading() {
    return <Container style={{padding: 100}}>
        <div className="eevi_loader">
        </div>
    </Container>;

}

