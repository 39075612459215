import {EeviStandardState} from "../components/eevi_standard_state";
import {apiV1, EeviStandardContainer, MenuLink} from "../containers/eevi_std_container";
import {LoggedInUser, LoginCredentials} from "../components/eevi_data";
import {EeviApi} from "../components/eevi_api";
import {debounce} from "react-ace/lib/editorOptions";
import {eeviGlobal} from "../components/eevi_context";
import {isGlobalError} from "../components/eevi_error";
import {GarbageCollector} from "../components/eevi_disposable";
import {
    RouteComponentProps,
    Redirect,
    React,
    CSSProperties,
    NavItem,
    NavLink,
    Navbar, NavbarBrand, NavbarToggler, Collapse, Nav, Dropdown, DropdownToggle, DropdownMenu, useHistory, DropdownItem
} from "../components/eevi_react_exports";


export type EeviFormProperties = RouteComponentProps<{}>;

export interface EeviFormState extends EeviStandardState {
    windowHeight: number;
    windowWidth: number;
    nextToken?: string;
}

export function initialState(): EeviFormState {
    return {
        windowHeight: window.innerHeight,
        windowWidth: window.innerWidth
    };
}


interface MenuLinkProps {
    loading?: boolean;
    menuLinks?: MenuLink[];
    title?: string;
    children?: React.ReactNode;
}

/**
 * Menus are a little tangled whilst we support the legacy of my poor original design. In general newer forms
 * should probably override renderMenus() rather than menuLinks. If both are overridden then the framework
 * will just use renderMenus(). This code is so that you can render menu links inside renderMenus().
 */
export function EeviMenuLinks(props: MenuLinkProps) {
    if (props.loading || !eeviGlobal.loggedInUser.email || eeviGlobal.loggedInUser.email == "unknown") {
        return <></>;
    }
    const history = useHistory();

    function navItems() {
        const menuLinks = props.menuLinks || [];
        return <>
            {
                menuLinks
                    .filter(m => m.title !== props.title) // exclude page we're already on
                    .map(menuLink =>
                        <NavItem key={menuLink.title} onClick={() => history.push(menuLink.link)}>
                            <NavLink to={menuLink.link}>{menuLink.title}</NavLink>
                        </NavItem>
                    )
            }
            {props.children}
        </>;
    }


    const [isOpen, setOpenState] = React.useState(false);
    return <Navbar expand="md" light color="faded">
        <NavbarBrand/>
        <NavbarToggler onClick={() => setOpenState(!isOpen)}/>
        <Collapse isOpen={isOpen} navbar className="pt-0">
            <Nav className="ml-auto" navbar>{navItems()}</Nav>
        </Collapse>
    </Navbar>;
}

interface DropdownMenuLinkProps {
    children?: React.ReactNode;
}


export function EeviDropDownMenuLink(props: DropdownMenuLinkProps) {
    const [isOpen, setOpenState] = React.useState(false);
    return <NavItem>
        <Dropdown className="eevi_dropdown" isOpen={isOpen} toggle={() => setOpenState(!isOpen)}>
            <DropdownToggle>Account</DropdownToggle>
            <DropdownMenu className="eevi_wide_dropdown_no_border" right>{props.children}</DropdownMenu>
        </Dropdown>
    </NavItem>

}

export abstract class EeviForm<TProperties extends EeviFormProperties,
    TState extends EeviFormState> extends React.Component<TProperties, TState> {
    public container: EeviStandardContainer | undefined | null;
    private resizeHandler: VoidFunction | undefined;
    private scrollHandler: VoidFunction | undefined;
    protected readonly startScalingAtWidth = 950;
    public readonly garbageCollector = new GarbageCollector();


    protected constructor(
        props: TProperties,
        readonly title: string,
        readonly hideTopMargin = false,
        readonly defaultMenus: boolean = false
    ) {
        super(props);
    }

    componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
    }

    static getDerivedStateFromError(error: Error) {
        // Update state so the next render will show the fallback UI.
        return {error: error.message};
    }

    componentDidMount() {
        this.addResizeHandler();
        this.addScrollHandler();
        this.onFormDidMount();
    }


    componentWillUnmount() {
        this.removeResizeHandler();
        this.removeScrollHandler();
        this.onFormWillUnmount();
        this.garbageCollector.dispose();
    }


    protected initialState(): EeviFormState {
        return initialState();
    }

    // event handlers that can be overridden
    protected onFormDidMount() {

    }

    protected onFormWillUnmount() {

    }

    protected onResize() {
        setTimeout(() => {
            this.setState({windowHeight: window.innerHeight, windowWidth: window.innerWidth});
        });
    }

    /**
     * Called when the user has scrolled past the end of the page.
     * Use this to fetch the next request for infinite scrolling.
     */
    protected onNextPage(nextToken: string) {

    }

    protected onScroll() {
        if (this.state.nextToken && EeviForm.getScrollPercent() > 95) {
            const nextToken = this.state.nextToken!;
            this.setState({nextToken: undefined});
            this.onNextPage(nextToken);
        }
    }

    protected static getScrollPercent(): number {
        const d = document.documentElement;
        const b = document.body;
        return (d.scrollTop || b.scrollTop) / ((d.scrollHeight || b.scrollHeight) - d.clientHeight) * 100;
    }


    private addResizeHandler() {
        if (!this.resizeHandler) {
            this.resizeHandler = () => this.onResize();
            window.addEventListener('resize', this.resizeHandler);
        }
    }

    private removeResizeHandler() {
        if (this.resizeHandler) {
            window.removeEventListener('resize', this.resizeHandler);
            this.resizeHandler = undefined;
        }
    }

    private addScrollHandler() {
        if (!this.scrollHandler) {
            this.scrollHandler = debounce(() => this.onScroll(), 100);
            window.addEventListener(
                'scroll',
                this.scrollHandler,
                {passive: true});
        }
    }

    private removeScrollHandler() {
        if (this.scrollHandler) {
            window.removeEventListener('scroll', this.scrollHandler);
            this.scrollHandler = undefined;
        }
    }


    protected getMenuButtonClass(scaleDown: boolean) {
        let menuButtonClass = "btn eevi_light_grey_color p-0";
        if (scaleDown) {
            menuButtonClass += " xx-small"
        }
        return menuButtonClass;
    }

    login(loginCredentials: LoginCredentials): Promise<LoggedInUser> {
        return new Promise<LoggedInUser>((resolve) => {
            const api = EeviApi.fromComponent<TState>(this);
            api.post<LoggedInUser>(
                `${apiV1}/login`, loginCredentials, undefined, (resp) => {
                    const userDetails = resp as LoggedInUser;
                    Object.assign(eeviGlobal.loggedInUser, userDetails);
                    if (userDetails.authenticated) {
                        // user is authenticated so retry loading or refreshing the form
                        this.setState({error: undefined});
                        this.onFormDidMount();
                    }
                    resolve(userDetails); // let EeviLogin pop-up component know that password was right/wrong
                }, false);

        });
    }


    protected menuLinks(): MenuLink[] {
        return [
            // {title: 'Residents', link: '/residents'},
            // {title: 'Carers', link: '/carers'},
            // {title: 'Calls', link: '/call_logs'}
        ];
    }


    /**
     * More or less the same as render() for form specific content. (See render() below)
     */
    protected abstract renderForm(): any;

    /**
     * Allows a form to control the style of its parent container div.
     */
    protected formContentDivStyle(): CSSProperties | undefined {
        return undefined;
    }


    protected renderMenus(): React.ReactNode | undefined {
        if (this.defaultMenus) {
            return <div className="d-flex justify-content-center">
                <EeviMenuLinks loading={this.state.loading} menuLinks={this.menuLinks()} title={this.getTitle()}>
                    <EeviDropDownMenuLink>
                        <div className="card shadow pt-4 pb-2 border bg-white">
                            <div className="font-weight-bold font-italic smaller border-bottom w-100 center pb-2">
                                {eeviGlobal.loggedInUser.email}
                            </div>
                            <DropdownItem onClick={() => this.props.history.replace('/logout', this.props.location)}>
                                <div className="ml-3 mt-2 smaller">Log out</div>
                            </DropdownItem>
                        </div>
                    </EeviDropDownMenuLink>
                </EeviMenuLinks>
            </div>;
        } else {
            return undefined;
        }
    }

    protected allowRenderMenus(): boolean {
        return !(this.state.loading || isGlobalError(this.state));
    }

    getTitle(): string {
        return this.state.title! || this.title!;
    }


    /**
     * Puts the standard header in place around the form, sets up error handling, user login.
     */
    render(): React.ReactNode {
        if (this.state.redirect) {
            return <Redirect to={this.state.redirect!} push/>;
        }
        const formContentDivStyle = this.formContentDivStyle();
        let formContent = <></>;
        if (!this.state.loading) {
            formContent = this.renderForm();
        }
        return <EeviStandardContainer
            ref={(instance) => this.container = instance}
            title={this.getTitle()}
            formState={this.state}
            formContentDivStyle={formContentDivStyle}
            hideTopMargin={this.hideTopMargin}
            menuLinks={this.menuLinks()}
            renderedMenus={this.allowRenderMenus() ? this.renderMenus() : undefined}
            login={(c) => this.login(c)}
            addLoginRegisterButton={false}>
            {formContent}
        </EeviStandardContainer>;
    }
}

